import request from "@/utils/Request";

/**
 * 开始评估以及重测
 * 开始评估只需要deviceId、treatment以及type三个参数
 * 重测时需要额外加上evaluationPlan以及lastEvaluationId两个参数
 * @returns
 */
export function startEvaluation(data) {
  return request({
    url: `/api/hospital-data/evaluation`,
    method: "post",
    data
  });
}

/**
 * 获取正在评估数据
 * @returns
 */
export function getCurrentEvluationData(treatmentId) {
  return request({
    url: `/api/hospital-data/evaluation/waiting/${treatmentId}`,
    method: "get",
  });
}

/**
 *  获取疼痛类型列表
 * @returns
 */
 export function getPainTypeList() {
  return request({
    url: `/api/main-data/dict/children?type=code&code=PAIN-TYPE`,
    method: "get",
  });
}

/**
 * 修改疼痛类型
 * @returns
 */
export function updatePainType(data) {
  return request({
    url: `/api/hospital-data/evaluation-detail-data/updatePainTypeById`,
    method: "put",
    data
  });
}

/**
 * 暂停评估
 * 评估id 来源于“开始评估”返回中的id
 * @returns 
 */
export function pauseEvaluation(evaluationId) {
  return request({
    url: `/api/hospital-data/evaluation/pause/${evaluationId}`,
    method: "put",
  });
}


/**
 * 继续评估
 * @param {*} dateType 
 * @returns 
 */
export function continueEvaluation(evaluationId) {
  return request({
    url: `/api/hospital-data/evaluation/continue/${evaluationId}`,
    method: "put",
  });
}


/**
 * 终止评估
 * 当选择时，abandonId为“终止原因列表”返回值的id
 * 当其他原因时，abandonId设为"other",并且discardReasonText设为填入的内容
 * @param {*} evaluationId 评估ID
 * @returns  
 */
 export function stopEvaluation(evaluationId,data) {
  return request({
    url: `/api/hospital-data/evaluation/stop/${evaluationId}`,
    method: "put",
    data
  });
}

/**
 * 结束评估 （用于被动评估）
 * 当选择时，abandonId为“终止原因列表”返回值的id
 * 当其他原因时，abandonId设为"other",并且discardReasonText设为填入的内容
 * @param {*} evaluationId 评估ID
 * @returns  
 */
 export function finishEvaluation(evaluationId) {
  return request({
    url: `/api/hospital-data/evaluation/finish/${evaluationId}`,
    method: "put",
  });
}


/**
 * 弃用评估
 * @param {*} evaluationId 评估ID
 * @returns  
 */
export function discardEvaluation(evaluationId,data) {
  return request({
    url: `/api/hospital-data/evaluation/discard/${evaluationId}`,
    method: "put",
    data
  });
}


/**
 * 获取评估终止原因
 * @returns
 */
 export function getEvaluationStopReasonList() {
  return request({
    url: `/api/main-data/system/dict`,
    method: "post",
    data:{
      code:'EVALUATION-STOP-REASON '
    }
  });
}

/**
 * 获取评估弃用原因
 * @returns
 */
 export function getEvaluationDiscardReason() {
  return request({
    url: `/api/main-data/system/dict`,
    method: "post",
    data:{
      code:'EVALUATION-STOP-REASON'
    }
  });
}






