import request from "@/utils/Request";

/**
 * 获取菜单
 * @returns
 */
export function getUserMenus() {
  return request({
    url: "/api/hospital-data/common/menu",
    method: "get",
  });
}

/**
 * 更新密码
 * @param {*} data
 * @returns
 */
export function updatePassword(data) {
  return request({
    url: "/api/hospital-data/common/pwd",
    method: "post",
    data,
  });
}

/**
 * 校验密码是否为初始密码
 * @returns
 */
export function checkPassword() {
  return request({
    url: "/api/hospital-data/common/check/pwd",
    method: "get",
  });
}


/**
 * 获取用户列表
 * @returns 
 */
export function getUserList(params) {
  return request({
    url: "/api/main-data/user",
    method: "get",
    params
  });
}

/**
 * 保存用户
 * @returns 
 */
 export function saveUser(data) {
  return request({
    url: "/api/main-data/user",
    method: "post",
    data
  });
}

/**
 * 获取角色列表
 * @returns 
 */
 export function getRoleList() {
  return request({
    url: "/api/main-data/role",
    method: "get"
  });
}

/**
 * 获取用户数据
 * @returns 
 */
 export function getUserById(userId) {
  return request({
    url: `/api/main-data/user/${userId}`,
    method: "get"
  });
}

/**
 * 删除用户
 * @param {*} userId 
 * @returns 
 */
export function deleteUser(userId) {
  return request({
    url: `/api/main-data/user/${userId}`,
    method: "delete"
  });
}


/**
 * 重置密码
 * @param {*} userId 
 * @returns 
 */
 export function restPassword(userId) {
  return request({
    url: `/api/main-data/user/${userId}/reset-password`,
    method: "put"
  });
}







