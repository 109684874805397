<!--标准颈部-->.
<template>
  <div class="standard-neck-component">
    <chart chart-title="训练时间" ref="trainTimeChartEl"></chart>
    <chart chart-title="完成率" ref="completionRateChartEl"></chart>
    <chart chart-title="平均角速度" ref="angularVelocityChartEl"></chart>
    <chart chart-title="最大角速度" ref="maxAngularVelocityChartEl"></chart>

  </div>
</template>

<script>
import Chart from "../Chart.vue";
import ChartUtils from "@/utils/ChartUtils";
import {getSelfLegendData} from "@/utils/ChartUtils"
import {getChartData,getXAxisLabel} from '@/api/TrendService'
import {chartFormat,fixedValue} from "@/utils/ChartUtils";

export default {
  props: {
     trendData:{
        type:Object
      }
  },
  components: {
    Chart,
  },
  data() {
    return {
      trainTimeChart: null,
      completionRateChart: null,
      angularVelocityChart: null,
      maxAngularVelocityChart: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.trainTimeChart = this.$refs.trainTimeChartEl;
      this.completionRateChart = this.$refs.completionRateChartEl;
      this.angularVelocityChart = this.$refs.angularVelocityChartEl;
      this.maxAngularVelocityChart = this.$refs.maxAngularVelocityChartEl;
      this.loadData();
    },
    loadChart(option) {
      this.chart.loadChart(option);
    },
    loadData() {
      this.trainTimeChart.loadChart(this.getTrainTimeChartOption());
      this.completionRateChart.loadChart(this.getCompletionRateChartOption());
      this.angularVelocityChart.loadChart(this.getAvgAngularVelocityOption());
      this.maxAngularVelocityChart.loadChart(this.getMaxAvgAngularVelocityOption());

    },

    /**
     * 获取训练时长图表数据
     */
    getTrainTimeChartOption() {
      const option = ChartUtils.getTrendStatisticsLineChartOption();

      option.legend.data = getSelfLegendData();
      
      option.yAxis[0].name = "(分钟)";
      option.xAxis.data = getXAxisLabel(this.trendData)
      option.tooltip.formatter = chartFormat("分钟",1);

      option.yAxis[0].axisLabel.formatter = null;
      option.yAxis[0].minInterval = 2;
    
     
      const chartData = getChartData(this.trendData,'trainDuration')

      const resultData = []
       for(let i=0;i<chartData.values.length;i++){
         const sub = []
        for(let j=0;j<chartData.values[i].length;j++){
          sub.push(parseFloat((chartData.values[i][j] / 60 ).toFixed(2)))
        }
       resultData.push(sub)
     }

     console.log("resultData",resultData)

      option.series = [
        ChartUtils.getLineSeries("左屈", resultData[0]),
        ChartUtils.getLineSeries("右屈", resultData[1]),
        ChartUtils.getLineSeries("左旋", resultData[2]),
        ChartUtils.getLineSeries("右旋", resultData[3]),
      ];

      return option;
    },
    /**
     * 获取完成率图表数据
     */
    getCompletionRateChartOption() {
      const option = ChartUtils.getTrendStatisticsChartOptionTemplate();
      option.yAxis[0].name = "(百分比)";
      option.xAxis.data = getXAxisLabel(this.trendData)
      option.yAxis[0].max = 100;
      option.yAxis[0].interval = 20;
      option.tooltip.formatter = chartFormat("%");

       const data = getChartData(this.trendData,'rate')
      const chartData = fixedValue(data.values,0)


      const item = ChartUtils.getSelfEvaluationChartBarSeries(
        "完成率",
        "#56a793",
        "#56a793",
        chartData[0]
      );
      item.label.color = "#12a5ef";
      item.label.formatter= "{c}%",
      option.series = [item];
      return option;
    },
    /**
     * 活动度图表数据
     */
    getAvgAngularVelocityOption() {
       const option = ChartUtils.getTrendStatisticsLineChartOption();
      option.xAxis.data = getXAxisLabel(this.trendData);
       option.yAxis[0].name = "(度/秒)";
      option.yAxis[0].axisLabel.formatter = null;
      
      option.legend.data = getSelfLegendData();
      option.tooltip.formatter = chartFormat("°/s",1);

      const romData = getChartData(this.trendData,'avgSpeed')


      option.series = [
        ChartUtils.getLineSeries("左屈", romData.values[0]),
        ChartUtils.getLineSeries("右屈", romData.values[1]),
        ChartUtils.getLineSeries("左旋", romData.values[2]),
        ChartUtils.getLineSeries("右旋", romData.values[3]),
    
      ];
      return option;
    },
     getMaxAvgAngularVelocityOption() {
       const option = ChartUtils.getTrendStatisticsLineChartOption();
       option.xAxis.data = getXAxisLabel(this.trendData);
       option.yAxis[0].name = "(度/秒)";
       option.yAxis[0].axisLabel.formatter = null;
       option.legend.data = getSelfLegendData();
       option.tooltip.formatter = chartFormat("°/s",1);
      const romData = getChartData(this.trendData,'maxSpeed')


      option.series = [
        ChartUtils.getLineSeries("左屈", romData.values[0]),
        ChartUtils.getLineSeries("右屈", romData.values[1]),
        ChartUtils.getLineSeries("左旋", romData.values[2]),
        ChartUtils.getLineSeries("右旋", romData.values[3]),
    
      ];
      return option;
    },

    /**
     * 获取最大角度图表数据
     */
    getMaxAngleChartOption() {
      const option = ChartUtils.getTrendStatisticsLineChartOption();
      option.xAxis.data = getXAxisLabel(this.trendData);
      option.yAxis[0].axisLabel.formatter = null;
      

      const romData = getChartData(this.trendData,'maxAngle')


      option.series = [
        ChartUtils.getLineSeries("左屈角度", romData.values[0]),
        ChartUtils.getLineSeries("右屈角度", romData.values[1]),
        ChartUtils.getLineSeries("左旋角度", romData.values[2]),
        ChartUtils.getLineSeries("右旋角度", romData.values[3]),
        ChartUtils.getLineSeries("前屈角度", romData.values[4]),
        ChartUtils.getLineSeries("后伸角度", romData.values[5]),
      ];
      return option;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";
.standard-neck-component {
}
</style>
