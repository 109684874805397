

export default {
  data() {
    return {
    };
  },

  components: { 
  
   },

  computed: {},

  mounted() {
    this.setPageTemplateTitle("关于");
   
  },

  methods: {
   
  },
};
