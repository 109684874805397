<!-- 数据详情对话框 -->
<template>
  <div class="component xrhealer-theme data-detail-dialog-component ">
    <el-dialog
      :modal-append-to-body="false"
      :title="title"
      :visible.sync="showDialog"
      width="90%"
    >
      <!--姿势错误-->
      <div v-if="currentDialogType === dialogType.PoseErrorDialog">
        <pose-error-table
          :errors="errors"
          :showHeader="false"
        ></pose-error-table>
      </div>
      <!--标准颈部训练结果-->
      <div v-if="currentDialogType === dialogType.NeckSportDialog">
        <div class="title-wrapper b-b">姿势错误</div>
        <pose-error-table :errors="errors" :showHeader="false"></pose-error-table>
        <div class="title-wrapper b-t">
            训练最大角度
        </div>
        <div class="data-detail-wrapper">
          <div class="data-detail-item">左屈：<span>{{sportDetailData.rateData.maxLeftFlexionRotate | formatAngle}}</span></div>
          <div class="data-detail-item">右屈：<span>{{sportDetailData.rateData.maxRightFlexionRotate | formatAngle}}</span></div>
          <div class="data-detail-item">左旋：<span>{{sportDetailData.rateData.maxLeftRotate | formatAngle}}</span></div>
          <div class="data-detail-item">右旋：<span>{{sportDetailData.rateData.maxRightRotate | formatAngle}}</span></div>
          <div class="data-detail-item">前屈：<span>{{sportDetailData.rateData.maxProtractRotate | formatAngle}}</span></div>
          <div class="data-detail-item">后伸：<span>{{sportDetailData.rateData.maxHypsokinesisRotate | formatAngle}}</span></div>
        </div>
        <div class="flex-row angle-speed-wrapper">
          <div class="speed-item">
            <div class="title-wrapper">平均角速度</div>
            <div class="data-detail-item"><span>{{sportDetailData.rateData.avgAngularSpeed | formatAngle}}/秒</span></div>
          </div>
          <div class="speed-item">
            <div class="title-wrapper">最大角速度</div>
            <div class="data-detail-item"><span>{{sportDetailData.rateData.maxAngularSpeed | formatAngle}}/秒</span></div>
          </div>
        </div>
        <!--动作占比-->
        <div class="range-wrapper">
          <div class="title-wrapper">动作占比</div>
            <div class="data-detail-wrapper">
              <div class="data-detail-item">左屈：<span>{{lastPlan.leftFlexionRange}}%</span></div>
              <div class="data-detail-item">右屈：<span>{{lastPlan.rightFlexionRange}}%</span></div>
              <div class="data-detail-item">左旋：<span>{{lastPlan.leftRange}}%</span></div>
              <div class="data-detail-item">右旋：<span>{{lastPlan.rightRange}}%</span></div>
              <div class="data-detail-item">前屈：<span>{{lastPlan.protractRange}}%</span></div>
              <div class="data-detail-item">后伸：<span>{{lastPlan.hypsokinesisRange}}%</span></div>
            </div>
        </div>
      </div>

      <!--本体感觉-->
      <div v-if="currentDialogType === dialogType.SelfSportDialog">
        <div class="title-wrapper b-b">姿势错误</div>
        <pose-error-table :errors="errors" :showHeader="false"></pose-error-table>
        <div class="title-wrapper b-b b-t">角速度/时长</div>
        <self-angle-speed-table :showHeader="false" :angleList="selfAngleList"></self-angle-speed-table>
      </div>

      <!--本体感觉睁闭眼-->
       <div v-if="currentDialogType === dialogType.SelfOCSportDialog" class="pd-b-20">
        <self-point-chart-group ref="selfPointChartGroup"></self-point-chart-group>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PoseErrorTable from "@/components/pose-error-table/PoseErrorTable.vue";
import SelfAngleSpeedTable from '@/components/self-angle-speed-table/SelfAngleSpeedTable.vue';
import SelfPointChartGroup  from '@/components/selft-point-chart/SelfPointChartGroup.vue'
export default {
  props: {},
  data() {
    return {
      showDialog: false,
      currentDialogType:"1",
      errors: [],
      title: "训练详情",
      sportDetailData:null,
      lastPlan:null,
      selfAngleList:[]
    };
  },

  components: {
    PoseErrorTable,
    SelfAngleSpeedTable,
    SelfPointChartGroup
  },

  computed: {
    dialogType(){
          return {
              PoseErrorDialog:"1",
              NeckSportDialog:"2",
              SelfSportDialog:"3",
              SelfOCSportDialog:"4"
          }
      }
  },

  mounted() {},

  methods: {
    showPoseErrorDialog(errors) {
      this.currentDialogType = this.dialogType.PoseErrorDialog
      this.showDialog = true;
      this.errors = errors;
    },
    showNeckSportDetail(sportDetailData,lastPlan){
      this.currentDialogType = this.dialogType.NeckSportDialog
      this.showDialog = true;
      this.errors = sportDetailData.errorLogs;
      this.sportDetailData = sportDetailData
      this.lastPlan = lastPlan
    },
    showSelfSportDetail(sportDetailData){
      this.sportDetailData = sportDetailData
      this.errors = sportDetailData.errorLogs;
      this.currentDialogType = this.dialogType.SelfSportDialog
      this.showDialog = true
      this.selfAngleList = JSON.parse(sportDetailData.rateData.additionStep1)
    },
     showSelfOCSportDetail(sportDetailData,type){
      this.sportDetailData = sportDetailData
      this.currentDialogType = this.dialogType.SelfOCSportDialog
      this.showDialog = true
      this.$nextTick(()=>{
        this.$refs.selfPointChartGroup.loadData(sportDetailData.pointData,type)
      })
    }
  },
};
</script>
<style lang="scss">
@import '@/styles/mixin.scss';
@import '@/styles/variable.scss';

.data-detail-dialog-component {
  .pose-error-table-component,
  .self-angle-speed-table-component{
    border: none;
    .pose-error-table {
      .header-wrapper {
        background-color: #f1f1f1;
        border-bottom: 1px solid $pageBorderColor;
      }
    }
  }
  .el-dialog {
    .el-dialog__body {
      padding: 0px !important;
    }
  }
  .b-b{
      border-bottom: 1px solid $pageBorderColor;
  }
  .b-t{
     border-top: 1px solid $pageBorderColor;
  }
  .title-wrapper{
      @include font-size-color(18px,$themeColor);
      line-height: 48px;
      padding-left: 20px;
  }
  .data-detail-wrapper{
    padding-left: 20px;
    @include flex_layout(row,null,center);
    
  }
  .data-detail-item{
      @include font-size-color(16px,#969799);
      margin-right: 70px;
      span{
        color:#2d405e;
      }
    }
  .angle-speed-wrapper{
    margin-top: 10px;
   .data-detail-item{
     padding-left: 20px;
   } 
  }
  .range-wrapper{
    padding: 20px 0px;
    padding-top: 10px;
  }
  .pd-b-20{
    padding-bottom: 20px;
  }
  .self-point-chart-component .chart-wrapper{
    height: 300px;
  }
}
</style>
