import {checkChar} from '@/utils/Validator'
import {getAllTags,getCaseEvaluation,saveCaseEvaluation} from '@/api/DiagnosisResultsServcie'
import {toLine,toHump} from '@/utils/Utils'
import { BackHomeControlMixins } from "@/utils/Mixins";
export default {
  mixins: [BackHomeControlMixins],
  components: {},
  data() {
    const that =  this
    return {
      pageParam:{
        patientCaseId: null,
        patientId:null,
        treatmentNum:0
      },
      minTreatmentNum:0,
      showMore: false,
      textarea: null,
      programs: [
        {
          title: "处方",
          code: "prescription",
          classes: "",
          details: [
            {
              title: "药物",
              code: "medicine",
              type: "textarea",
            },
            {
              title: "手法",
              code: "technique",
              type: "textarea",
            },
            {
              title: "理疗",
              code: "physiotherapy",
              type: "textarea",
            },
            {
              title: "训练",
              code: "sports",
              type: "textarea",
            },
          ],
        },
        {
          title: "治疗方案",
          code: "treatment_program",
          classes: "",
          details: [
            {
              title: "近期目标",
              code: "recent_goals",
              type: "textarea",
            },
            {
              title: "远期目标",
              code: "long_term_goal",
              type: "textarea",
            },
            {
              title: "计划",
              code: "plan",
              type: "textarea",
            },
            {
              title: "疗程数",
              code: "treatment_num",
              type: "number",
              min: that.minTreatmentNum,//TODO
              max: 100,
            },
          ],
        },
        {
          title: "其他评估",
          code: "other_evaluation",
          classes: { hidden: this.showMore },
          details: [
            {
              title: "主要问题",
              code: "principal_problem",
              type: "textarea",
            },
            {
              title: "缓解因素",
              code: "mitigation_factors",
              type: "textarea",
            },
            {
              title: "加重因素",
              code: "aggravating_factors",
              type: "textarea",
            },
            {
              title: "24小时因素",
              code: "hour24_mode",
              type: "textarea",
            },
            {
              title: "现病史",
              code: "history",
              type: "textarea",
            },
            {
              title: "既往史",
              code: "past_history",
              type: "textarea",
            },
            {
              title: "社会因素",
              code: "social_factors",
              type: "textarea",
            },
            {
              title: "特殊问题",
              code: "special_problems",
              type: "textarea",
            },
            {
              title: "视诊",
              code: "visual_inspection",
              type: "textarea",
            },
            {
              title: "肌肉神经检查",
              code: "muscle_nerve_examination",
              type: "textarea",
            },
            {
              title: "触诊",
              code: "palpation",
              type: "textarea",
            },
            {
              title: "特殊检查",
              code: "special_inspection",
              type: "textarea",
            },
          ],
        },
      ],
      caseEvaluation: {},
      selectedTagCode: null,
      tags: {},
      showOnly: false,
      resultForm: {},
      rules: {
        medicine: [
          {
            validator: checkChar,
          },
        ],
        technique: [
          {
            validator: checkChar,
          },
        ],
        physiotherapy: [
          {
            validator: checkChar,
          },
        ],
        sports: [
          {
            validator: checkChar,
          },
        ],
        recent_goals: [
          {
            validator: checkChar,
          },
        ],
        long_term_goal: [
          {
            validator: checkChar,
          },
        ],
        plan: [
          {
            validator: checkChar,
          },
        ],
        treatment_num: [
          {
            validator: checkChar,
          },
        ],
        principal_problem: [
          {
            validator: checkChar,
          },
        ],
        mitigation_factors: [
          {
            validator: checkChar,
          },
        ],
        aggravating_factors: [
          {
            validator: checkChar,
          },
        ],
        hour24_mode: [
          {
            validator: checkChar,
          },
        ],
        history: [
          {
            validator: checkChar,
          },
        ],
        past_history: [
          {
            validator: checkChar,
          },
        ],
        social_factors: [
          {
            validator: checkChar,
          },
        ],
        special_problems: [
          {
            validator: checkChar,
          },
        ],
        visual_inspection: [
          {
            validator: checkChar,
          },
        ],
        muscle_nerve_examination: [
          {
            validator: checkChar,
          },
        ],
        palpation: [
          {
            validator: checkChar,
          },
        ],
        special_inspection: [
          {
            validator: checkChar,
          },
        ],
      },
    };
  },
  watch: {},
  mounted() {
    this.setPageTemplateTitle('诊断结果')
    this.showOnly = false;
    console.log('init',this)

    this.minTreatmentNum = parseInt(this.pageParam.treatmentNum)

    this.init();
  },
  methods: {
    init() {
      this.getAllTags();
      this.getCaseEvaluation();
    },
    showMoreEvent() {
      this.showMore = false;
    },
    getAllTags() {
      getAllTags().then((data)=>{
        this.tags = data;
      }).catch(()=>{})     
    },
    changeTag(tag, index) {
      if (index >= 1) {
        this.showMore = false;
      }
      if (this.selectedTagCode == tag) {
        this.selectedTagCode = null;
      } else {
        this.selectedTagCode = tag;
      }
    },
    closeTagWin() {
      this.selectedTagCode = null;
    },
    insertTag(code, tagName) {
      if (this.caseEvaluation[code]) {
        this.$set(
          this.caseEvaluation,
          code,
          this.caseEvaluation[code] + " " + tagName
        );
      } else {
        this.$set(this.caseEvaluation, code, tagName);
      }
    },
    saveProgram(mainCode) {
      
      let res = {
        caseId: this.pageParam.patientCaseId,
      };
      let pass = true;
      for (let i = 0; i < this.programs.length; i++) {
        if (this.programs[i].code == mainCode) {
          for (let j = 0; j < this.programs[i].details.length; j++) {
            let c = this.programs[i].details[j];
            checkChar(
              {},
              this.caseEvaluation[c.code],
              function(e) {
                if (e && pass) {
                  this.$message({
                    type: "warning",
                    message: "保存错误:栏位[" + c.title + "]中存在非法字符！",
                  });
                  pass = false;
                }
              }
            );
            res[toHump(c.code)] =
            this.caseEvaluation[c.code];
          }
        }
      }
      if (pass) {
        saveCaseEvaluation(res).then(()=>{
          this.$message({
            message: '保存成功',
            type: 'success'
          });
        }).catch(()=>{})
      }
    },
    getCaseEvaluation() {
      getCaseEvaluation(this.pageParam.patientCaseId).then(({data})=>{
        for (let key in data) {
          this.$set(
            this.caseEvaluation,
            toLine(key),
            data[key]
          );
        }
        this.$set(
          this.caseEvaluation,
          "treatment_num",
          this.patientcase.treatmentTotal
        );

      }).catch(()=>{})
    },
  },
};
