<!-- 肩颈肌肉训练结果 -->
<template>
  <div class="component neck-muscle-train-result-component">
    <div class="neck-train-result-wrapper">
      <div class="result-panel-wrapper" style="align-items:flex-start">
        <div class="result-panel completion-rate" style="height:396px">
          <div class="icon-title completion-rate">平均完成率</div>
          <div class="completion-rate-process-wrapper">
            <el-progress
              type="dashboard"
              color="#25aef3"
              :width="218"
              :stroke-width="14"
              :percentage="trainData.detail.rateData?trainData.detail.rateData.rate:0"
            ></el-progress>
          </div>
        </div>
        <!-- <div class="flex-1">
          <div class="pose-error-table-wrapper" style="margin-top:0px;">
            <pose-error-table
              :errors="trainData.detail.errorLogs"
            ></pose-error-table>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import PoseErrorTable from "@/components/pose-error-table/PoseErrorTable.vue";
export default {
  props: {
    trainData: {
      type: Object,
    },
  },
  data() {
    return {};
  },

  components: {
    // PoseErrorTable,
  },

  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped></style>
