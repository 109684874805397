<!-- 字典编辑对话框 -->
<template>
  <div class="component dict-edit-dialog">
    <el-dialog
      :modal-append-to-body="false"
      :title="title"
      :visible.sync="showDialog"
      width="930px"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <div class="flex-row">
          <el-form-item label="姓名" prop="userName">
            <el-input
              v-model="form.userName"
              maxlength="6"
              placeholder="请输入"
              v-focus="{type:'text',position:'leftBottom',xOffset:0,yOffset:0}"
            ></el-input>
          </el-form-item>
          <div class="form-item-h-space"></div>
          <el-form-item label="性别" prop="gender">
            <div class="gender-button-wrapper">
              <gender-button
                @change="handleGenderChange"
                ref="genderButton"
              ></gender-button>
            </div>
            <el-input v-model="form.gender" :readonly="true"></el-input>
          </el-form-item>
        </div>

        <div class="flex-row">
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model="form.phone"
              maxlength="11"
              placeholder="请输入"
              v-focus="{type:'number',position:'leftBottom',xOffset:0,yOffset:0}"
            ></el-input>
          </el-form-item>
          <div class="form-item-h-space"></div>
          <el-form-item label="邮箱" prop="mail">
            <el-input
              v-model="form.mail"
              maxlength="50"
              placeholder="请输入"
              v-focus="{type:'en-text',position:'leftBottom',xOffset:0,yOffset:0}"
            ></el-input>
          </el-form-item>
        </div>

        <div class="flex-row">
          <el-form-item label="职位">
            <el-select v-model="form.jobId" placeholder="请选择 " clearable>
              <!-- <el-option label="请选择" value=""></el-option> -->
              <el-option
                v-for="item in jobs"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <div class="form-item-h-space"></div>
          <el-form-item label="职称">
            <el-select v-model="form.titleId" placeholder="请选择 " clearable>
              <!-- <el-option label="请选择" value=""></el-option> -->
              <el-option
                :key="item.id"
                :label="item.name"
                :value="item.id"
                v-for="item in titles"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="flex-row">
          <el-form-item label="角色" prop="roles">
            <el-select
              v-model="form.roles"
              class="role-select"
              :multiple="true"
              placeholder="请选择 "
            >
              <el-option
                :key="item.id"
                :label="item.roleName"
                :value="item.id"
                v-for="item in roles"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="form-item-h-space"></div>
          <div class="flex-1"></div>
        </div>
      </el-form>

      <div slot="footer">
        <div class="flex-row">
          <div class="form-dialog-footer-btn" @click="showDialog = false">
            取 消
          </div>
          <div
            class="form-dialog-footer-btn confirm"
            @click="handleConfirmClick"
          >
            确 定
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { checkChar, checkMobile, checkMail } from "@/utils/Validator";
import GenderButton from "@/components/gender-button/GenderButton.vue";
import { saveUser, getRoleList, getUserById } from "@/api/SysService";
import { getChildDictByCode } from "@/api/DictService";

export default {
  data() {
    return {
      showDialog: false,
      title: "",
      roles: [],
      titles: [],
      jobs: [],
      canSubmit: true,
      form: {
        id: "",
        userName: "",
        phone: "",
        gender: "",
        roles: [],
        jobId: "",
        titleId: "",
        description: "",
        photo: "",
        mail: "",
      },
      rules: {
        userName: [
          {
            required: true,
            requiredMsg: "用户姓名不能为空!",
            trigger: "blur",
            validator: checkChar,
          },
        ],
        phone: [
          {
            required: true,
            requiredMsg: "请正确输入手机号码!",
            validator: checkMobile,
            trigger: "blur",
          },
        ],
        jobId: [
          {
            required: true,
            message: "职位必须选择!",
          },
        ],
        gender: [
          {
            required: true,
            message: "性别必须选择!",
          },
        ],
        mail: [
          {
            required: false,
            message: "请正确输入邮箱地址!",
            validator: checkMail,
            trigger: "blur",
          },
        ],
        roles: [
          {
            required: true,
            message: "角色必须选择!",
          },
        ],
        description: [
          {
            required: false,
            trigger: "blur",
            validator: checkChar,
          },
        ],
      },
    };
  },

  components: {
    GenderButton,
  },

  computed: {},

  mounted() {
    this.getInitData();
  },

  methods: {
    getInitData() {
      getChildDictByCode("DOCTOR-TITLE")
        .then(({ data }) => {
          this.titles = data;
        })
        .catch(() => {});

      getChildDictByCode("DOCTOR-JOB")
        .then(({ data }) => {
          this.jobs = data;
        })
        .catch(() => {});

      getRoleList().then(({ data }) => {
        this.roles = data;
      });
    },
    handleGenderChange(gender) {
      this.form.gender = gender;
    },

    handleConfirmClick() {
      if (!this.canSubmit) {
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return false;
        }

        this.canSubmit = false;
        saveUser(this.form)
          .then(() => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$emit("editSuccess");
            this.close();
          })
          .catch(() => {
            this.canSubmit = true;
          });
        return true;
      });
    },
    close() {
      this.showDialog = false;
    },
    open(userId) {
      this.canSubmit = true;
      if (!userId) {
        this.title = "新增";
        this.form.id = "";
        this.form.userName = "";
        this.form.phone = "";
        this.form.gender = null;
        this.form.roles = [];
        this.form.jobId = "";
        this.form.titleId = "";
        this.form.description = "";
        this.form.mail = "";

        this.$nextTick(() => {
          this.$refs.genderButton.selectGender(this.form.gender);
        });
      } else {
        this.title = "编辑";
        getUserById(userId)
          .then(({ data }) => {
            this.form = data;
            this.$nextTick(() => {
              this.$refs.genderButton.selectGender(this.form.gender);
            });
          })
          .catch(() => {});
      }
      this.showDialog = true;

      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
  },
};
</script>
<style lang="scss">
.dict-edit-dialog {
  @import "@/styles/mixin.scss";
  @import "@/styles/variable.scss";

  .el-dialog__body {
    padding: 20px 20px 0px 20px;
  }
  .desc {
    .el-form-item__label {
      top: -55px;
    }
  }
  .role-select {
    // .el-input__inner {
    //   @include placeholder {
    //       color: $backColor1  !important;
    //       font-style: normal;
    //   }
    // }
  }
}
</style>
