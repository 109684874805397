import VrStatus from "@/components/vr-status/VRStatus.vue";
import { getDeviceDataByCaseId } from "@/api/DeviceService";
import { startEvaluation } from "@/api/EvaluationService";
import { EvaluationTypeEnum } from "@/utils/Enums";
import { WorkbenchMixins,BackHomeControlMixins } from "@/utils/Mixins";
import WebSocketService from "@/api/WebSocketService";
import { WebSocketCommand } from "@/api/WebSocketService";

export default {
  mixins: [WorkbenchMixins,BackHomeControlMixins],
  data() {
    return {
      pageParam: {
        patientCaseId: null,
        treatmentId: null,
      },
      currentSelectType: null,
      deviceData: {},
      evaluationData: null,
      evaluationStartMsgTimeout: 20000,
      loading: null,
      timeoutObj: null,
    };
  },

  components: { VrStatus },

  computed: {
    deviceStatus() {
      return this.deviceData.isConnect;
    },
    evaluationType() {
      return EvaluationTypeEnum;
    },
  },

  mounted() {
    this.setPageTemplateTitle("评估方案");
    this.bindWebsocketMessageHandler();
    this.getInitData();
  },

  methods: {
    /**
     * 获取初始数据
     */
    getInitData() {
      getDeviceDataByCaseId(this.pageParam.patientCaseId)
        .then((device) => {
          this.deviceData = device;
        })
        .catch(() => {});
    },
    /**
     * 绑定websocket消息处理
     */
    bindWebsocketMessageHandler() {
      //获取全量评估数据
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.START_EVA,
        this,
        this.handleStartEvaluationEvent
      );

      // 前置机错误消息
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.MSG_ERROR_MSG,
        this,
        this.handleMainServiceErrorEvent
      );
    },
    /**
     * 处理评估类型选择
     * @param {*} evaluationType
     * @returns
     */
    handleEvaluationTypeSelect(evaluationType) {
      // if (!this.deviceStatus) {
      //   return;
      // }
      this.currentSelectType = evaluationType;
    },
    /**
     * 开始评估
     */
    handleStartEvaluationClick() {
      if (!this.deviceData.isConnect) {
        this.$message.error("设备状态异常");
        return;
      }

      if(!this.currentSelectType){
        this.$message.error("请选择评估方案");
        return;
      }
      const reqParam = {
        deviceId: this.deviceData.id,
        treatment: this.treatmentId,
        type: this.currentSelectType.type,
      };

      this.loading = this.$loading({
        lock: true,
        text: "处理中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      startEvaluation(reqParam)
        .then(({ data }) => {
          this.evaluationData = data;
          this.timeoutObj = setTimeout(() => {
            this.$alert("连接VR设备超时！", "警告", {
              callback: () => {
                this.handleStartEvaluationEvent();
              },
            });
          }, this.evaluationStartMsgTimeout);
        })
        .catch(() => {
          this.loading.close();
        });
    },
    /**
     * 处理开始评估消息
     */
    handleStartEvaluationEvent() {
      clearTimeout(this.timeoutObj);
      this.loading.close();

      this.openEvaluationingPage(
        this.pageParam.patientCaseId,
        this.pageParam.treatmentId,
        this.evaluationData.id,
        this.evaluationData.deviceId
      );
    },
    /**
     * 退出评估
     */
    handleExitEvaluationClick() {
      this.openWorkbenchPage(
        this.pageParam.patientCaseId
      );
    },
    /**
     * 处理前置机错误消息
     * @param {*} event
     */
    handleMainServiceErrorEvent(event) {
      this.loading.close();
      clearTimeout(this.timeoutObj);
      this.$alert(event.data.errorMsg, "提示");
      if (event.data.stopBusiness) {
        this.openWorkbenchPage(
          this.pageParam.patientCaseId);
      }
    },
  },
};
