<!--接诊记录-->
<template>
  <div class="component reception-log-item-component">
    <div class="summary-wrapper">
      <div>
        {{ item.createTime | formatDate}}<span class="mg-l-10">接诊医生：</span
        >{{ item.doctorName }}
      </div>
      <div class="fold-btn" @click="handleOpenOrCloseClick" v-if="!item.show">
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="fold-btn" @click="handleOpenOrCloseClick" v-else>
        <i class="el-icon-arrow-up"></i>
      </div>
    </div>
    <div class="content-wrapper" v-if="item.show && detailData !== null" >
      <div class="fs-24 color_ff9000" v-if="hasEvaluationData">评估结果：</div>

      <div class="table-wrapper" v-if="hasEvaluationData">
        <div class="summary-panel" >
          <div class="color_gray">
            一般疼痛打分：<span class="fs-24 theme-color">{{detailData.evaluation.pain | formatEmpty('N/A')}}</span
            ><span class="color_black1">分</span>
            <span style="margin-left:130px;">姿势错误：</span
            ><span class="fs-24 theme-color">{{detailData.evaluation.errorNum | formatEmpty('N/A')}}</span
            ><span class="color_black1">次</span>
          </div>
          <a href="javascript:;" v-if="detailData.evaluation.errorNum" @click="handleEvaluationDetailClick" class="detail-btn">详情</a>

          <div class="flex-1"></div>
          <div class="print-report-btn" @click="handlePrintReportClick">打印报告</div>

        </div>
        <div class="flex-row">
          <div class="rom-table-wrapper">
            <div class="header">
              <span class="direction">方向</span>
              <span class="rom">主动/被动角度</span>
              <span class="pain">主动/被动疼痛分</span>
              <span class="pain-type">主动/被动疼痛类型</span>
              <span class="self-difference">本体感觉角度差距</span>
            </div>
            <div class="body">
              <template v-for="(romItem,index) in romData">
              <div class="row" :key="`rom:${romItem.direction}`" v-if="index < 3" >
                <div class="direction">{{romItem.direction | formatSportDirection}}</div>
                <div class="rom">
                  
                  <span v-if="!romItem.active.isAbnormal"> {{ romItem.active.angle | formatAngle}}</span>
                  <el-popover
                    v-else
                    placement="top-start"
                    trigger="hover"
                  >
                  <div class="abnormal-desc-wrapper">
                    <span class="title">姿势错误:</span>{{romItem.active.errorDesc}}
                  </div>
                    <span slot="reference" class="abnormal-data-wrapper"
                      >{{ romItem.active.angle | formatAngle}}<i class="el-icon-warning-outline"></i
                    ></span>
                  </el-popover>
                  /{{romItem.passive.angle | formatAngle}}</div>
                <div class="pain">{{romItem.active.pain | formatEmpty('--')}}/{{romItem.passive.pain | formatEmpty('--')}}</div>
                <div class="pain-type">{{romItem.active.painType | formatEmpty('--')}}/{{romItem.passive.painType | formatEmpty('--')}}</div>
                <div class="self-difference">{{romItem.sf.angleDiff | formatEmpty('--')}}</div>
              </div>
              </template>
            </div>
          </div>
          <div class="rom-table-wrapper">
            <div class="header">
              <span class="direction">方向</span>
              <span class="rom">主动/被动角度</span>
              <span class="pain">主动/被动疼痛分</span>
              <span class="pain-type">主动/被动疼痛类型</span>
              <span class="self-difference">本体感觉角度差距</span>
            </div>
            <div class="body">
              
              <template v-for="(romItem,index) in romData">
              <div class="row" :key="`rom:${romItem.direction}`" v-if="index > 2" >
                <div class="direction">{{romItem.direction | formatSportDirection}}</div>
                <div class="rom">
                  <span v-if="!romItem.active.isAbnormal"> {{ romItem.active.angle | formatAngle}}</span>
                  <el-popover
                    v-else
                    placement="top-start"
                    trigger="hover"
                  >
                  <div class="abnormal-desc-wrapper">
                    <span class="title">姿势错误:</span>{{romItem.active.errorDesc}}
                  </div>
                    <span slot="reference" class="abnormal-data-wrapper"
                      >{{ romItem.active.angle | formatAngle}}<i class="el-icon-warning-outline"></i
                    ></span>
                  </el-popover>
                  /{{romItem.passive.angle | formatAngle}}</div>
                <div class="pain">{{romItem.active.pain | formatEmpty('--')}}/{{romItem.passive.pain | formatEmpty('--')}}</div>
                <div class="pain-type">{{romItem.active.painType | formatEmpty('--')}}/{{romItem.passive.painType | formatEmpty('--')}}</div>
                <div class="self-difference">{{romItem.sf.angleDiff | formatEmpty('--')}}</div>
              </div>
              </template>

            </div>
          </div>
        </div>
      </div>
      <!--表格结束-->

      <!--训练结果-->
      <div class="fs-24 color_1ab534" v-if="detailData.trains.length>0" style="margin-top:10px;">训练结果：</div>

      <div class="train-time-tabs-wrapper" v-if="detailData.trains.length>0">
        <tabs @changed="hanleTrainItemTabChange">
          <tab-item 
            :title="item.treatmentDate.substring(11,16)" 
            :key="item.treatmentSportId" 
            v-for="(item) in detailData.trains" 
            :active="item.treatmentSportId === currentTrainItem.treatmentSportId"
            
            >
          </tab-item>
        </tabs>
        <div class="train-detail-wrapper">
          <div class="train-detail-row">
            <div class="train-item-title-wrapper">
              <span class="fs-24-black1">训练幅度:</span>
              <span class="fs-20-black1 mg-l-10"></span>
            </div>
            <div class="data-detail-wrapper">
              <span class="data-label-value">左屈：<span>{{currentTrainItem.lastPlan.leftFlexionRotate | formatAngle}}</span></span>
              <span class="data-label-value">右屈：<span>{{currentTrainItem.lastPlan.rightFlexionRotate | formatAngle}}</span></span>
              <span class="data-label-value">左旋：<span>{{currentTrainItem.lastPlan.leftRotate | formatAngle}}</span></span>
              <span class="data-label-value">右旋：<span>{{currentTrainItem.lastPlan.rightRotate | formatAngle}}</span></span>
              <span class="data-label-value">前屈：<span>{{currentTrainItem.lastPlan.protractRotate | formatAngle}}</span></span>
              <span class="data-label-value">后屈：<span>{{currentTrainItem.lastPlan.hypsokinesisRotate | formatAngle}}</span></span>
            </div>
          </div>

          <div :key="sportItem.sportType" v-for="(sportItem) in currentTrainItem.treatmentSportsDetail">
          
            <!--标准颈部训练-->
          <div class="train-detail-row" v-if="sportItem.sportType === sportProjectTypeEnum.NECK.type && sportItem.detail.hasData" >
            <div class="train-item-title-wrapper">
              <span class="fs-24-black1">{{sportProjectTypeEnum.NECK.desc}}:</span>
              <span class="train-scheme-data-title group-no">{{sportItem.detail.sportTime}}组</span>
              <span class="train-scheme-data-title speed">{{sportItem.detail.speed | formatSpeed}}</span>
            </div>
            <div class="data-detail-wrapper">
              <span class="data-label-value">时长：<span>{{sportItem.duration | formatSeconds}}</span></span>
              <span class="data-label-value">完成率：<span>{{sportItem.detail.rateData.rate}}%</span></span>
              <span class="data-label-value">姿势错误：<span>{{sportItem.errorCount}}</span></span>
            </div>
            <div class="result-detail-btn-wrapper">
              <a href="javascript:;" @click="handleNeckSportDetailClick(sportItem.detail,currentTrainItem.lastPlan)" class="detail-btn fs-20">详情</a>
            </div>
          </div>
          <!--标准颈部训练-->

          <div class="train-detail-row" v-if="sportItem.sportType === sportProjectTypeEnum.NECK_MUSCLE.type && sportItem.detail.hasData">
            <div class="train-item-title-wrapper">
              <span class="fs-24-black1">{{sportProjectTypeEnum.NECK_MUSCLE.desc}}:</span>
              <span class="train-scheme-data-title group-no">{{sportItem.detail.sportTime}}组</span>
            </div>
            <div class="data-detail-wrapper">
              <span class="data-label-value">时长：<span>{{sportItem.duration | formatSeconds}}</span></span>
              <span class="data-label-value">完成率：<span>{{sportItem.detail.rateData.rate}}%</span></span>
            </div>
          </div>
          <!--肩颈肌肉训练-->

          <div class="train-detail-row self-train" v-if="sportItem.sportType === sportProjectTypeEnum.SELFFEEL.type && sportItem.detail.hasData">
            <div class="train-item-title-wrapper">
              <span class="fs-24-black1">{{sportProjectTypeEnum.SELFFEEL.desc}}:</span>
              <span class="train-scheme-data-title group-no">{{sportItem.detail.sportTime}}组</span>
              <span class="train-scheme-data-title speed">{{sportItem.detail.speed | formatSpeed}}</span>
            </div>
            <div class="flex-1">
              <!-- <div class="self-train-item open" v-if="sportItem.detail.rateData">
                <span class="fs-24-black1">睁眼训练：</span>
                <span class="data-label-value">完成率：<span>{{sportItem.detail.rateData.rate}}%</span></span>
                <span class="data-label-value">姿势错误：<span>{{sportItem.detail.rateData.errorNum}}</span></span>
                <div class="result-detail-btn-wrapper">
                  <a href="javascript:;" class="detail-btn fs-20" @click="handleSelfSportDetailClick(sportItem.detail)">详情</a>
                </div>
              </div> -->

<!--  v-if="sportItem.detail.sportSfMain && sportItem.detail.sportSfMain.cEyeNum > 0" -->
              <div class="self-train-item close">
                <span class="fs-24-black1">闭眼训练：</span>
                <span class="data-label-value"
                  >平均回位差距：<span>{{sportItem.detail.sportSfMain.cEyeAvgReturnAngleDiff.toFixed(1) | formatAngle}}</span></span
                >
                <span class="data-label-value"
                  >平均定位差距：<span>{{sportItem.detail.sportSfMain.cEyeAvgAngleDiff.toFixed(1) | formatAngle}}</span></span
                >
                <span class="data-label-value">正常率：<span>{{(sportItem.detail.sportSfMain.cEyeCorrect * 100).toFixed(2)}}%</span></span>
                <div class="result-detail-btn-wrapper">
                  <a href="javascript:;" class="detail-btn fs-20" @click="hendleSelfOCSportDetailClick(sportItem.detail,2)">详情</a>
                </div>
              </div>

              <div class="self-train-item open-close"  v-if="sportItem.detail.sportSfMain && sportItem.detail.sportSfMain.ocEyeNum > 0">
                <span class="fs-24-black1">睁闭眼训练：</span>
                <span class="data-label-value">
                  平均回位差距：<span>{{sportItem.detail.sportSfMain.ocEyeAvgReturnAngleDiff.toFixed(1) | formatAngle}}</span>
                  </span>
                <span class="data-label-value">
                  平均定位差距：<span>{{sportItem.detail.sportSfMain.ocEyeAvgAngleDiff.toFixed(1) | formatAngle}}</span>
                </span>
                <span class="data-label-value">正常率：<span>{{(sportItem.detail.sportSfMain.ocEyeCorrect * 100).toFixed(2)}}%</span></span>
                <div class="result-detail-btn-wrapper">
                  <a href="javascript:;" class="detail-btn fs-20" @click="hendleSelfOCSportDetailClick(sportItem.detail,1)">详情</a>
                </div>
              </div>
            </div>
          </div>
          <!--本体感觉训练-->

          <div class="train-detail-row self-train"  v-if="sportItem.sportType === sportProjectTypeEnum.SELFFEEL_OPEN.type && sportItem.detail.hasData">
            <div class="train-item-title-wrapper">
              <span class="fs-24-black1">{{sportProjectTypeEnum.SELFFEEL_OPEN.desc}}:</span>
              <span class="train-scheme-data-title group-no">{{sportItem.detail.sportTime}}组</span>
              <span class="train-scheme-data-title speed">{{sportItem.detail.speed | formatSpeed}}</span>
            </div>
            <div class="flex-1">
              <div class="self-train-item open" v-if="sportItem.detail.rateData">
                <span class="data-label-value">完成率：<span>{{sportItem.detail.rateData.rate}}%</span></span>
                <span class="data-label-value">姿势错误：<span>{{sportItem.detail.rateData.errorNum}}</span></span>
                <div class="result-detail-btn-wrapper">
                  <a href="javascript:;" class="detail-btn fs-20" @click="handleSelfSportDetailClick(sportItem.detail)">详情</a>
                </div>
              </div>
            </div>
          </div>
          <!--运动速度训练-->
            </div>
        </div>
      </div>
    </div>

    <div class="" v-if="item.show && detailData === null">
      无数据
    </div>

  <data-detail-dialog ref="dataDetailDialog"></data-detail-dialog>
  <printer-dialog ref="printerDialog"></printer-dialog>
  </div>
</template>

<script>
import Tabs from "@/components/tabs/Tabs.vue";
import TabItem from "@/components/tabs/TabItem.vue";
import { getReceptionDetail,getReportPrintData } from "@/api/ReceptionService";
import {SportDirectionEnum,SportProjectTypeEnum} from '@/utils/Enums'
import DataDetailDialog from './DataDetailDialog.vue';
import PrinterDialog from './PrinterDialog.vue'

export default {
  props: {
    item: {
      type: Object,
    },
    expand:{
      type:Boolean,
      default:false
    },
    patientName:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      detailData: null,
      hasEvaluationData:false,
      romData:{},
      currentTrainItem:{}
      
    };
  },

  components: { Tabs, TabItem,DataDetailDialog,PrinterDialog },

  computed: {
    sportProjectTypeEnum(){
      return SportProjectTypeEnum;
    }
  },

  mounted() {
    if(this.expand){
      this.handleOpenOrCloseClick()
    }
    
  },

  methods: {
    handleOpenOrCloseClick() {
      this.item.show = !this.item.show;
      if (this.item.show && this.detailData === null) {
        this.getReceptionDetail(this.item.treatmentId);
      }
    },
    getReceptionDetail(treatmentId) {
      getReceptionDetail(treatmentId)
        .then(({ data }) => {

          this.hasEvaluationData = (
            data.evaluation.active !== null ||
            data.evaluation.passive !== null ||
            data.evaluation.sf !== null 
          )

          const hasData = this.hasEvaluationData ||
            data.trains.length > 0;
          
            console.log('hasData',hasData)
          this.detailData = hasData ? data : null;
          hasData && this.parseRomData()
          console.log('currentTrainItem')
          this.currentTrainItem = this.detailData.trains[0];
        })
        .catch(() => {});
    },

  parseRomData(){
    const evaluationData = this.detailData.evaluation
    const romTemplate = [
      SportDirectionEnum.LEFT_FLEXION.type,
      SportDirectionEnum.LEFT_ROTATE.type,
      SportDirectionEnum.PROTRACT.type,
      SportDirectionEnum.RIGHT_FLEXION.type,
      SportDirectionEnum.RIGHT_ROTATE.type,
      SportDirectionEnum.HYPSOKINESIS.type
    ];
   this.romData = romTemplate.map(item=>{
     console.log("romData")
      let active = {angle:null,isAbnormal:0,painType:null,pain:null}
      let passive =  {angle:null,isAbnormal:0,painType:null,pain:null}
      let sf = {angleDiffs:[]}
      if(evaluationData.active && evaluationData.active.details.length > 0){
        active = evaluationData.active.details.filter(el=>el.direction == item)[0] || active
      }
      if(evaluationData.passive && evaluationData.passive.details.length > 0){
        passive = evaluationData.passive.details.filter(el=>el.direction == item)[0] || passive
      }

      if(evaluationData.sf && evaluationData.sf.details.length > 0){
        sf = evaluationData.sf.details.filter(el=>el.direction == item)[0] || sf
      }

      const angleDiffs = []
      console.log("angleDiffs",angleDiffs)
      sf.angleDiffs.forEach(element => {
        angleDiffs.push(`${element}°`)
      });
      sf.angleDiff = angleDiffs.join("、")
      return {
        direction:item,
        active,
        passive,
        sf
      }
    })
  },
  handleEvaluationDetailClick(){
    this.$refs.dataDetailDialog.showPoseErrorDialog(this.detailData.evaluation.active.errorLogs)
  },
  handleNeckSportDetailClick(data,lastPlan){
    this.$refs.dataDetailDialog.showNeckSportDetail(data,lastPlan)
  },
  handleSelfSportDetailClick(data){
    this.$refs.dataDetailDialog.showSelfSportDetail(data);
  },
  hendleSelfOCSportDetailClick(data,type){
    this.$refs.dataDetailDialog.showSelfOCSportDetail(data,type);
  },
  hanleTrainItemTabChange(tab){
    console.log("currentTrainItem")
    this.currentTrainItem = this.detailData.trains[tab.index]
    console.log("this.currentTrainItem",this.currentTrainItem)
  },
  handlePrintReportClick(){
    const loading = this.$loading({
          lock: true,
          text: '处理中，请稍候',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
    getReportPrintData(this.detailData.treatmentId,this.patientName).then(({data})=>{
       loading.close()
       this.$refs.printerDialog.show(data)
    }).catch(()=>{
      loading.close()
    })
  }
    
  },
};
</script>
<style lang="scss" scoped src="./ReceptionLogItem.scss"></style>
