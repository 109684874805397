import VrProgress from "@/components/vr-progress/VRProgress.vue";
import RomEvaluation from "./RomEvaluation.vue";
import PauseDialog from "@/components/pause-dialog/PauseDialog.vue";
import SelfEvaluationResult from "../workbench/SelfEvaluationResult.vue";
import ReasonDialog from "@/components/reason-dialog/ReasonDialog.vue";
import RestartEvaluationDialog from "./RestartEvaluationDialog.vue";
import { EvaluationTypeEnum, getEnumByType } from "@/utils/Enums";
import { WorkbenchMixins,MainServiceCommunication,BackHomeControlMixins } from "@/utils/Mixins";
import WebSocketService from "@/api/WebSocketService";
import { WebSocketCommand } from "@/api/WebSocketService";
import { startEvaluation } from "@/api/EvaluationService";

import {
  getCurrentEvluationData,
  getPainTypeList,
  pauseEvaluation,
  continueEvaluation,
  stopEvaluation,
  finishEvaluation,
  discardEvaluation,
} from "@/api/EvaluationService";
export default {
  mixins: [WorkbenchMixins,MainServiceCommunication,BackHomeControlMixins],
  data() {
    return {
      pageParam: {
        patientCaseId: null,
        treatmentId: null,
        evaluationId: null,
        deviceId: null,
      },
      evaluationData: {
        details: [],
      },
      currentEvaluationType: EvaluationTypeEnum.ACTIVE,
      painList: [],
      isPause: false,
      isStop: false,
      isFinishEvaluation: false,
      deviceData: {},
      exceptionReason:null,
      continueException:false,
    };
  },
  components: {
    VrProgress,
    RomEvaluation,
    SelfEvaluationResult,
    PauseDialog,
    ReasonDialog,
    RestartEvaluationDialog,
  },
  computed: {
    /**
     * 是否完成
     * @returns
     */
    isFinished() {
      return this.isStop || this.isFinishEvaluation;
    },
    /**
     * 能否展示完成按钮
     * @returns
     */
    canFinish() {
      return (
        this.currentEvaluationType === EvaluationTypeEnum.PASSTIVE &&
        !this.isFinished
      );
    },
    /**
     * 是否展示暂停按钮
     * @returns
     */
    canPause() {
      return !this.isPause && !this.isFinished;
    },
    /**
     * 是否展示继续按钮
     * @returns
     */
    canContinue() {
      return this.isPause && !this.isFinished;
    },
    /**
     * 是否暂时终止按钮
     * @returns
     */
    canStop() {
      return !this.isFinished;
    },
    /**
     * 是否展示重新评估按钮
     * @returns
     */
    canRestartEvaluation() {
      return (
        this.isFinishEvaluation &&
        this.currentEvaluationType === EvaluationTypeEnum.ACTIVE
      );
    },
    /**
     * 是否展示弃用按钮
     * @returns
     */
    canDiscard() {
      return this.isFinished && !this.isStop;
    },
    /**
     * 是否展示本体感觉评估视图
     * @returns
     */
    showSelfEvaluationView() {
      return this.currentEvaluationType === EvaluationTypeEnum.SELF;
    },
    /**
     * 是否展示方向动画
     * @returns
     */
    showDirectionGif() {
      return (
        !this.isFinished &&
        !this.isPause &&
        this.currentEvaluationType === EvaluationTypeEnum.ACTIVE
      );
    },
  },
  mounted() {
    this.getEvaluationingData();
    this.getInitData();

    //开始评估
    WebSocketService.addMessageListener(
      WebSocketCommand.evaluationCmd.START_EVA,
      this,
      ()=>{
        console.log("收到消息START_EVA")
        
        // this.getEvaluationingData()
        // this.isFinishEvaluation =false;
        // this.clearVrRequestTimeoutTask();
        window.location.reload();
        console.log("收到消息START_EVA END")
         
      });

      //重新评估
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.MSG_START_EVA,
        this,
        ()=>{
          this.isFinishEvaluation =false;
          this.clearVrRequestTimeoutTask();
          this.getEvaluationingData()
        });

    //获取全量评估数据
    WebSocketService.addMessageListener(
      WebSocketCommand.evaluationCmd.UPDATE_EVALUATION_DATA,
      this,
      this.getEvaluationingData
    );

    // 更新一般疼痛分值
    WebSocketService.addMessageListener(
      WebSocketCommand.evaluationCmd.UPDATE_NORMAL_PAIN,
      this,
      this.getEvaluationingData
    );

    //更新设备电量信息
    WebSocketService.addMessageListener(
      WebSocketCommand.evaluationCmd.UPDATE_DEVICE_INFO,
      this,
      (event) => {
        this.deviceData = event.data;
      }
    );

    // 评估暂停
    WebSocketService.addMessageListener(
      WebSocketCommand.evaluationCmd.PAUSE_EVA,
      this,
      this.handleEvaluationPauseEvent
    );

    // 评估继续
    WebSocketService.addMessageListener(
      WebSocketCommand.evaluationCmd.CONTINUE_EVA,
      this,
      this.handleEvaluationContinueEvent
    );

    // 评估继续失败
    WebSocketService.addMessageListener(
      WebSocketCommand.evaluationCmd.CONTINUE_EVA_EXCEPTION,
      this,
      this.handleEvaluationContinueFailedEvent
    );

    // 评估完成
    WebSocketService.addMessageListener(
      WebSocketCommand.evaluationCmd.FINISH_EVA,
      this,
      this.handleEvaluationFinishEvent
    );

    // 前置机错误消息
    WebSocketService.addMessageListener(
      WebSocketCommand.evaluationCmd.MSG_ERROR_MSG,
      this,
      this.handleMainServiceErrorEvent
    );


  },

  methods: {
    /**
     * 获取初始数据
     */
    getInitData() {
      getPainTypeList()
        .then(({ data }) => {
          this.painList = data;
        })
        .catch(() => {});
    },
    /**
     * 获取评估数据
     */
    getEvaluationingData() {
      getCurrentEvluationData(this.pageParam.treatmentId).then(({ data }) => {
        if (data === null) {
          this.handleEndClick()
          return;
        }
        this.evaluationData = data;
        this.currentEvaluationType = getEnumByType(
          EvaluationTypeEnum,
          data.type
        );

        this.setPageTemplateTitle(this.currentEvaluationType.desc);
        this.hidePageTemplateBackBtn();
        this.isPause = (this.evaluationData.pauseStatus === 0);

        if(this.isPause){
          this.continueException = false;
          this.exceptionReason=null;
        }

      }).catch(()=>{});
    },
    /**
     * 暂停评估
     */
    handlePauseClick() {
      this.continueException = false;
      this.exceptionReason=null;
      pauseEvaluation(this.evaluationData.id).then(() => {
        this.isPause = true;
      });
    },
    /**
     * 继续评估
     */
    handleContinueClick() {
      continueEvaluation(this.evaluationData.id).then(() => {
        this.openVrRequestTimeoutTask(() => {
            this.isPause = true;
            this.continueException = true;
            this.exceptionReason = "连接VR超时...";
          });
      });
    },
    /**
     * 终止评估点击事件
     */
    handleStopClick() {
      this.$refs.stopEvaluationDialog.show();
    },
    /**
     * 终止原因选择事件
     * @param {*} stopReasonId
     */
    handleStopReasonSelect(stopReasonId) {
      const param = {
        abandonId: stopReasonId,
      };
      stopEvaluation(this.evaluationData.id, param)
        .then(() => {
          this.$refs.stopEvaluationDialog.close();
          this.isStop = true;
          this.isPause = false;
        })
        .catch(() => {});
    },
    /**
     * 评估完成后结束评估，结束评估后跳转进入工作台界面
     */
    handleEndClick() {
      //跳转页面
      this.openWorkbenchPage(
        this.pageParam.patientCaseId);
    },
    /**
     * 完成评估后继续新评估
     */
    handleContinueProcessClick() {
      this.back();
      // this.openEvaluationSchemePage(
      //   this.pageParam.patientCaseId,
      //   this.pageParam.treatmentId
      // );
      //跳转方案页面
    },
    /**
     * 被动评估完成操作
     */
    handlePasstiveFinishClick() {
      //调用结束评估接口
      this.$confirm("确定要结束此次评估吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.isFinishEvaluation = true;
          finishEvaluation(this.evaluationData.id)
            .then(() => {})
            .catch(() => {});
        })
        .catch(() => {});
    },
    /**
     * 弃用
     */
    handleDiscardClick() {
      this.$refs.discardEvaluationDialog.show();
    },
    /**
     * 弃用原因选择事件
     * @param {*} reasonId
     */
    handleDiscardReasonSelect(reasonId) {
      const param = {
        abandonId: reasonId,
      };
      discardEvaluation(this.evaluationData.id, param)
        .then(() => {
          this.$refs.discardEvaluationDialog.close();
          this.isStop = true;
          this.isFinishEvaluation = false;
        })
        .catch(() => {});
    },
    /**
     * 处理评估完成事件
     */
    handleEvaluationFinishEvent() {
      this.isFinishEvaluation = true;
    },
    /**
     * 重新评估按钮点击事件
     */
    handleRestartEvaluationClick() {
      this.$refs.restartEvaluationDialog.show();
    },
    /**
     * 处理重新评估方向选择事件
     */
    handleRestartEvaluationDirectionSelect(directions) {
      const request = {
        deviceId: this.pageParam.deviceId,
        type: EvaluationTypeEnum.ACTIVE.type,
        treatment: this.pageParam.treatmentId,
        evaluationPlan: {
          isFirstEvaluation: false,
          evaluationPlans: directions,
        },
        lastEvaluationId: this.evaluationData.id,
      };
      startEvaluation(request)
        .then(() => {
          this.$refs.restartEvaluationDialog.close();

          this.openVrRequestTimeoutTask(()=>{
            this.isFinishEvaluation  = false
            this.getEvaluationingData();
          })
          
        })
        .catch(() => {});
    },
    /**
     * 处理评估暂停消息
     */
    handleEvaluationPauseEvent() {
      this.isPause = true;
      this.continueException = false;
      this.exceptionReason=null;
    },
    /**
     * 处理评估继续消息
     */
    handleEvaluationContinueEvent() {
      this.isPause = false;
      this.clearVrRequestTimeoutTask();
    },
    /**
     * 处理评估继续失败消息
     */
    handleEvaluationContinueFailedEvent(event) {
      this.isPause = true;
      this.continueException = true;
      this.exceptionReason=event.data;
      this.clearVrRequestTimeoutTask();
      // this.$alert(event.data, "提示");
    },
    /**
     * 处理前置机错误消息
     * @param {*} event
     */
    handleMainServiceErrorEvent(event) {
      this.$alert(event.data.errorMsg, "提示");
      if (event.data.stopBusiness) {
        this.openWorkbenchPage(this.pageParam.patientCaseId);
      }
    },
  },
};
