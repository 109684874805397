import {
  getDeviceMatchList,
  getVrList,
  deleteVR,
  changeBind,
} from "@/api/DeviceService";
import DeviceEditDialog from "./DeviceEditDialog.vue";
export default {
  data() {
    return {
      tableData: [],
      vrs: [],
      deviceList: [],
    };
  },

  components: {
    DeviceEditDialog,
  },

  computed: {},

  mounted() {
    this.getInitData();
  },

  methods: {
    /**
     * 获取初始数据
     */
    getInitData() {
      getDeviceMatchList()
        .then(({ data }) => {
          this.tableData = data.rows;
        })
        .catch(() => {});
      getVrList()
        .then(({ data }) => {
          this.vrs = data.rows;
        })
        .catch(() => {});
    },
    handleBindClick(deviceMatch, vrDeviceId) {
      let deviceMatchId = null;
      if (deviceMatch.length > 0) {
        deviceMatchId = deviceMatch[0].id;
      }

      this.$confirm("确定要绑定此VR设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          changeBind(deviceMatchId, { vrDeviceId }).then(() => {
            this.$message({
              type: "success",
              message: "绑定成功!",
            });
            this.getInitData();
          });
        })
        .catch(() => {});
    },
    handleEditClick(vr){
      this.$refs.deviceEditDialog.open(vr)
    },
    handleDeleteClick(vr) {
      this.$confirm("确定执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteVR(vr.id).then(() => {
            this.getInitData();
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          }).catch(() => {});
        })
        .catch(() => {});
    },
  },
};
