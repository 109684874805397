import VrStatus from "@/components/vr-status/VRStatus.vue";
import TrainItem from "@/components/train-item/TrainItem.vue";
import DirectionRatio from "@/components/direction-ratio/DirectionRatio.vue";
import { getDeviceDataByCaseId } from "@/api/DeviceService";
import { SportProjectTypeEnum, SportDirectionEnum } from "@/utils/Enums";
import WebSocketService from "@/api/WebSocketService";
import { WebSocketCommand } from "@/api/WebSocketService";
import { WorkbenchMixins, BackHomeControlMixins } from "@/utils/Mixins";
import {
  getTrainProjectList,
  getPatientTrainRangeData,
  startTrain,
} from "@/api/TrainService";
export default {
  mixins: [WorkbenchMixins, BackHomeControlMixins],
  data() {
    const sportRangeList = Object.values(SportDirectionEnum).map((item) => {
      return {
        type: item.type,
        desc: item.desc,
        lock: false,
        value: 20,
      };
    });

    return {
      pageParam: {
        patientCaseId: null,
        treatmentId: null,
        patientId: null,
      },
      num: 60,
      deviceData: {},
      trainRangeData: {},
      currentSelectTrainRangeIndex: -1,
      neckSport: {
        leftFlexionRotate: 0,
        leftFlexionRange: 20,
        rightFlexionRotate: 0,
        rightFlexionRange: 20,
        leftRotate: 0,
        leftRange: 20,
        rightRotate: 0,
        rightRange: 20,
        protractRotate: 0,
        protractRange: 10,
        hypsokinesisRotate: 0,
        hypsokinesisRange: 10,
      },
      sportProjects: [],
      sportRangeList: sportRangeList,
      selectedDeviceId: null,
      timeoutObj: null,
      lastRangeList: [],
    };
  },

  components: { VrStatus, TrainItem, DirectionRatio },

  watch: {
    rangList: {
      deep: true,
      handler: function(newval, oldval) {
        if (this.lastRangeList.length === 0) {
          this.lastRangeList = newval;
          return;
        }
        const that = this;
        let sum = 0;
        let diff = 0;
        let changeIndex = null;
        for (let i = 0; i < newval.length; i++) {
          if (newval[i] !== oldval[i]) {
            diff = newval[i] - oldval[i];
            changeIndex = i;
          }
          sum += newval[i];
        }
        if (sum !== 100) {
          const arr = newval.map((item, index) => {
            return { key: index, value: item };
          });
          const sortArr = arr
            .sort((a, b) => {
              return a.value - b.value;
            })
            .map(function(item) {
              return item.key;
            });
          if (diff > 0) {
            sortArr.reverse();
          }

          const changeSortList = [];
          sortArr.forEach((itemIndex) => {
            if (
              itemIndex !== changeIndex &&
              !that.sportRangeList[itemIndex].lock
            ) {
              changeSortList.push(itemIndex);
            }
          });

          if (changeSortList.length === 0) {
            newval = oldval;
          } else {
            for (let j = 0; j < Math.abs(diff / 5); j++) {
              if (diff > 0) {
                newval[changeSortList[j % changeSortList.length]] -= 5;
              } else {
                newval[changeSortList[j % changeSortList.length]] += 5;
              }
            }
          }
          console.log("value", newval);
          newval.forEach((value, index) => {
            that.sportRangeList[index].value = value;
          });
        }
      },
    },
  },
  computed: {
    rangList() {
      return [
        this.sportRangeList[0].value,
        this.sportRangeList[1].value,
        this.sportRangeList[2].value,
        this.sportRangeList[3].value,
        this.sportRangeList[4].value,
        this.sportRangeList[5].value,
      ];
    },
    sportProjectTypeEnum() {
      return SportProjectTypeEnum;
    },
    /**
     * 方向类型与训练幅度属性映射
     * @returns
     */
    directionTypeObjectPropertyMap() {
      return {
        leftFlexionRange: SportDirectionEnum.LEFT_FLEXION.type,
        rightFlexionRange: SportDirectionEnum.RIGHT_FLEXION.type,
        leftRange: SportDirectionEnum.LEFT_ROTATE.type,
        rightRange: SportDirectionEnum.RIGHT_ROTATE.type,
        protractRange: SportDirectionEnum.PROTRACT.type,
        hypsokinesisRange: SportDirectionEnum.HYPSOKINESIS.type,
      };
    },
  },

  mounted() {
    this.setPageTemplateTitle("训练方案");
    this.getInitData();
    this.bindWebsocketMessageHandler();
  },

  methods: {
    getInitData() {
      getTrainProjectList(this.pageParam.patientCaseId).then(({ data }) => {
        this.sportProjects = this.parseTrainProject(data);
      });
      getPatientTrainRangeData(this.pageParam.patientId).then(({ data }) => {
        console.log("getPatientTrainRangeData", data);
        this.trainRangeData = data;
        this.copyProperties(data.defaultAnglePlan, this.neckSport);
        //初始化默认幅度
        const directionValues = Object.values(
          this.directionTypeObjectPropertyMap
        );
        const keyValues = Object.keys(this.directionTypeObjectPropertyMap);
        this.sportRangeList.forEach((element) => {
          const keyIndex = directionValues.findIndex(
            (value) => value === element.type
          );
          const key = keyValues[keyIndex];
          const anglePlan = data.lastAnglePlan || data.defaultAnglePlan
          element.value = anglePlan[key];
        });

        

        this.$nextTick(() => {
          let selectedIndex = this.trainRangeData.sportPlans.findIndex(item=>item.defaultSeleced)
          console.log("selectedIndex:"+selectedIndex);
          if(selectedIndex === -1){
            selectedIndex = 0;
          }
          this.handleTrainRangeItemClick(selectedIndex);
        });
      });
      getDeviceDataByCaseId(this.pageParam.patientCaseId).then((device) => {
        this.deviceData = device;
      });
    },
    /**
     * 处理开始评估消息
     */
    handleStartTrainEvent() {
      clearTimeout(this.timeoutObj);
      this.loading.close();

      this.openTrainingPage(
        this.pageParam.patientCaseId,
        this.pageParam.treatmentId,
        this.trainData.id,
        this.trainData.deviceMatchId
      );
    },
    /**
     * 绑定websocket消息处理
     */
    bindWebsocketMessageHandler() {
      //获取全量评估数据
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.START_SPORT,
        this,
        this.handleStartTrainEvent
      );

      // 前置机错误消息
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.MSG_ERROR_MSG,
        this,
        this.handleMainServiceErrorEvent
      );
    },
    handleTrainRangeItemClick(index) {
      this.currentSelectTrainRangeIndex = index;
      const item = this.trainRangeData.sportPlans[index];
      this.copyProperties(item, this.neckSport);
    },
    parseTrainProject(data) {
      let sportProjects = [];
      sportProjects = data.map((item) => {
        // item.isSelected = true;
        item.children = [];
        item.canUp = true;
        item.canDown = true;
        item.sortIndex = 0;
        return item;
      });

      sportProjects = sportProjects.map((item) => {
        item.children = sportProjects.filter(
          (subItem) => subItem.pid === item.id
        );
        return item;
      });

      sportProjects = sportProjects.filter((item) => item.level === 1);
      this.sortProject(sportProjects);
      return sportProjects;
    },
    sortProject(sportProjects) {
      for (let index = 0; index < sportProjects.length; index++) {
        const item = sportProjects[index];
        item.sortIndex = index;
        item.canUp = index > 0;
        item.canDown = true;
      }
      sportProjects[sportProjects.length - 1].canDown = false;
    },
    handleCheckBoxChange() {
      this.sportProjects = this.sortProject(this.sportProjects);
    },
    handleUpEvent(itemIdex) {
      if (!this.sportProjects[itemIdex].canUp) {
        return;
      }
      const temp = this.sportProjects[itemIdex - 1];
      this.$set(this.sportProjects, itemIdex - 1, this.sportProjects[itemIdex]);
      this.$set(this.sportProjects, itemIdex, temp);
      this.sortProject(this.sportProjects);
    },
    handleDownEvent(itemIdex) {
      if (!this.sportProjects[itemIdex].canDown) {
        return;
      }
      const temp = this.sportProjects[itemIdex + 1];
      this.$set(this.sportProjects, itemIdex + 1, this.sportProjects[itemIdex]);
      this.$set(this.sportProjects, itemIdex, temp);
      this.sortProject(this.sportProjects);
    },
    handleSpeedChangeEvent(project, speed) {
      project.speed = speed;
    },
    handleSliderChange(directData) {
      console.log(directData);
    },
    /**
     * 开始训练
     */
    handleStartTrainClick() {
      if (!this.deviceData.isConnect) {
        this.$message.error("设备状态异常");
        return;
      }

      const selectedList = this.getSelectTrainProject();
      if (selectedList.length === 0) {
        this.$message.error("请至少选择一个训练项目！");
        return;
      }

     
      const keyValues = Object.keys(this.directionTypeObjectPropertyMap);
      keyValues.forEach(key=>{
        const type = this.directionTypeObjectPropertyMap[key]
        const value = this.sportRangeList.filter(item=>item.type===type)[0].value
        this.neckSport[key] = value
      });

      const params = {
        sportProjectList: selectedList,
        deviceMatchId: this.deviceData.id,
        treatmentId: this.pageParam.treatmentId,
        patientNeckSport: this.neckSport,
      };

      //校验参数
      const rotateNames = ["左屈","右屈","左旋","右旋","前屈","后伸"]
      const rotates = ["leftFlexionRotate","rightFlexionRotate","leftRotate","rightRotate","protractRotate","hypsokinesisRotate"]
      for(let index = 0;index<rotates.length;index++){
        if(params.patientNeckSport[rotates[index]]===undefined){
          this.$message.error("训练幅度【"+rotateNames[index]+"】数据输入格式错误,请输入有效数字!");
          return;
        }
      }
      for(let index=0;index<params.sportProjectList.length;index++){
        let sportItem = params.sportProjectList[index]
        
        if(sportItem.sprotTime === undefined){
          const sportName = this.sportProjects.filter(item=>item.id === sportItem.id)[0].name
          this.$message.error(sportName+"组数输入格式错误,请输入有效的数字!");
          return;
        }

      }

      this.loading = this.$loading({
        lock: true,
        text: "处理中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      startTrain(params)
        .then(({ data }) => {
          this.trainData = data;
          this.timeoutObj = setTimeout(() => {
            this.$alert("连接VR设备超时！", "警告", {
              callback: () => {
                this.handleStartTrainEvent();
              },
            });
          }, 20 * 1000);
        })
        .catch(() => {
          this.loading.close();
        });
    },
    getSelectTrainProject() {
      const selectedList = [];

      this.sportProjects
        .filter((item) => item.isSelected)
        .forEach((element) => {
          selectedList.push({
            id: element.id,
            sprotTime: element.sprotTime,
            speed: element.speed,
          });

          element.children
            .filter((child) => child.isSelected)
            .forEach((childEl) => {
              selectedList.push({
                id: childEl.id,
                sprotTime: element.sprotTime,
                speed: element.speed,
              });
            });
        });
      return selectedList;
    },
    /**
     * 退出训练
     */
    hanldeExitTrainClick() {
      this.openWorkbenchPage(this.pageParam.patientCaseId)
    },
    /**
     * 处理前置机错误消息
     * @param {*} event
     */
    handleMainServiceErrorEvent(event) {
      this.loading.close();
      clearTimeout(this.timeoutObj);
      this.$alert(event.data.errorMsg, "提示");
      if (event.data.stopBusiness) {
        this.openWorkbenchPage(this.pageParam.patientCaseId);
      }
    },
    handleSelfDetailItemChange() {
      const selfItem = this.sportProjects.filter(
        (item) => item.type === SportProjectTypeEnum.SELFFEEL.type
      )[0];
      const childSelectCount = selfItem.children.filter(
        (item) => item.isSelected
      ).length;
      selfItem.isSelected = childSelectCount > 0;
    },
  },
};
