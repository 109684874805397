import request from "@/utils/Request";



/**
 * 获取患者基本信息
 * @returns
 */
export function getPatientData(patientCaseId) {
  return request({
    url: `/api/hospital-data/treatment/detailbycase/${patientCaseId}`,
    method: "get",
  });
}

/**
 * 更新患者基本信息
 * @returns
 */
 export function updatePatientData(patientId,data) {
  return request({
    url: `/api/hospital-data/patient/form/${patientId}`,
    method: "put",
    data
  });
}

/**
 *  获取历史病程
 * @returns
 */
export function getHistoryPatientCase(patientCaseId) {
  return request({
    url: `/api/hospital-data/patientcase/history/by/case/${patientCaseId}`,
    method: "get",
  });
}

/**
 *  病程下最近评估记录
 * @returns
 */
export function getLastEvaluationData(patientCaseId) {
  return request({
    url: `/api/hospital-data/patientcase/last/evaluation/${patientCaseId}`,
    method: "get",
  });
}

/**
 * 获取诊断列表
 * @returns
 */
export function getDiagnosisList() {
  return request({
    url: "/api/hospital-data/patientcase/diagnosistype",
    method: "get",
  });
}

/**
 * 获取最近接诊
 * dateType
 * 日期类型 0 本周 1 本月
 * @returns
 */
export function getLastReceptionList(dateType) {
  return request({
    url: `/api/hospital-data/patient/recent?dateType=${dateType}`,
    method: "get",
  });
}

/**
 * 进行接诊
 * @param {*} dateType
 * @returns
 */
export function doReception(data) {
  return request({
    url: `/api/hospital-data/patient/treatment`,
    method: "post",
    data,
  });
}

/**
 * 获取正在进行的评估或者训练
 * currentWaitingType：
 * 1 评估中
 * 2 训练中
 * @param {*} treatmentId 
 * @returns 
 */
export function getCurrentTrainOrEvaluationData(treatmentId){
  return request({
    url: ` /api/hospital-data/treatment/waiting/module/${treatmentId}`,
    method: "get",
  });
 
}

