import { Tabs, TabItem } from "@/components/tabs";
import EvaluationActive from "./evaluation/EvaluationActive.vue";
import SelfEvaluation from './evaluation/SelfEvaluation.vue';
import StandardNeck from './sport/StandardNeck.vue'
import NeckMuscles from './sport/NeckMuscles.vue'
import SelfOpen from './sport/SelfOpen.vue'
import SelfOpenClose from './sport/SelfOpenClose.vue'
import { BackHomeControlMixins } from "@/utils/Mixins";
import {getEvaluationTrendData,getTrainTrendData} from '@/api/TrendService'
export default {
  mixins: [BackHomeControlMixins],
  data() {
    return {
      pageParam:{
        patientCaseId:null,
      },
      patientCase: "",
      activeName: "first",
      selfEvaluationChart:null,
      currentPage:'evaluation',
      evaluationTrendData:null,
      trainTrendData:null,
    };
  },

  components: {
    Tabs,
    TabItem,
    EvaluationActive,
    SelfEvaluation,
    StandardNeck,
    NeckMuscles,
    SelfOpen,
    SelfOpenClose
  },

  computed: {},

  mounted() {
    this.setPageTemplateTitle("趋势统计")
    this.showBackHomeButton();
    this.getInitData()
  },

  methods: {
    getInitData(){
      getEvaluationTrendData(this.pageParam.patientCaseId).then(({data})=>{
        this.evaluationTrendData = data
      }).catch(()=>{})
      getTrainTrendData(this.pageParam.patientCaseId).then(({data})=>{
        this.trainTrendData = data
      }).catch(()=>{})
    },
    changePage(pageType){
      this.currentPage = pageType
    }
   
  },
};
