.<template>
  <div class="self-evaluation-component">
    <chart chart-title="平均角度差距" ref="selfEvaluationChartEl"></chart>
  </div>
</template>

<script>
import Chart from "../Chart.vue";
import ChartUtils from "@/utils/ChartUtils";
import {getChartData,getXAxisLabel} from '@/api/TrendService'
import {chartFormat,fixedValue} from "@/utils/ChartUtils";
export default {
  props: {
    trendData: {
      type: Object,
    },
  },
  components: {
    Chart,
  },
  data() {
    return {
      selfEvaluationChart: null,
      averageAngularVelocityChart: null,
      painScoreChart: null,
      generalScoreChart: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.selfEvaluationChart = this.$refs.selfEvaluationChartEl;
      this.loadData();
    },
    loadChart(option) {
      this.chart.loadChart(option);
    },
    loadData() {
      this.selfEvaluationChart.loadChart(this.getSelfEvaluationOption());
    },
    getSelfEvaluationOption() {
      const option = ChartUtils.getTrendStatisticsChartOptionTemplate();
      option.yAxis[0].name = "(角度)";
      option.xAxis.data = getXAxisLabel(this.trendData)
      option.tooltip.formatter = chartFormat("°");

      option.yAxis[0].max = 20;
      const data = getChartData(this.trendData,'avgAngleDiff')

      const chartData = fixedValue(data.values,1)

      const item = ChartUtils.getSelfEvaluationChartBarSeries(
        "平均角度差距",
        "#56a793",
        "#56a793",
       chartData[0]
      );
      item.label.color = "#12a5ef";
      option.series = [item];
      return option;
    },
  },
};
</script>

<style lang="scss"></style>
