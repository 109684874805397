import request from "@/utils/Request";

/**
 * 获取设备信息
 * @param {*} caseId 病程ID
 * @returns
 */
export function getDeviceDataByCaseId(caseId) {
  return new Promise(function(resolve, reject) {
    request({
      url: `/api/main-data/device/match/by/case/${caseId}`,
      method: "get",
    })
      .then(({ data }) => {
        let hasDevice = false;
        let isConnect = false;
        let deviceItem = {}
        if (data.length) {
          deviceItem = data[0];
          hasDevice = true;
          isConnect =
            deviceItem.hostConnect === 1 && deviceItem.vrConnect === 1;
        }
        deviceItem.hasDevice = hasDevice;
        deviceItem.isConnect = isConnect;

        resolve(deviceItem);
      })
      .catch((err) => {
        reject(err);
      });
  });
}



/**
 * 获取设备列表
 * @returns
 */
 export function getDeviceMatchList() {
  return request({
    url: `/api/main-data/device/match`,
    method: "get",
    params:{
      keyword:'',
      pageSize:100,
      pageNum:1
    }
  });
}

/**
 * 获取设备列表
 * @returns
 */
 export function getVrList() {
  return request({
    url: `/api/main-data/system/device`,
    method: "get",
    params:{
      type:1,
    }
  });
}

/**
 * 保存设备
 * @param {*} data 
 * @returns 
 */
export function saveDevice(data) {
  return request({
    url: `/api/main-data/system/device`,
    method: "post",
    data
  });
}

/**
 * 删除设备
 * @param {*} deviceId 
 * @returns 
 */
export function deleteVR(deviceId){
  return request({
    url: `/api/main-data/system/device/${deviceId}`,
    method: "delete"
  });
}


/**
 * 修改绑定
 * @param {*} deviceMatchId 
 * @returns 
 */
export function changeBind(deviceMatchId,params){
  return request({
    url: `/api/main-data/device/match/changebind/${deviceMatchId}`,
    method: "get",
    params
  });
}