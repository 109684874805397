.<template>
  <div class="components self-evaluation-component">
    <div class="icon-title angle">差距角度</div>
    <div class="chart-wrapper">
      <div class="chart-legend">
        <div class="legend-item">
          <span :style="{ background: normalColor }" class="color-block"></span
          >正常
        </div>
        <div class="legend-item">
          <span
            :style="{ background: abNormalColor }"
            class="color-block"
          ></span
          >异常
        </div>
      </div>
      <e-charts ref="selfEvaluationChart"></e-charts>
    </div>
  </div>
</template>

<script>
import ECharts from "@/components/chart/ECharts.vue";
import ChartUtils from "@/utils/ChartUtils";
import {SportDirectionEnum} from '@/utils/Enums'
export default {
  props: {
    evaluationData: {
      type: Object,
      default: () => {
        return {
          details: [],
        };
      },
    },
  },
  components: {
    ECharts,
  },
  data() {
    return {
      chart: null,
      normalColor: ChartUtils.chartConstants.selfBarNormalColor,
      abNormalColor: ChartUtils.chartConstants.selfAbnormalBarColor,
    };
  },
  watch: {
    evaluationData: function() {
      this.chart.loadChart(this.getChartOptions());
    },
  },
  mounted() {
    console.log("data", this.evaluationData);
    this.chart = this.$refs.selfEvaluationChart;
    this.chart.loadChart(this.getChartOptions());
  },

  methods: {
    getChartOptions() {
      const option = ChartUtils.getLastSelfEvaluationChartOption();

      option.xAxis.data = ["前屈", "后伸", "左屈", "右屈", "左旋", "右旋"];
      option.series = [];

      if (this.evaluationData.details.length === 0) {
        return option;
      }

      const directionList = [
        SportDirectionEnum.PROTRACT.type,
        SportDirectionEnum.HYPSOKINESIS.type,
        SportDirectionEnum.LEFT_FLEXION.type,
        SportDirectionEnum.RIGHT_FLEXION.type,
        SportDirectionEnum.LEFT_ROTATE.type,
        SportDirectionEnum.RIGHT_ROTATE.type
      ]
      const groupCount = this.evaluationData.details[0].angleDiffs.length;


      for (let groupIndex = 0; groupIndex < groupCount; groupIndex++) {
        const groupData = [];
        for (
          let index = 0;
          index < directionList.length;
          index++
        ) {
         const direction = directionList[index]
         const items = this.evaluationData.details.filter(item=>item.direction == direction)

          if(items.length>0){
             const item = items[0];
             groupData.push(item.angleDiffs[groupIndex]);
          }else{
            groupData.push(null);
          }
         
        }

        const series = ChartUtils.getSelfEvaluationChartBarSeries(
          `第${groupIndex + 1}组`,
          ChartUtils.chartConstants.selfBarNormalColor,
          ChartUtils.chartConstants.selfAbnormalBarColor,
          groupData
        );
        option.series.push(series);
      }
      return option;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variable.scss";
@import "../../styles/mixin.scss";
.self-evaluation-component {
  .chart-wrapper {
    margin-top: 44px;
    position: relative;
    height: 460px;

    .chart-legend {
      display: flex;
      @include position(absolute, top -10px right 10px);
      @include font-size-color(18px, $backColor1);

      .legend-item {
        margin-left: 40px;
        display: flex;
        align-items: center;
      }
      .color-block {
        margin-right: 10px;
        width: 34px;
        height: 25px;
      }
    }
  }
}
</style>
