<!--标准颈部-->.
<template>
  <div class="standard-neck-component">
    <chart chart-title="训练时间" ref="trainTimeChartEl"></chart>
    <chart chart-title="完成率" ref="completionRateChartEl"></chart>
    <chart chart-title="角速度" ref="angularVelocityChartEl"></chart>
    <chart chart-title="最大角度" ref="maxAngleChartEl"></chart>
  </div>
</template>

<script>
import Chart from "../Chart.vue";
import ChartUtils from "@/utils/ChartUtils";
import {getChartData,getXAxisLabel} from '@/api/TrendService'
import {chartFormat,fixedValue} from "@/utils/ChartUtils";

export default {
  props: {
     trendData:{
        type:Object
      }
  },
  components: {
    Chart,
  },
  data() {
    return {
      trainTimeChart: null,
      completionRateChart: null,
      angularVelocityChart: null,
      maxAngleChart: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.trainTimeChart = this.$refs.trainTimeChartEl;
      this.completionRateChart = this.$refs.completionRateChartEl;
      this.angularVelocityChart = this.$refs.angularVelocityChartEl;
      this.maxAngleChart = this.$refs.maxAngleChartEl;

      this.loadData();
    },
    loadChart(option) {
      this.chart.loadChart(option);
    },
    loadData() {
      this.trainTimeChart.loadChart(this.getTrainTimeChartOption());
      this.completionRateChart.loadChart(this.getCompletionRateChartOption());
      this.angularVelocityChart.loadChart(this.getAvgAngularVelocityOption());
      this.maxAngleChart.loadChart(this.getMaxAngleChartOption());
    },

    /**
     * 获取训练时长图表数据
     */
    getTrainTimeChartOption() {
      const option = ChartUtils.getTrendStatisticsLineChartOption();
      option.legend = null;
      option.tooltip.formatter = chartFormat("分钟",1);
      
      option.yAxis[0].name = "(分钟)";
      option.xAxis.data = getXAxisLabel(this.trendData)

      option.yAxis[0].axisLabel.formatter = null;
      option.yAxis[0].minInterval = 2;

      const lineSeries = ChartUtils.getLineAreaSeriesTemplate();
      lineSeries.name="训练时间"
      const chartData = getChartData(this.trendData,'trainDuration')

      lineSeries.data = chartData.values[0].map(item=>(item/60).toFixed(2));

      option.series = lineSeries;
      return option;
    },
    /**
     * 获取完成率图表数据
     */
    getCompletionRateChartOption() {
      const option = ChartUtils.getTrendStatisticsChartOptionTemplate();
      option.yAxis[0].name = "(百分比)";
      option.xAxis.data = getXAxisLabel(this.trendData)
      option.yAxis[0].max = 100;
      option.yAxis[0].interval = 20;
      option.tooltip.formatter = chartFormat("%",null);

      const data = getChartData(this.trendData,'rate')
      const chartData = fixedValue(data.values,0)

      const item = ChartUtils.getSelfEvaluationChartBarSeries(
        "完成率",
        "#56a793",
        "#56a793",
        chartData[0]
      );
      item.label.color = "#12a5ef";
      item.label.formatter= "{c}%",
      option.series = [item];
      return option;
    },
    /**
     * 活动度图表数据
     */
    getAvgAngularVelocityOption() {
      const option = ChartUtils.getTrendStatisticsLineChartOption();
      option.xAxis.data = getXAxisLabel(this.trendData);
      option.yAxis[0].name = "(度/秒)";
      option.yAxis[0].axisLabel.formatter = null;
      option.tooltip.formatter = chartFormat("°/s",1);

       option.color = [
      "#62c056",
      "#12a5ef"
    ];
    const chartData = getChartData(this.trendData,'speed')

     option.legend.data =
     [
        "平均角速度",
        "最大角速度"
      ];
      option.series = [
        ChartUtils.getLineSeries("平均角速度", chartData.values[0]),
        ChartUtils.getLineSeries("最大角速度", chartData.values[1]),
      ];
      return option;
    },

    /**
     * 获取最大角度图表数据
     */
    getMaxAngleChartOption() {
      const option = ChartUtils.getTrendStatisticsLineChartOption();
      option.xAxis.data = getXAxisLabel(this.trendData);
      option.yAxis[0].name = "(角度)";
      option.yAxis[0].axisLabel.formatter = null;
      option.tooltip.formatter = chartFormat("°",1);
      
      const romData = getChartData(this.trendData,'maxAngle')


      option.series = [
        ChartUtils.getLineSeries("左屈", romData.values[0]),
        ChartUtils.getLineSeries("右屈", romData.values[1]),
        ChartUtils.getLineSeries("左旋", romData.values[2]),
        ChartUtils.getLineSeries("右旋", romData.values[3]),
        ChartUtils.getLineSeries("前屈", romData.values[4]),
        ChartUtils.getLineSeries("后伸", romData.values[5]),
      ];
      return option;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";
.standard-neck-component {
}
</style>
