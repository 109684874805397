import { Tabs, TabItem } from "@/components/tabs";
import DictList from './dict/DictList.vue'
import UserList from './user/UserList.vue'
import DeviceList from './device/DeviceList.vue'

export default {
  data() {
    return {
    };
  },

  components: { 
    Tabs,
    TabItem,
    DictList,
    UserList,
    DeviceList
   },

  computed: {},

  mounted() {
    this.setPageTemplateTitle("系统管理");
   
  },

  methods: {
   
  },
};
