<!-- 训练方案中训练项目组件 -->
<template>
  <div
    class="component train-item-component"
    :class="{ 'bottom-line': showBottomBorder }"
  >
    <div class="train-img " :class="[`train-img-${project.type}`]"></div>
    <div class="train-title">{{ project.name }}</div>
    <div class="num-group-wrapper">
      <span class="fs-20-black1 mg-r-20">组数:</span>
      <div class="input-number-wrapper">
        <el-input-number
          v-model="project.sprotTime"
          :min="project.minTime"
          :max="project.maxTime"
          :precision="0"
        ></el-input-number>
      </div>
    </div>
    <div class="flex-1"></div>
    <div class="speed-wrapper" v-show="project.speed !== null">
      <span class="fs-20-black1 mg-r-20">速度:</span>
      <div class="radio-btn-group">
        <div
          class="radio-btn "
          @click="handleSpeedChange(0)"
          :class="{ active: project.speed === 0 }"
        >
          慢速
        </div>
        <div
          class="radio-btn "
          @click="handleSpeedChange(1)"
          :class="{ active: project.speed === 1 }"
        >
          正常
        </div>
      </div>
    </div>
    <div class="sort-wrapper">
      <div
        class="sort-btn up"
        :class="{ disabled: !project.canUp}"
        @click="handleUpClick"
      ></div>
      <div
        class="sort-btn down "
        :class="{ disabled: !project.canDown }"
        @click="hanldeDownClick"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showBottomBorder: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
    }
  },
  data() {
    return {
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    handleUpClick() {
      this.$emit("onUp", this.project.sortIndex);
    },
    hanldeDownClick() {
      this.$emit("onDown", this.project.sortIndex);
    },
    handleSpeedChange(speed) {
      this.project.speed = speed
      this.$emit("onSpeedChange", this.project,speed);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

.train-item-component {
  @include flex_layout(row, null, center);

  &.bottom-line {
    border-bottom: 1px solid $pageBorderColor;
  }

  .train-img {
    width: 247px;
    height: 129px;
    @include bg-image(null, 247px, center);

    &.train-img-1 {
      background-image: url("../../assets/train-scheme/1.png");
    }

    &.train-img-2 {
      background-image: url("../../assets/train-scheme/2.png");
    }

    &.train-img-3 {
      background-image: url("../../assets/train-scheme/3.png");
    }

    &.train-img-4{
       background-image: url("../../assets/train-scheme/4.jpg");
    }
  }

  .train-title {
    margin-left: 30px;
    @include font-size-color(24px, $backColor1);
  }

  .num-group-wrapper,
  .speed-wrapper,
  .sort-wrapper {
    @include flex_layout(row, null, center);
  }

  .num-group-wrapper {
    margin-left: 80px;
  }
  .sort-wrapper {
    margin-left: 70px;
    .sort-btn {
      width: 60px;
      height: 60px;
      @include bg-image(null, 34px, center);

      &.up {
        background-image: url("../../assets/sort/up.png");
        &.disabled {
          background-image: url("../../assets/sort/up_0.png");
        }
      }

      &.down {
        background-image: url("../../assets/sort/down.png");
        &.disabled {
          background-image: url("../../assets/sort/down_0.png");
        }
      }
    }
  }
}
</style>
