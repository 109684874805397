<!-- 回位差距，定位差距组图 -->
<template>
  <div class="component self-point-chart-group-component">
    <self-point-chart ref="backwardDataChart" chart-title="回位差距" ></self-point-chart>

    <div class="line"></div>
    <self-point-chart ref="forwardDataChart" chart-title="定位差距"></self-point-chart>
  </div>
</template>

<script>
import SelfPointChart from "./SelfPointChart.vue";
import { fillSFData } from "@/api/CommonService";
export default {
  props: {},
  data() {
    return {
      pointData: [],
      dataType: 1,
    };
  },

  components: { SelfPointChart },

  computed: {},

  mounted() {},

  methods: {
    loadData(pointData, dataType) {
      this.pointData = pointData;
      this.dataType = dataType;

      const forwardData = [];
      const backwardData = [];

      for (let j = 0; j < pointData.length; j++) {
        if (pointData[j].type == dataType) {
          fillSFData(pointData[j], forwardData, backwardData);
        }
      }

      this.$refs.backwardDataChart.loadData(backwardData);
      this.$refs.forwardDataChart.loadData(forwardData);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

.self-point-chart-group-component {
  .line {
    height: 1px;
    background: $pageBorderColor;
    margin: 20px 0px;
  }
}
</style>
