<!-- 工作台 -->
<template>
  <div class="sub-page trend-statistics-page">
    <div class="category-wrapper">
      <div class="radio-btn-group">
        <div
          class="radio-btn "
          :class="{ active: 'evaluation' === currentPage }"
          @click="changePage('evaluation')"
        >
          评估
        </div>
        <div
          class="radio-btn"
          :class="{ active: 'sport' === currentPage }"
          @click="changePage('sport')"
        >
          训练
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="tabs-wrapper" v-show="'evaluation' == currentPage">
        <tabs>
          <tab-item title="主动评估" :active="true">
            <evaluation-active v-if="evaluationTrendData" :trendData="evaluationTrendData.activeEva" :isAciveEvaluation="true"></evaluation-active>
          </tab-item>
          <tab-item title="被动评估">
            <evaluation-active v-if="evaluationTrendData" :trendData="evaluationTrendData.passiveEva" :isAciveEvaluation="false"></evaluation-active>
          </tab-item>
          <tab-item title="本体感觉评估">
            <self-evaluation v-if="evaluationTrendData" :trendData="evaluationTrendData.sfEva"></self-evaluation>
          </tab-item>
        </tabs>
      </div>
      <div class="tabs-wrapper" v-if="'sport' == currentPage">
        <tabs v-if="trainTrendData">
          <tab-item title="标准颈部训练" :active="true">
            <standard-neck :trendData="trainTrendData.neckTrain"></standard-neck>
          </tab-item>
          <tab-item title="肩颈肌肉训练">
            <neck-muscles :trendData="trainTrendData.neckMuscleTrain"></neck-muscles>
          </tab-item>
          <tab-item title="运动速度训练">
            <self-open :trendData="trainTrendData.sfOpenTrain"></self-open>
          </tab-item>
          <tab-item title="本体感觉训练  闭眼">
            <self-open-close :trendData="trainTrendData.sfCloseTrain"></self-open-close>
          </tab-item>
          <tab-item title="本体感觉训练  睁眼+闭眼">
            <self-open-close :trendData="trainTrendData.sfOpenCloseTrain"></self-open-close>
          </tab-item>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import TrendStatisticsInstance from "./TrendStatistics";
export default TrendStatisticsInstance;
</script>
<style lang="scss" scoped>
@import "./TrendStatistics.scss";
</style>
