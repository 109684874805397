import EvaluationResult from "./EvaluationResult.vue";
import SelfEvaluationResult from "./SelfEvaluationResult.vue";
import { Tabs, TabItem } from "@/components/tabs";
import { WorkbenchMixins } from "@/utils/Mixins";
import ImgPlaceholder from "@/components/image-placeholder/ImgPlaceholder.vue";
import { SportDirectionEnum } from "@/utils/Enums";
import {
  getPatientData,
  getHistoryPatientCase,
  getLastEvaluationData,
  getCurrentTrainOrEvaluationData
} from "@/api/WorkbenchService";
import { getLocalPatientById} from "@/api/LocalProxyService";


import {getDeviceDataByCaseId} from "@/api/DeviceService"

export default {
  mixins: [WorkbenchMixins],
  data() {
    return {
      pageParam: {
        patientCaseId: null,
        showOnly: null,
      },
      historyPatientCaseList: [],
      historyPatientCaseId: null,
      lastEvaluationData: {
       
      },
      lastEvaluationTime: "",
      patientCase: {},
      activeName: "first",
      loaded:false,
      patientName:''
    };
  },

  components: {
    EvaluationResult,
    SelfEvaluationResult,
    Tabs,
    TabItem,
    ImgPlaceholder,
  },
  

  computed: {
    showFooterBar() {
      if (this.patientCase.lastTreatmentisToday !== undefined) {
        return this.patientCase.lastTreatmentisToday;
      }
      return false;
    },
  },
  watch:{
    '$route'(to,from){
      console.log(to,from)
      this.pageParam.patientCaseId = to.query.patientCaseId
      this.getInitData()
    },
   
  },

  mounted() {
    const backHomeFlag =  this.pageParam.showOnly === undefined
    this.$store.commit('setBackHomeFlag',backHomeFlag)
    this.setPageTemplateTitle("工作台");
    this.getInitData();
  },

  beforeDestroy() {
    this.$store.commit('setBackHomeFlag',false)
  },
  methods: {
    getInitData() {
      getPatientData(this.pageParam.patientCaseId)
        .then(({ data }) => {
          this.patientCase = data;
          this.historyPatientCaseId = this.patientCase.patientCaseId;
          if (parseInt(data.treatmentTotal) > 0) {
            this.patientCase.treatmentPercent =
              (parseInt(data.treatmentNum) / parseInt(data.treatmentTotal)) *
              100;
          } else {
            this.patientCase.treatmentPercent = 0;
          }
          getLocalPatientById(data.patientId,(patient)=>{
            this.patientName = patient.name
          })

          this.getCurrentTrainOrEvaluationData(this.patientCase.lastTreatmentId)
        })
        .catch(() => {});

      getHistoryPatientCase(this.pageParam.patientCaseId)
        .then(({ data }) => {
          this.historyPatientCaseList = data;
        })
        .catch(() => {});

      getLastEvaluationData(this.pageParam.patientCaseId)
        .then(({ data }) => {
          
          // if(data.active === null){
          //   data.active = {createTime:"",pain:null}
          // }
          this.lastEvaluationTime = data.active ? data.active.createTime : "";
          
          //判断被动评估方向是否完整
          if (data.passive && data.passive.details.length < 6) {
            const directions = Object.values(SportDirectionEnum);
            const details = [];
            for (let index = 0; index < directions.length; index++) {
              const direction = directions[index];
              const tempArr = data.passive.details.filter(
                (item) => item.direction == direction.type
              );
              const detailItem =
                tempArr.length > 0
                  ? tempArr[0]
                  : {
                      angle: null,
                      direction: "" + direction.type,
                      errorDesc: null,
                      id: null,
                      isAbnormal: 0,
                      pain: null,
                      painType: null,
                    };
              details.push(detailItem);
            }
            data.passive.details = details;
          }
          this.lastEvaluationData = data;
        })
        .catch(() => {});

        
    },

  

    getCurrentTrainOrEvaluationData(treatmentId){
      getCurrentTrainOrEvaluationData(treatmentId).then(({data})=>{
        if(data.currentWaitingType == 1 ){
          //1 评估中
          this.openEvaluationingPage(
            this.patientCase.lastPatientCaseId, 
            this.patientCase.lastTreatmentId,
            data.waitingEvaluationId, 
            data.waitingDeviceMatchId
            )

        }else if(data.currentWaitingType == 2){
          //2 训练中
          this.openTrainingPage(
            this.patientCase.lastPatientCaseId, 
            this.patientCase.lastTreatmentId,
            data.waitingTreatmentSportId, 
            data.waitingDeviceMatchId
          )
         
        }

       

      }).catch(()=>{})
    },

    handleEvaluationTabChange(tab) {
      if (tab.dataAttrs) {
        this.lastEvaluationTime = tab.dataAttrs.createTime;
      }
      console.log(tab);
    },
    handleStartEvaluationClick() {
      getDeviceDataByCaseId(this.pageParam.patientCaseId).then((data)=>{
        if(data.hasDevice){
          this.openEvaluationSchemePage(
            this.pageParam.patientCaseId,
            this.patientCase.lastTreatmentId
          );
        }else{
          this.$message.error("暂无可用设备!")
        }
      }).catch(()=>{})
      
    },
    handleStartTrainClick() {

      getDeviceDataByCaseId(this.pageParam.patientCaseId).then((data)=>{
        if(data.hasDevice){
          this.openTrainSchemePage(
            this.pageParam.patientCaseId,
            this.patientCase.lastTreatmentId,
            this.patientCase.patientId
          );
        }else{
          this.$message.error("暂无可用设备!")
        }
      }).catch(()=>{})

     
    },
    handleDiagnosisResultsClick() {
      this.navigateTo(
        `/page/diagnosis-results?patientCaseId=${this.pageParam.patientCaseId}&patientId=${this.patientCase.patientId}&treatmentNum=${this.patientCase.treatmentTotal}`
      );
    },
    handleReceptionLogClick() {
      this.navigateTo(
        `/page/reception-log?patientCaseId=${this.pageParam.patientCaseId}&patientName=${this.patientName}`
      );
    },
    handleTrendClick() {
      this.navigateTo(
        `/page/trend?patientCaseId=${this.pageParam.patientCaseId}`
      );
    },
    handlePatientEditClick() {
      this.navigateTo(
        `/page/reception?patientCaseId=${this.pageParam.patientCaseId}`
      );
    },
    handleHistroyPatientCaseChange(id) {
       this.openWorkbenchPage(id);  
    },
  },
};
