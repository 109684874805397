import PatientCard from "./PatientCard.vue";
import { getDoctorList, getDiagnosisList } from "@/api/ReceptionService";
// import { getPatientList } from "@/api/PatientService";
import ImgPlaceholder from "@/components/image-placeholder/ImgPlaceholder.vue"
import {findPatientPage} from "@/api/LocalProxyService"
export default {
  data() {
    return {
      options: [],
      doctors: [],
      diagnosis: [],
      patientList: [],
      totalPatient: 0,
      queryParam: {
        dateType: "",
        doctorId: "",
        diagnosis: "",
        gender: "",
        pageNum: 1,
        pageSize: 12,
        orderField: "receptionTime",
        orderType: "desc"
      },
    };
  },

  components: { PatientCard,ImgPlaceholder},

  computed: {},

  mounted() {
    this.setPageTemplateTitle("患者信息");
    this.getInitData();
    this.getPatientList();
  },

  methods: {
    getInitData() {
      getDoctorList()
        .then(({ data }) => {
          this.doctors = data;
        })
        .catch(() => {});
      getDiagnosisList()
        .then(({ data }) => {
          this.diagnosis = data;
        })
        .catch(() => {});
    },
    getPatientList() {
      // getPatientList(this.queryParam).then(({ data }) => {
      //   this.totalPatient = data.total;
      //   this.patientList = data.rows;
      // });
      findPatientPage(this.queryParam).then(({ data }) => {
          console.log("findPatientPage",data)
          this.totalPatient = data.total;
          this.patientList = data.rows;
        });
    },
    handlePageNoChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.getPatientList();
    },
    handleSearchClick() {
      this.queryParam.pageNum = 1;
      this.getPatientList();
    },
    handlePatientCardClick(patient) {
      this.navigateTo(`/page/workbench?patientCaseId=${patient.patientCaseId}&showOnly=true`)
    },
  },
};
