<!-- 评估结果 -->
<template>
  <div class="components evaluation-component">
    <div class="icon-title angle">运动角度/激惹疼痛打分</div>

    <div class="data-wrapper">
      <div class="direction-table">
        <div class="direction-head">
          <div class="icon"></div>
          <div class="direction">方向</div>
          <div class="angle">角度</div>
          <div class="pain">疼痛分</div>
          <div class="pain-type">疼痛类型</div>
        </div>
        <div class="direction-list">
          <div
            class="direction-row"
            :key="item.id"
            v-show="index < 3"
            v-for="(item, index) in evaluationData.details"
          >
            <div class="icon">
              <div
                class="icon-wrapper"
                :class="[`direction-icon-${item.direction}`]"
              ></div>
            </div>
            <div class="direction">
              {{ item.direction | formatSportDirection }}
            </div>
            <div class="angle">
              <span v-if="!item.isAbnormal"> {{ item.angle | formatAngle }}</span>
              <el-popover
                v-else
                placement="top-start"
                trigger="hover"
              >
              <div class="abnormal-desc-wrapper">
                <span class="title">姿势错误:</span>{{item.errorDesc}}
              </div>
                <span slot="reference" class="abnormal-data-wrapper"
                  >{{ item.angle | formatAngle}}<i class="el-icon-warning-outline"></i
                ></span>
              </el-popover>
            </div>
            <div class="pain">{{ item.pain | formatEmpty("--") }}</div>
            <div class="pain-type">{{ item.painType | formatEmpty("--") }}</div>
          </div>
        </div>
      </div>

      <div class="space-wrapper"></div>

      <div class="direction-table">
        <div class="direction-head">
          <div class="icon"></div>
          <div class="direction">方向</div>
          <div class="angle">角度</div>
          <div class="pain">疼痛分</div>
          <div class="pain-type">疼痛类型</div>
        </div>
        <div class="direction-list">
          <div
            class="direction-row"
            :key="item.id"
            v-show="index > 2"
            v-for="(item, index) in evaluationData.details"
          >
            <div class="icon">
              <div
                class="icon-wrapper"
                :class="[`direction-icon-${item.direction}`]"
              ></div>
            </div>
            <div class="direction">
              {{ item.direction | formatSportDirection }}
            </div>
            <div class="angle">
              <span v-if="!item.isAbnormal"> {{ item.angle | formatAngle}}</span>
              <el-popover
                v-else
                placement="top-start"
                trigger="hover"
              >
              <div class="abnormal-desc-wrapper">
                <span class="title">姿势错误:</span>{{item.errorDesc}}
              </div>
                <span slot="reference" class="abnormal-data-wrapper"
                  >{{ item.angle | formatAngle}}<i class="el-icon-warning-outline"></i
                ></span>
              </el-popover>
            </div>
            <div class="pain">{{ item.pain | formatEmpty("--")}}</div>
            <div class="pain-type">{{ item.painType | formatEmpty("--") }}</div>
          </div>
        </div>
      </div>
    </div>

  <template v-if="showPainProgress">
    <div class="icon-title commonly-pain" >一般疼痛打分</div>
    <div class="pain-bar-wrapper">
      <pain-progress :value="parseInt(evaluationData.pain)" v-if="evaluationData.pain !== null"></pain-progress>
      <div class="normal-pain-null-tip" v-else>患者未进行一般疼痛打分</div>
    </div>
    </template>
  </div>
</template>

<script>
import PainProgress from "@/components/pain-progress/PainProgress.vue";
export default {
  props: {
    showPainProgress:{
      type:Boolean,
      default:true

    },
    evaluationData: {
      type: Object,
      default: () => {
        return {
          details: [],
        };
      },
    },
  },
  data() {
    return {};
  },

  components: { PainProgress },

  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "../../styles/variable.scss";
@import "../../styles/mixin.scss";

.data-wrapper {
  margin-top: 10px;
  display: flex;
}
.direction-table {
  flex: 1;
  .direction-head,
  .direction-row {
    display: flex;

    .direction,
    .pain {
      flex: 1;
      text-align: center;
    }
    .angle,
    .pain-type {
      flex: 2;
      text-align: center;
    }
    .icon {
      width: 70px;
      .icon-wrapper {
        @include size(65px, 50px);
        @include bg-image(null, 65px, center);

        &.direction-icon-0 {
          background-image: url("../../assets/workbench/left-flexion.png");
        }

        &.direction-icon-1 {
          background-image: url("../../assets/workbench/right-flexion.png");
        }
        &.direction-icon-2 {
          background-image: url("../../assets/workbench/left-rotate.png");
        }
        &.direction-icon-3 {
          background-image: url("../../assets/workbench/right-rotate.png");
        }
        &.direction-icon-4 {
          background-size: 46px;
          background-image: url("../../assets/workbench/anteflexion.png");
        }
        &.direction-icon-5 {
          background-size: 42px;
          background-image: url("../../assets/workbench/retroextension.png");
        }
      }
    }
  }

  .direction-head {
    text-align: center;
    @include font-size-color(16px, $backColor2);
  }

  .direction-row {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .direction {
      @include font-size-color(20px, $backColor1);
    }
    .angle,
    .pain {
      @include font-size-color(20px, $themeColor);
    }

    .pain-type {
      @include font-size-color(14px, $themeColor);
    }
  }
}
.space-wrapper {
  width: 60px;
  height: 270px;
  position: relative;

  &:after {
    @include position(absolute, top 25px left 20px bottom 20px);
    content: " ";
    width: 1px;
    background: $pageBorderColor;
  }
}

.pain-bar-wrapper {
  margin-top: 30px;
  @include flex-row;
}
</style>
