.<template>
  <div class="components self-point-chart-component">
    <div class="chart-wrapper">
      <div class="chart-title">{{chartTitle}}</div>
      <div class="chart-legend">
        <div class="legend-item">
          <span :style="{ background: normalColor }" class="color-block"></span
          >正常
        </div>
        <div class="legend-item">
          <span
            :style="{ background: abNormalColor }"
            class="color-block"
          ></span
          >异常
        </div>
      </div>
      <e-charts ref="selfEvaluationChart"></e-charts>
    </div>
    <div class="x-axis-label-wrapper">
      <span class="label-item" :key="item" v-for="item in xAxisLabel">
        {{ item }}
      </span>
    </div>
  </div>
</template>

<script>
import ECharts from "@/components/chart/ECharts.vue";
import ChartUtils from "@/utils/ChartUtils";
export default {
  props: {
    chartTitle:{
      type:String
    }
  },
  components: {
    ECharts,
  },
  data() {
    return {
      xAxisLabel: ["上", "下", "左", "右", "左上", "右上", "左下", "右下"],
      chart: null,
      normalColor: ChartUtils.chartConstants.selfBarNormalColor,
      abNormalColor: ChartUtils.chartConstants.selfAbnormalBarColor,
    };
  },
  watch: {
 
  },
  mounted() {
    this.chart = this.$refs.selfEvaluationChart;
  },

  methods: {
    loadData(pointData) {
      const option = ChartUtils.getSelfPointChartConfig();
      let xInterval = option.xAxis.interval;
      let yMax = 5;
      let newSeriesData = [];
      for (let i = 0; i < pointData.length; i++) {
        let xRegion = 0;
        // 确定X轴的区间位置
        for (let k = 0; k < this.xAxisLabel.length; k++) {
          if (this.xAxisLabel[k] == pointData[i].xLabel) {
            xRegion = k;
            break;
          }
        }
        let pointCount = pointData[i].data.length;
        let deltaX = xInterval / (pointCount + 1);
        for (let j = 0; j < pointCount; j++) {
          if (pointData[i].data[j] > yMax) {
            yMax = Math.ceil(pointData[i].data[j]);
          }
          let newItem = this.getPointChartItem(
            6 * xRegion + deltaX * (j + 1),
            pointData[i].data[j]
          );
          newSeriesData.push(newItem);
        }
      }
      option.series = newSeriesData;
      option.yAxis.max = yMax;

      this.chart.loadChart(option);
    },
    getPointChartItem(x, y) {
      let color = "#25c68a";
      //TODO
      if (y > 4) {
        color = "#f9b41c";
      }
      let item = {
        symbolSize: 20,
        data: [[x, y]],
        type: "scatter",
        itemStyle: {
          color: color,
        },
      };
      return item;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixin.scss';
@import '@/styles/variable.scss';

.self-point-chart-component {
  .chart-wrapper {
    margin-top: 44px;
    position: relative;
    height: 460px;

    .chart-legend {
      display: flex;
      @include position(absolute, top -10px right 20px);
      @include font-size-color(18px, $backColor1);

      .legend-item {
        margin-left: 40px;
        display: flex;
        align-items: center;
      }
      .color-block {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    .chart-title {
      @include position(absolute, top -10px left 30px);
      @include font-size-color(20px, $themeColor);
    }
  }
  .x-axis-label-wrapper {
    @include flex_layout(row, center, center);
    padding-left: 60px;
    padding-right: 10px;
    .label-item {
      flex: 1;
      text-align: center;
      padding: 5px 0px;
      @include font-size-color(18px, #2d405e);
    }
  }
}
</style>
