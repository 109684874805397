import { getDictTypes, getDictList,deleteDict } from "@/api/DictService";
import DictEditDialog from './DictEditDialog.vue'
export default {
  data() {
    return {
      searchLargeTypeOptions: [],
      searchSubTypeOptions: [],
      dictList: [],
      totalDictCount: 0,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        largeTypeId: "",
        subTypeId: "",
      },
    };
  },

  components: {
    DictEditDialog
  },

  computed: {},

  mounted() {
    this.getInitData();
  },

  methods: {
    /**
     * 获取初始数据
     */
    getInitData() {
      //获取大类
      getDictTypes("")
        .then(({ data }) => {
          this.searchLargeTypeOptions = data;
        })
        .catch(() => {});

      this.getDictList();
    },
    /**
     * 获取字典列表
     */
    getDictList() {
      getDictList(this.queryParam)
        .then(({data}) => {
          this.dictList = data.rows;
          this.totalDictCount = data.total;
        })
        .catch(() => {});
    },
    handleLargeTypeChange(value) {
      this.queryParam.subTypeId = "";
      this.searchSubTypeOptions = [];

      this.handleSearchClick();

      if (!value) {
        return;
      }

      getDictTypes(this.queryParam.largeTypeId)
        .then(({ data }) => {
          this.searchSubTypeOptions = data;
        })
        .catch(() => {});
      
    },
    handlePageNoChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.getDictList();
    },
    handleSearchClick() {
      this.handlePageNoChange(1);
    },
    handleEditClick(row){
        this.$refs.dictEditDialog.open(row)
    },
    handleDeleteClick(row){
        this.$confirm('确定执行此操作?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            deleteDict(row.id).then(() => {
              if(this.dictList.length===1 && this.queryParam.pageNum>1){
                this.queryParam.pageNum -= 1
              }
              this.getDictList()
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
            });
          }).catch(() => {
  
          });
    }
  },
};
