<!-- 暂停对话框 -->
<template>
  <div class="component pause-component">
    <div class="tip-dialog">
      <div>已暂停</div>
      <div class="pause-dialog-exception" v-if="continueException">
      {{ exceptionReason }}
    </div>
  </div>
    
  </div>
</template>

<script>
export default {
  props: {
    continueException: {
      type: Boolean,
      default: false,
    },
    exceptionReason: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

.pause-component {
  @include position(fixed, top 0px right 0px bottom 0px left 0px);
  z-index: 100;
  background-color: rgba($color: #000000, $alpha: 0.3);
  @include flex_layout(row, center, center);

  .tip-dialog {
    border: 1px solid rgb(205, 221, 233);
    background-color: rgb(255, 255, 255);
    width: 350px;
    height: 258px;
    @include font-size-color(30px, $themeColor);
    @include flex_layout(column, center, center);
  }
  .pause-dialog-exception{
    margin-top: 20px;
    @include font-size-color(16px,$backColor1);
    text-align: center;
  }
}
</style>
