import UserEditDialog from "./UserEditDialog.vue";
import { getUserList, deleteUser, restPassword } from "@/api/SysService";
export default {
  data() {
    return {
      userList: [],
      totalUserCount: 0,
      queryParam: {
        keyword: "",
      },
    };
  },

  components: {
    UserEditDialog,
  },

  computed: {},

  mounted() {
    this.getUserList();
  },

  methods: {
    /**
     * 获取初始数据
     */
    getUserList() {
      getUserList(this.queryParam)
        .then(({ data }) => {
          this.userList = data;
        })
        .catch(() => {});
    },

    handleEditClick(row) {
      const userId = row === null ? null : row.id
      this.$refs.userEditDialog.open(userId);
    },
    handleDeleteClick(row) {
      this.$confirm("确定执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteUser(row.id).then(() => {
            this.getUserList();
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          });
        })
        .catch(() => {});
    },
    handleRestPasswordClick(row) {
      this.$confirm("确定执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          restPassword(row.id).then(() => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          });
        })
        .catch(() => {});
    },
  },
};
