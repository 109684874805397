import request from "@/utils/Request";

/**
 * 获取评估趋势数据
 * @returns
 */
export function getEvaluationTrendData(patientCaseId) {
  return request({
    url: `/api/hospital-data/patientcase/eva/trend/${patientCaseId}`,
    method: "get",
  });
}

/**
 * 获取训练趋势数据
 * @returns
 */
export function getTrainTrendData(patientCaseId) {
  return request({
    url: `/api/hospital-data/patientcase/train/trend/${patientCaseId}`,
    method: "get",
  });
}

/**
 * 根据给定的趋势数据获取x轴标签
 * @param {*} trendData
 * @returns
 */
export function getXAxisLabel(trendData) {
  const xLabels = [];
  for (let index = 0; index < trendData.createDate.length; index++) {
    xLabels.push(
      `第${index + 1}次\n${trendData.createDate[index]}`
    );
  }
  return xLabels;
}

/**
 * 根据给定的趋势数据获取图表数据
 * @param {*} trendData 
 */
export function getChartData(trendData,type){
  const romData = trendData.trends.filter(item=>item.type===type)[0]
  return romData;
}