import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    backHomeFlag:false,
    showBackBtn:true,
  },
  mutations: {
    setBackHomeFlag(state,flag){
      state.backHomeFlag = flag
    },
    setShowBackBtnFlag(state,flag){
      state.showBackBtn = flag
    }
  },
  actions: {
  },
  modules: {
  }
})
