<template>
  <div id="app">
    <router-view />
    <div class="page-button-wrapper">
      <div class="refresh-icon" @click="handleRefreshClick"></div>
      <div
        class="back-home-icon"
        @click="handleBackHomeClick"
        v-show="showBackHomeIcon"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBackHomeIcon: false,
    };
  },
  created() {
    window.$APP = this;
  },
  mounted() {},
  methods: {
    handleRefreshClick() {
      window.location.reload();
    },
    handleBackHomeClick() {
      this.navigateTo("/");
    },
    showBackHomeButton() {
      this.showBackHomeIcon = true;
    },
    hideBackHomeButton() {
      this.showBackHomeIcon = false;
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

#app {
  font-family: Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  background-image: url("./assets/common/bg.png");
  background-repeat: no-repeat;
  background-position: center;
}

.page-button-wrapper {
  // @include position(fixed,right 20px bottom 60px);
  @include flex_layout(column, center, center);
  background-color: rgba($color: #fff, $alpha: 1);

  @include position(fixed, right 0px top 70%);
  border: 1px solid #0093d9;
  border-right: none;
  border-radius: 4px;

  .back-home-icon,
  .refresh-icon {
    // width: 103px;
    // height: 106px;
    // @include bg-image(null,103px,center)

    width: 62px;
    height: 62px;
    @include bg-image(null, 34px, center center);
  }

  .back-home-icon {
    // margin-top: 10px;
    // background-image: url('./assets/common/back-home.png');
    background-image: url("./assets/common/back-home2.png");
    position: relative;
    &::before {
      content: " ";
      background-color: #d3d3d3;
      width: 40px;
      height: 1px;

      @include position(absolute, top 0px left 10px);
    }
  }
  .refresh-icon {
    // background-image:url('./assets/common/refresh.png');
    background-image: url("./assets/common/refresh2.png");
  }
}
</style>
