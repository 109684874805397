<template>
  <div class="page page-template">
    <div class="page-header-wrapper">
      <status-bar
        mode="navigateBar"
        :show-back="showBack"
        ref="pageStatusBar"
        :pageTitle="pageTitle"
      ></status-bar>
    </div>
    <div class="page-content-wrapper">
      <div class="page-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import StatusBar from "@/components/status-bar/StatusBar.vue";
export default {
  data() {
    return {
      pageTitle: "",
      showBack: true,
      backToHome:false,
    };
  },

  components: {
    StatusBar,
  },

  computed: {},
  created() {
    window.pageTemplate = this;
  },
  mounted() {},
  beforeRouteUpdate(to, from, next) {
    this.showBack = true;
    next();
  },
  methods: {
    
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";

.page-template {
  background-image: url("../../assets/common/top_banner.png");
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 236px;

  .page-content-wrapper {
    margin-top: 10px;
    width: 100%;
    height: calc(100vh - 90px);
    box-sizing: border-box;

    background-image: url("../../assets/common/ground_glass.png");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-position-x: 30px;

    padding: 0px 30px;

    .page-content {
      height: 100%;
      background-image: -webkit-linear-gradient(
        90deg,
        rgb(242, 252, 255) 0%,
        rgb(245, 252, 255) 39%,
        rgb(247, 251, 255) 100%
      );
      border: 1px solid #9fc9db;
      opacity: 0.9;
      overflow-y: auto;
      @include scroll-bar;
    }
  }
}
</style>
