.<template>
  <div class="trend-chart-component">
    <div class="no-data-wrapper" v-show="showNoDataTip">
      <img src="../../assets/common/no-chart-data.png"/>
      <div class="no-data-tip">暂无数据</div>
    </div>
    <div class="line-title blue">{{chartTitle}}</div>
    <div class="chart-wrapper">
      <slot name="contron-panel"></slot>
      <e-charts ref="chartEl"></e-charts>
    </div>
  </div>
</template>

<script>
import ECharts from "@/components/chart/ECharts.vue";
export default {
  props: {
    chartTitle: {
      type: String,
      required: true,
    },
  },
  components: {
    ECharts,
  },
  data() {
    return {
      chart: null,
      showNoDataTip:false,
    };
  },
  mounted() {
    this.chart = this.$refs.chartEl;
  },
  methods: {
    loadChart(option) {
      console.log(option)
      if( option.xAxis.data && option.xAxis.data.length === 0){
        this.showNoDataTip = true;
      }
      this.chart.loadChart(option);
    },
  },
};
</script>

<style lang="scss">
.trend-chart-component {
   position: relative;
    margin-bottom: 50px;
  .chart-wrapper {
    margin-top: 20px;
    position: relative;
    width: 100%;
    height: 460px;
  }
  .no-data-wrapper{
    position: absolute;
    z-index: 800;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .no-data-tip{
      padding-top: 10px;
      padding-left: 50px;
      font-size: 18px;
    }
  }
}
</style>
