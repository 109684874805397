<!-- VR状态 -->
<template>
  <div class="vr-status-component">
    <div class="device-status-title">设备状态:</div>
    <template v-if="deviceData.hasDevice">
      <span class="electricity">VR电量:<span class="normal" v-if="deviceData.vrElectric">
        <font color="red" v-if="deviceData.vrElectric <= 20">{{ deviceData.vrElectric }}%</font>
        <font v-else>{{ deviceData.vrElectric }}%</font>
      </span>
      <span class="abnormal" v-else>设备断线</span></span>
      <span class="network"
        >网络:<span class="normal" v-if="isConnect">连接中</span
        ><span class="abnormal" v-else>断线</span></span
      >
    </template>
    <template v-else>
      暂无可用设备
    </template>
  </div>
</template>

<script>
export default {
  props: {
    deviceData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },

  components: {},

  computed: {
    isConnect() {
      return (
        this.deviceData.hostConnect === 1 && this.deviceData.vrConnect === 1
      );
    },
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

.vr-status-component {
  @include flex_layout(row);
  @include font-size-color(24px, $backColor1);

  .device-status-title {
    @include incon-title(
      "../../assets/common/device-status.png",
      67px,
      0 0 0 87px,
      64px,
      24px,
      $backColor1
    );
  }

  .electricity {
    margin-left: 20px;
  }
  .network {
    margin-left: 70px;
  }
  .normal {
    color: #09b511;
  }
  .abnormal {
    color: red;
  }
}
</style>
