<!-- 肩颈肌肉训练结果 -->
<template>
  <div class="component neck-train-result-component">
    <div class="neck-train-result-wrapper">
      <div class="result-panel-wrapper">
        <div class="result-panel completion-rate">
          <div class="icon-title completion-rate">平均完成率</div>
          <div class="completion-rate-process-wrapper">
            <el-progress
              type="dashboard"
              color="#25aef3"
              :width="218"
              :stroke-width="14"
              :percentage="trainData.detail.rateData?trainData.detail.rateData.rate:0"
            ></el-progress>
          </div>
        </div>

        <div class="result-panel">
          <div class="icon-title angular-velocity">角速度(每秒)</div>

          <div class="data-list-wrapper">
            <div class="data-item">
              <div class="data-value">
                {{ trainData.detail.rateData.avgAngularSpeed | formatAngle }}
              </div>
              <div class="data-desc">平均</div>
            </div>
            <div class="data-item">
              <div class="data-value">
                {{ trainData.detail.rateData.maxAngularSpeed | formatAngle }}
              </div>
              <div class="data-desc">最大</div>
            </div>
          </div>
        </div>

        <div class="result-panel max-angle-wrapper">
          <div class="icon-title max-angle">训练最大角度</div>

          <div class="data-list-wrapper">
            <div class="data-item">
              <div class="data-value">
                {{ trainData.maxNeckSport.leftFlexionRotate | formatAngle }}
              </div>
              <div class="data-desc">左屈</div>
            </div>
            <div class="data-item">
              <div class="data-value">
                {{ trainData.maxNeckSport.rightFlexionRotate | formatAngle }}
              </div>
              <div class="data-desc">右屈</div>
            </div>
            <div class="data-item">
              <div class="data-value">
                {{ trainData.maxNeckSport.leftRotate | formatAngle }}
              </div>
              <div class="data-desc">左旋</div>
            </div>
            <div class="data-item">
              <div class="data-value">
                {{ trainData.maxNeckSport.rightRotate | formatAngle }}
              </div>
              <div class="data-desc">右旋</div>
            </div>
            <div class="data-item">
              <div class="data-value">
                {{ trainData.maxNeckSport.protractRotate | formatAngle }}
              </div>
              <div class="data-desc">前屈</div>
            </div>
            <div class="data-item">
              <div class="data-value">
                {{ trainData.maxNeckSport.hypsokinesisRotate | formatAngle }}
              </div>
              <div class="data-desc">后伸</div>
            </div>
          </div>
        </div>
      </div>
      <!--数据面板结束-->
      <div class="pose-error-table-wrapper">
        <pose-error-table
          :errors="trainData.detail.errorLogs"
        ></pose-error-table>
      </div>
    </div>
  </div>
</template>

<script>
import PoseErrorTable from "@/components/pose-error-table/PoseErrorTable.vue";
export default {
  props: {
    trainData: {
      type: Object,
    },
  },
  data() {
    return {};
  },

  components: {
    PoseErrorTable,
  },

  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped></style>
