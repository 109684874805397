
<template>
  <div class="gender-component">
    <el-button-group>
    <el-button class="male" :class="{selected:gender==0}" @click="handleGenderClick(0)"><i class="gender-icon male"></i>男性</el-button>
    <el-button class="female" :class="{selected:gender==1}"  @click="handleGenderClick(1)"><i class="gender-icon female"></i>女性</el-button>
    </el-button-group>
  </div>
</template>

<script>
export default {
  data () {
    return {
      gender:null
    };
  },

  components: {},

  computed: {},

  mounted(){
    
  },

  methods: {
    /**
     * 处理性别点击事件
     */
    handleGenderClick(gender){
      this.gender = gender
      this.$emit("change",gender)
    },
    /**
     * 选中指定性别
     */
    selectGender(gender){
      this.gender = gender
    }
  }
}

</script>
<style lang='scss' scoped>
.gender-component{  
  .gender-icon{
    display: inline-block;
    width: 14px;
    height: 14px;
    background-position: center;
   
    margin-right: 5px;

    &.male{
      background-image: url('../../assets/common/male.png');
    }

   

    &.female{
     background-image: url('../../assets/common/female.png');
    }
  }

/deep/ .el-button-group{
  .el-button{
    font-size: 20px;
    color: #181e29;
    min-width: 116px;
  }



  .male{
   &.selected{
      color:#13a2f3;;
      margin-right: 0px;
      border-color: #13a2f3;;
      border-radius: 0px;
    }
  }
  .female{
    &.selected{
      color:#13a2f3;;
      border-color: #13a2f3;;
      border-radius: 0px;
    }
  }
 
}

}
</style>