<!-- 患者卡片 -->
<template>
  <div class="component patient-card-component">
      <div class="title-row">
          <div class="name text-overflow" style="max-width:280px;" :class="genderClass">{{patientData.patientName}}</div>
          <div class="fs-20 color_black1 flex-shrink-0"><span class="color_ac">年龄：</span>{{patientData.age}}</div>
      </div>
      <div class="info-row diagnosis"><span class="color_ac">诊断：</span>{{patientData.diagnosis}}</div>
      <div class="info-row"><span class="color_ac">负责医生：</span>{{patientData.liableUserName}}<span class="mg-l-30 color_ac">接诊医生：</span>{{patientData.doctorName}}</div>
      <div class="info-row"><span class="color_ac">接诊时间：</span>{{patientData.receptionTime}}</div>
      <div class="info-row"><span class="color_ac">总接诊次数：</span>{{patientData.totalTreatmentCount}}</div>
  </div>
</template>

<script>
export default {
    props:{
        patientData:{
            type:Object
        }
    },
  data () {
    return {
    };
  },

  components: {},

  computed: {
      genderClass(){
          return {
              male:this.patientData.gender === 0,
              female:this.patientData.gender === 1
          }
      }
  },

  mounted(){},

  methods: {}
}

</script>
<style lang='scss' scoped>
@import '@/styles/mixin.scss';
@import '@/styles/variable.scss';

.patient-card-component{
    box-sizing: border-box;
    width: 440px;
    // height: 254px;
    border: 1px solid $pageBorderColor;
    padding: 20px;
    
    .title-row{
        @include flex_layout(row,space-between,center);
        .name{
            @include bg-image(null,29px 29px,right center);
            @include font-size-color(28px,$backColor1);
            padding-right: 38px;
            height: 40px;
            

            &.male{
                background-image: url('../../assets/common/gender/male.png');
            }


            &.female{
                background-image: url('../../assets/common/gender/female.png');
            }
        }
       
    }
    .info-row{
        @include font-size-color(20px,$backColor1);
        
        overflow:hidden; 
        text-overflow:ellipsis;
        display:-webkit-box; 
        -webkit-box-orient:vertical;
        -webkit-line-clamp:1; 

        margin-bottom: 26px;
         &.diagnosis{
            margin-top: 38px;
        }
        &:last-child{
            margin-bottom: 0px;
        }
    }
}
</style>