<!-- 错误姿势 -->
<template>
  <div class="pose-error-table-component">
    <div class="title-bar-wrapper" v-if="showHeader">
      <div class="icon-title pose-error">姿势错误</div>
      <div class="error-times">
        {{ errors.length }}<span class="unit">次</span>
      </div>
    </div>
    <div class="pose-error-table">
      <div class="header-wrapper">
        <div class="header">
          <span class="direction">方向</span>
          <span class="desc">错误位置</span>
          <span class="duration">持续时间</span>
        </div>
      </div>
      <div class="body">
        <div class="row" :key="error.id" v-for="error in pageData">
          <span class="direction">{{ error.sportDirection }}</span>
          <span class="desc">{{ error.describe }}</span>
          <span class="duration">{{ error.duration }}″</span>
        </div>
      </div>
      <div class="pager">
        <div
          class="page-btn previous"
          @click="handlePrePageClick"
          :class="{ disabled: pageNo === 1 }"
        ></div>
        <div
          class="page-btn next"
          @click="handleNextPageClick"
          :class="{ disabled: pageNo === totalPage }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showHeader:{
      type:Boolean,
      default:true
    },
    errors: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 4,
      totalPage: 1,
      pageData: [],
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.initPage();
  },
  watch: {
    errors: function() {
      this.initPage();
    },
  },
  methods: {
    initPage() {
      this.pageNo = 1;
      this.totalPage = Math.ceil(this.errors.length / this.pageSize) || 1;
      this.page();
    },
    handleNextPageClick() {
      if (this.pageNo === this.totalPage) {
        return;
      }
      this.pageNo++;
      this.page();
    },
    handlePrePageClick() {
      if (this.pageNo === 1) {
        return;
      }
      this.pageNo--;
      this.page();
    },

    page() {
      const startIndex = (this.pageNo - 1) * this.pageSize;
      this.pageData =
        startIndex + this.pageSize >= this.errors.length
          ? this.errors.slice(startIndex, this.errors.length)
          : this.errors.slice(startIndex, startIndex + this.pageSize);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";
@mixin content-padding {
  padding: 10px 20px 0px 20px;
}
.pose-error-table-component {
  border: 1px solid $pageBorderColor;
  .title-bar-wrapper {
    @include content-padding;
    padding-top: 0px;
    height: 60px;
    background-color: #f3f5f5;
    @include flex_layout(row, space-between, center);
  }
  .error-times {
    @include font-size-color(30px, $themeColor);
    .unit {
      font-size: 20px;
    }
  }
  .pose-error-table {
    .header-wrapper {
      box-sizing: border-box;
      background-color: #fff;
      //    padding-left: 20px;
    }
    .header {
      @include flex_layout(row, null, center);
      @include font-size-color(16px, $backColor1);
      height: 34px;
      margin: 0px 20px;
    }
    .row {
      @include flex_layout(row, null, center);
      margin: 0px 20px;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid $pageBorderColor;
    }
    .direction {
      padding-left: 40px;
      box-sizing: border-box;
      width: 20%;
    }
    .error-direction {
      box-sizing: border-box;
      width: 29%;
    }
    .desc {
      box-sizing: border-box;
      // width: 32%;
      flex: 1;
    }
    .duration {
      box-sizing: border-box;
      width: 19%;
    }
  }

  .pager {
    @include flex_layout(row, null, center);
  }
  .page-btn {
    width: 60px;
    height: 60px;
    cursor: pointer;
    @include bg-image(null, 25px, center);
    &.previous {
      background-image: url("../../assets/pager/pre.png");
      &.disabled {
        background-image: url("../../assets/pager/pre_disabled.png");
      }
    }
    &.next {
      background-image: url("../../assets/pager/next.png");
      &.disabled {
        background-image: url("../../assets/pager/next_disabled.png");
      }
    }
  }
}
</style>
