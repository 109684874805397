<!-- 活动度评估 -->
<template>
  <div class="rom-evaluation-component">
    <div class="rom-evaluation-detail-wrapper">
      <div class="icon-title angle">运动角度/激惹疼痛打分</div>
      <div class="evaluation-direction-wrapper">
        <div
          class="evaluation-direction-item"
          :key="'item:' + item.type"
          v-for="item,index in romList"
        >
          <div
            class="direction-icon "
            v-if="index==currentEvaluationIndex && showDirectionGif"
            :class="[`d-${item.type}-ing`]"
          ></div>
          <div class="direction-icon " v-else :class="[`d-${item.type}`]"></div>
          <div class="direction-info">
            {{ item.name }}
            <span class="evaluation-value" v-if="item.evaluationAngle"
              >{{ item.evaluationAngle }}°/{{ item.painScore }}</span
            >
            <span class="evaluation-value" v-else>--</span>
          </div>
          <div class="pain-type-wrapper">
            <el-select
              :disabled="item.evaluationResultId==null"
              v-model="item.painType"
              @change="handlePainTypeChange(item)"
              placeholder="请选择"
              clearable
            >
              <el-option label="无" value=""></el-option>
              <el-option
                :label="item.name"
                :value="item.name"
                :key="item.id"
                v-for="item in painList"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <div class="pain-score-wrapper" v-if="showNormalPainProcess">
        <div class="icon-title commonly-pain">一般疼痛打分</div>
        <pain-progress :value="parseInt(evaluationData.pain)" class="mg-l-50" v-if="evaluationData.pain!==null"></pain-progress>
        <div class="normal-pain-tip" v-else>患者未进行一般疼痛打分</div>
      </div>
    </div>

    <!--姿势错误-->
    <div class="pose-error-table-warpper" v-if="showNormalPainProcess">
      <pose-error-table :errors="evaluationData.errorLogs"></pose-error-table>
    </div>
  </div>
</template>

<script>
import PainProgress from "@/components/pain-progress/PainProgress.vue";
import PoseErrorTable from "@/components/pose-error-table/PoseErrorTable.vue";
import { updatePainType } from "@/api/EvaluationService";
import { SportDirectionEnum,EvaluationTypeEnum} from "@/utils/Enums";
export default {
  props: {
    showDirectionGif:{
      type:Boolean,
      default:false
    },
    painList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    evaluationData: {
      type: Object,
      default: () => {
        return {
          details: [],
        };
      },
    },
  },
  data() {
    const romList = Object.values(SportDirectionEnum).map((item) => {
      return {
        type: item.type,
        name: item.desc,
        painType: null,
        evaluationResultId: null,
        evaluationAngle: null,
        painScore: null,
      };
    });

    return {
      romList: romList,
      currentEvaluationIndex: 0,
    };
  },

  components: {
    PainProgress,
    PoseErrorTable,
  },

  computed: {
    showNormalPainProcess(){
      return this.evaluationData.type === EvaluationTypeEnum.ACTIVE.type
    },
    
  },

  mounted() {
    this.initData();
  },
  watch: {
    evaluationData: function(newVal) {
      console.log("newVal:", newVal);
      this.initData();
    },
  },
  methods: {
    initData() {
      for (let index = 0; index < this.evaluationData.details.length; index++) {
        
        const evaluationResultItem = this.evaluationData.details[index];
        const romItem = this.romList.filter(item=>item.type == evaluationResultItem.direction)[0]

        romItem.evaluationResultId = evaluationResultItem.id;
        romItem.evaluationAngle = evaluationResultItem.angle;
        romItem.painScore = evaluationResultItem.pain;
        romItem.painType = evaluationResultItem.painType;
        // romItem.isEvaluationing = false;
      }

      this.romList.map(item=>item.isEvaluationing = false)
      this.currentEvaluationIndex = this.romList.findIndex(item=>item.evaluationResultId === null);
      
    },
    handlePainTypeChange(item) {
      const param = {
        evaluationDetailDataId: item.evaluationResultId,
        painType: item.painType,
      };
      updatePainType(param)
        .then(() => {
          this.$message.success("修改成功!");
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

.rom-evaluation-component {
  .rom-evaluation-detail-wrapper {
    margin-top: 10px;
    border: 1px solid $pageBorderColor;
    background-color: #f3f5f5;
    padding: 18px 20px;
  }
  .evaluation-direction-wrapper {
    margin-top: 15px;
    @include flex_layout(row, space-around, center);
  }
  .evaluation-direction-item {
    @include flex_layout(column, center, null);
    @include font-size-color(20px, $backColor1);
  }
  .evaluation-value {
    margin-left: 30px;
    @include font-size-color(28px, $themeColor);
  }
  $directions: 0, 1, 2, 3, 4, 5;
  .direction-icon {
    width: 150px;
    height: 120px;
    @include bg-image(null, 150px 120px);

    @each $index in $directions {
      &.d-#{$index} {
        background-image: url("../../assets/rom-direction/#{$index}.png");
      }

      &.d-#{$index}-ing {
        background-image: url("../../assets/rom-direction/animation-#{$index}.gif");
      }
    }
  }
  .direction-info {
    margin-top: 10px;
  }
  .pain-type-wrapper {
    margin-top: 15px;
    width: 110px;
  }
  .pain-score-wrapper {
    margin-top: 24px;
    width: 480px;
  }

  .pose-error-table-warpper {
    margin-top: 10px;
  }
  .normal-pain-tip{
    @include font-size-color(20px,$backColor2);
    padding: 20px;
    padding-left: 44px;
  }
}
</style>
