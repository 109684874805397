<!-- 字典编辑对话框 -->
<template>
  <div class="component device-edit-dialog">
    <el-dialog
      :modal-append-to-body="false"
      :title="title"
      :visible.sync="showDialog"
      width="930px"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <div class="flex-row">
          <el-form-item
            label="VR编码"
            prop="code"
            
           
          >
            <el-input v-model="form.code" maxlength="30" autocomplete="off" v-focus="{type:'text',position:'leftBottom',xOffset:0,yOffset:0}"></el-input>
          </el-form-item>
          <div style="width:20px;"></div>

          <el-form-item label="VR MAC" prop="mac" >
            <el-input v-model="form.mac" autocomplete="off" v-focus="{type:'text',position:'leftBottom',xOffset:0,yOffset:0}"></el-input>
          </el-form-item>
        </div>
      </el-form>

      <div slot="footer">
        <div class="flex-row">
          <div class="form-dialog-footer-btn" @click="showDialog = false">
            取 消
          </div>
          <div
            class="form-dialog-footer-btn confirm"
            @click="handleConfirmClick"
          >
            确 定
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { checkMac } from "@/utils/Validator";
import { saveDevice } from "@/api/DeviceService";

export default {
  data() {
    return {
      showDialog: false,
      title: "",
      largeTypeOptions: [],
      subTypeOptions: [],
      canSubmit: true,
      form: {
        id: "",
        code: "",
        mac: "",
      },
      rules: {
        code: [
          {
            required: true,
            message: "设备编码不能为空!",
            trigger: "blur",
          },
        ],
        mac: [
          {
            required: true,
            requiredMsg: "MAC地址不能为空！",
            trigger: "blur",
            validator: checkMac,
          },
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    handleConfirmClick() {
      if (!this.canSubmit) {
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return false;
        }

        this.canSubmit = false;
        this.form.type = 1;
        saveDevice(this.form)
          .then(() => {
            this.$message({
							type: 'success',
							message: '操作成功!'
						});
            this.$emit("editSuccess");
            this.close();
          })
          .catch(() => {
            this.canSubmit = true;
          });
        return true;
      });
    },
    close() {
     
      this.showDialog = false;
    },
    open(formData) {
      this.canSubmit = true;
      if (!formData) {
        this.title = "新增";
        this.form.id=null;
        this.form.mac = "";
        this.form.code = "";
      } else {
        this.title = "编辑";
        this.form = JSON.parse(JSON.stringify(formData));
      }
      this.showDialog = true;
      this.$nextTick(()=>{
        this.$refs['form'].clearValidate()

      })
    },
  },
};
</script>
<style lang="scss">
.device-edit-dialog {
  .el-dialog__body {
    padding: 20px 20px 0px 20px;
  }
}
</style>
