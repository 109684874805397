import VrProgress from "@/components/vr-progress/VRProgress.vue";
import PoseErrorTable from "@/components/pose-error-table/PoseErrorTable.vue";
import NeckMuscleTrainResult from "./NeckMuscleTrainResult.vue";
import NeckTrainResult from "./NeckTrainResult.vue";
import SelfTrainResult from "./SelfTrainResult.vue";

import PauseDialog from "@/components/pause-dialog/PauseDialog.vue";
import ReasonDialog from "@/components/reason-dialog/ReasonDialog.vue";

import {
  getTrainDetail,
  pauseEvaluation,
  continueTrain,
  stopTrain,
  getTrainTime
} from "@/api/TrainService";

import { WorkbenchMixins, MainServiceCommunication,BackHomeControlMixins } from "@/utils/Mixins";
import WebSocketService from "@/api/WebSocketService";
import { WebSocketCommand } from "@/api/WebSocketService";
import { SportProjectTypeEnum } from "@/utils/Enums";

export default {
  mixins: [WorkbenchMixins, MainServiceCommunication,BackHomeControlMixins],
  data() {
    return {
      pageParam: {
        patientCaseId: null,
        treatmentId: null,
        trainId: null,
        deviceId: null,
      },
      isPause: false,
      isRest: false,
      isFinished: false,
      continueException: false,
      exceptionReason: null,
      exceptionTimeout: null,
      intervals: [],
      sportTime: 0,
      nextSprotName: null,
      isFinishedAll: false,
      firstSport:false,
      deviceData: {},
      currentSportProject: {},
      trainDetail: {
        frontCustomData:{}
      },
    };
  },

  components: {
    VrProgress,
    PoseErrorTable,
    NeckMuscleTrainResult,
    NeckTrainResult,
    SelfTrainResult,
    PauseDialog,
    ReasonDialog,
  },

  computed: {
    /**
     * 训练项目枚举
     * @returns
     */
    projectTypeEnum() {
      return SportProjectTypeEnum;
    },
    maxTime(){
      return this.trainDetail.frontCustomData.currentSportTotalTime
    }
  },
  mounted() {

   const trainId = window.sessionStorage.getItem(this.pageParam.trainId)
   if(!trainId){
    this.firstSport = true;
    window.sessionStorage.setItem(this.pageParam.trainId,"1");
   }

    this.setPageTemplateTitle("训练中");
    this.hidePageTemplateBackBtn();
    this.getTrainDetail();
    this.bindWebSocketHandler();
  },

  methods: {
    getTrainDetail() {
      getTrainDetail(this.pageParam.trainId).then((data) => {
        //判断暂停状态
        console.log("trainDetailData",data)
        if (data.frontCustomData.isPause) {
          this.isPause = true;
          this.continueException = false;
          this.exceptionReason = null;
        }
        //判断完成状态
        if (data.frontCustomData.isFinishedAll) {
          this.handleFinishAllSportEvent();
        } else {
          this.isFinishedAll = false;
        }
        //时间校准
        this.sportTime = data.frontCustomData.currentSportResult.duration;
        this.currentSportProject = data.frontCustomData.currentSportProject;
        this.trainDetail = data;

        console.log(data.frontCustomData)
        this.nextSprotName = data.frontCustomData.nextSportName

        console.log("获取数据：",this.sportTime,data.frontCustomData)
        if(data.rateData == null){
          this.isRest = false;
        }

        this.asyncTime(this.sportTime);
        getTrainTime(this.pageParam.trainId).then(()=>{}).catch(()=>{})

      });
    },
    bindWebSocketHandler() {
      //开始训练
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.START_SPORT,
        this,
        () => {
          this.isRest = false;
          this.isPause = false;
          this.continueException = false;
          this.getTrainDetail();
        }
      );

      //获取全量评估数据
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.UPDATE_SPORT,
        this,
        this.getTrainDetail
      );

      //更新设备电量信息
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.UPDATE_DEVICE_INFO,
        this,
        (event) => {
          console.log("this:",this)
          this.deviceData = event.data;
        }
      );

      /**
       * 暂停训练
       */
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.PAUSE_SPORT,
        this,
        () => {
          this.isPause = true;
          this.continueException = false;
        }
      );

      //继续训练指令
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.CONTINUE_SPORT,
        this,
        (event) => {
          this.remarkFinishTeachStatus()
          this.loading = false;
          this.isPause = false;
          //非闭眼将时间重置为前置机发来的时间
          if (
            this.currentSportProject.type != SportProjectTypeEnum.SELFFEEL.type
          ) {
            this.sportTime = event.data;
            this.asyncTime(parseInt(event.data / 1000));
          }
          this.clearVrRequestTimeoutTask();
        }
      );

      //继续训练异常
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.CONTINUE_SPORT_EXCEPTION,
        this,
        (event) => {
          this.remarkFinishTeachStatus()
          this.isPause = true;
          this.continueException = true;
          this.exceptionReason = event.data;
          this.clearVrRequestTimeoutTask();
        }
      );

      //终止训练
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.STOP_SPORT,
        this,
        this.handleFinishAllSportEvent
      );

      //教学结束通知
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.MSG_TRANSFER_FINISH_TRAIN,
        this,
        () => {

         this.remarkFinishTeachStatus()

          //开始计时
          this.sportTime = 0;
          this.clearTimer();
          const interval = setInterval(() => {
            if (!this.isPause) {
              this.sportTime++;
            }
            if (this.sportTime >= this.maxTime) {
              this.sportTime = this.maxTime;
              clearInterval(interval);
            }
          }, 1000);
          this.intervals.push(interval);
        }
      );

      //完成结束训练阶段
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.FINISH_SPORT,
        this,
        () => {
          this.clearFinishTeachStatus()
          this.isRest = true;
          this.sportTime = this.maxTime;
        }
      );

      //同步时间
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.ASYNC_SPORTED_TIME,
        this,
        (event) => {
          console.log("syancdata:",event.data)
          this.isPause = false
          this.asyncTime(parseInt(event.data / 1000));
        }
      );

      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.MSG_TRANSFER_FINISH_SF,
        this,
        (event) => {
          if (event.data == 1) {
            // 1完成睁闭眼
            // that.currentSport.name = that.currentSport.name + ' (3/3)'
            //that.SFProgress = 3
          } else if (event.data == 2) {
            // 2完成闭眼
            this.isRest = true;
          }
        }
      );

      //完成所有训练
      WebSocketService.addMessageListener(
        WebSocketCommand.evaluationCmd.FINISH_ALL_SPORTS,
        this,
        ()=>{
          this.clearFinishTeachStatus()
          this.getTrainDetail()
        }
       
      );
    },

    /**
     * 标记完成教学
     */
    remarkFinishTeachStatus(){
      window.sessionStorage.setItem(this.pageParam.trainId,"true")
    },
    checkFinishTeachStatus(){
      return window.sessionStorage.getItem(this.pageParam.trainId) === "true"
    },
    clearFinishTeachStatus(){
      window.sessionStorage.removeItem(this.pageParam.trainId)
    },
    /**
     * 暂停
     */
    handlePauseClick() {
      this.isPause = true;
      this.continueException = false;
      pauseEvaluation(this.pageParam.trainId)
        .then(() => {})
        .catch(() => {});
    },
    /**
     * 继续训练
     */
    handleContinueClick() {
      continueTrain(this.pageParam.trainId)
        .then(() => {
          this.openVrRequestTimeoutTask(() => {
            this.isPause = true;
            this.continueException = true;
            this.exceptionReason = "连接VR超时...";
          });
        })
        .catch(() => {});
    },
    handleStopClick() {
      this.$refs.stopTrainDialog.show();
    },
    /**
     * 停止原因选择事件
     * @param {*} stopReasonId
     */
    handleStopReasonSelect(stopReasonId) {
      const param = {
        abandonId: stopReasonId,
      };
      stopTrain(this.pageParam.trainId, param)
        .then(() => {
          this.clearFinishTeachStatus()
          this.$refs.stopTrainDialog.close();
          this.openVrRequestTimeoutTask(() => {
            this.handleFinishAllSportEvent();
          });
        })
        .catch(() => {});
    },
    /**
     * 完成所有训练事件
     */
    handleFinishAllSportEvent() {
      this.clearVrRequestTimeoutTask()
      this.clearFinishTeachStatus()
      this.isFinished = true;
      this.isPause = false;
      this.setPageTemplateTitle("训练完成");
      this.isFinishedAll = true;
    },
    asyncTime(sportTime) {
      if (this.isRest) {
        this.sportTime = this.maxTime;
      } else {
        if (this.checkFinishTeachStatus()) {
          this.sportTime = sportTime;
          this.clearTimer();
          const interval = setInterval(() => {
            if (!this.isPause) {
              this.sportTime++;
            }
            if (this.sportTime >= this.maxTime) {
              this.sportTime = this.maxTime;
              clearInterval(interval);
            }
          }, 1000);
          this.intervals.push(interval);
        }
      }
    },
    clearTimer() {
      this.clearVrRequestTimeoutTask();
      // 每次离开当前界面时，清除定时器
      for (let i = 0; i < this.intervals.length; i++) {
        clearInterval(this.intervals[i]);
      }
      this.intervals = [];
    },
    handleExitTrainClick() {
      this.openWorkbenchPage(this.pageParam.patientCaseId);
    },
    handleOpenTrainSchemePageClick() {
      this.back();
    },
  },
  beforeDestroy(){
    this.clearTimer()
  }
};
