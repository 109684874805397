import request from "@/utils/Request"

/**
 * 登录接口
 * @param {*} params
 * @returns
 */
export function login(params) {
  return request({
    url: "/api/login",
    method: "post",
    params
  })
}

/**
 * 退出登录
 * @returns 
 */
export function exit(){
  return request({
    url: "/api/logout",
    method: "post"
  })
  
}