import { MessageBox,Loading } from "element-ui";
/**
 * 调用本地接口
 * @param {*} action
 * @param {*} param
 * @returns
 */
export function callLocalApi(action, param) {
  
  const reqParam = {
    action: action,
    param: param,
    sessionId: localStorage.getItem("sessionId"),
  };

 
  return new Promise(function(resolve, reject) {
    const loading = Loading.service({ 
      lock: true,
      text: '请求中，请稍候',
      spinner: 'el-icon-loading',  // 自定义图标
      background: 'rgba(0, 0, 0, 0.1)'
  })
    window.XRHealerClient.localProxy(JSON.stringify(reqParam)).then((res) => {
      loading.close();
      const data = JSON.parse(res);
      if (data.code === 1000) {
        resolve(data);
      } else {
        MessageBox.confirm(data.message,"出错啦")
        reject(data);
      }
    });
  });
}

export function findPatientListByCondition(param){
  return callLocalApi("GetPatientInfoByKeyWord",param);
}

export function findPatientPage(param){
  return callLocalApi("FindPatientPage",param)
}


export function getLocalPatientById(id,callback){
  findPatientListByCondition({id}).then(({data})=>{
    console.log("getLocalPatientById",data)
    if(data.length > 0){
      callback && callback(data[0])
    }
  })
}

export function updatePatientCase(param){
  return callLocalApi("UpdatePatientCase",param)
}

export function syncPatientData(param){
  return callLocalApi("SyncCloudData",param)

}