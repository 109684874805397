import request from "@/utils/Request";
import {
  SportProjectTypeEnum,
  TreatmentStatusEnum,
  getEnumByType,
  SportSpeedEnum,
} from "@/utils/Enums";

/**
 * 获取训练项目列表
 * @returns
 */
export function getTrainProjectList(patientCaseId) {
  return request({
    url: `/api/hospital-data/sport/program/patient/${patientCaseId}`,
    method: "get",
  });
}

/**
 * 获取训练幅度数据
 * @returns
 */
export function getPatientTrainRangeData(patientId) {
  return request({
    url: `/api/hospital-data/patient-neck-sport-plan/sport/ranges/${patientId}`,
    method: "get",
  });
}

/**
 * 开始训练
 * @returns
 */
export function startTrain(data) {
  return request({
    url: `/api/hospital-data/treatmentsport`,
    method: "post",
    data,
  });
}

/**
 * 暂停训练
 * treatmentSportId	训练id，从“开始训练”中获取
 * @returns
 */
export function pauseEvaluation(treatmentSportId) {
  return request({
    url: `/api/hospital-data/treatmentsport/pause/${treatmentSportId}`,
    method: "put",
  });
}

/**
 * 继续训练
 * @returns
 */
export function continueTrain(treatmentSportId) {
  return request({
    url: `/api/hospital-data/treatmentsport/continue/${treatmentSportId}`,
    method: "put",
  });
}

/**
 * 终止训练
 *当选择时，abandonId为“终止原因列表”返回值的id
 *当其他原因时，abandonId设为"other",并且discardReasonText设为填入的内容
 * @param {*} treatmentSportId 训练id
 * @returns
 */
export function stopTrain(treatmentSportId, data) {
  return request({
    url: `/api/hospital-data/treatmentsport/stop/${treatmentSportId}`,
    method: "put",
    data,
  });
}

/**
 *  获取训练详情
 * @returns
 */
export function getTrainDetail(treatmentSportId) {
  return new Promise(function(resolve, reject) {
    request({
      url: `/api/hospital-data/treatmentsport/all/${treatmentSportId}`,
      method: "get",
    })
      .then(({ data }) => {
        const isStop =
          data.treatmentStatus === TreatmentStatusEnum.SPORT_STOP.type;
        const isFinishedAll =
          data.treatmentStatus === TreatmentStatusEnum.SPORT_FINISH.type ||
          isStop;

        data.frontCustomData = {
          //是否暂停
          isPause: data.pauseStatus === 1,
          //是否全部完成
          isFinishedAll: isFinishedAll,
          //是否终止
          isStop: isStop,
          //下一个运动名称
          nextSportName: null,
          //当前训练项目
          currentSportProject: null,
          //当前训练结果
          currentSportResult: null,
          //当前训练合计时间
          currentSportTotalTime: null,
          //当前训练速度描述
          currentSportSpeedDesc: null,
        };

        //处理数据
        for (let index = 0; index < data.sportProjects.length; index++) {
          const project = data.sportProjects[index];
          const projectResult = data.treatmentSportsDetail[index];
          const isCurrentTrainingProject = project.id === data.currentSportId;
          const rateData = projectResult.detail.rateData;

          const isLast = index === data.sportProjects.length - 1;

          if (isCurrentTrainingProject) {
            data.frontCustomData.currentSportProject = project;
            data.frontCustomData.currentSportResult = projectResult;
            data.frontCustomData.nextSportName = isLast
              ? "无"
              : data.sportProjects[index + 1].name;
          }

          //运动名称
          projectResult.sportName = project.name;
          //是否展示结果
          projectResult.showResult =
            isCurrentTrainingProject ||
            (data.frontCustomData.isFinishedAll && projectResult.duration);

          if (!rateData) {
            projectResult.detail.rateData = {};
            projectResult.maxNeckSport = {};
          } else if (
            SportProjectTypeEnum.NECK.type === projectResult.sportType &&
            Object.keys(rateData).length > 0
          ) {
            //标准颈部训练扩展字段
            projectResult.maxNeckSport = {
              leftFlexionRotate: rateData.maxLeftFlexionRotate.toFixed(1),
              rightFlexionRotate: rateData.maxRightFlexionRotate.toFixed(1),
              leftRotate: rateData.maxLeftRotate.toFixed(1),
              rightRotate: rateData.maxRightRotate.toFixed(1),
              protractRotate: rateData.maxProtractRotate.toFixed(1),
              hypsokinesisRotate: rateData.maxHypsokinesisRotate.toFixed(1),
            };
          }
        }

        //处理训练时间以及速度描述
        const currentSport = data.frontCustomData.currentSportProject;
        const speedDescJson = currentSport.speedDesc || "{}";
        const speedDesc = JSON.parse(speedDescJson);
        let totalTime = null;
        let speedText = null;
        if (currentSport.type == SportProjectTypeEnum.NECK.type) {
          const enumItem = getEnumByType(SportSpeedEnum, currentSport.speed);
          totalTime = speedDesc[enumItem.field];
          speedText = enumItem.desc;
        } else if (currentSport.type == SportProjectTypeEnum.NECK_MUSCLE.type) {
          totalTime = speedDesc[SportSpeedEnum.NORMAL.field];
          speedText = SportSpeedEnum.NORMAL.desc;
        }
        data.frontCustomData.currentSportTotalTime = totalTime;
        data.frontCustomData.currentSportSpeedDesc = speedText;

        resolve(data);
      })
      .catch((res) => {
        reject(res);
      });
  });
}

/**
 * 获取训练时间
 * @param {*} treatmentSportId 
 * @param {*} data 
 * @returns 
 */
export function getTrainTime(treatmentSportId) {
  return request({
    url: `/api/hospital-data/treatmentsport/sportedtime/${treatmentSportId}`,
    method: "get",
  });
}
