import * as echarts from "echarts";
import Moment from "moment";
export default {
  chartConstants: {
    /**
     * 轴标签颜色
     */
    axisLabelColor: "#3a4355",
    /**
     * 轴标签字体大小
     */
    axisLabelFontSize: 18,
    /**
     * 轴线颜色
     */
    axisLineColor: "#ebebeb",
    /**
     * 本体感觉正常柱状图颜色
     */
    selfBarNormalColor: "#59bf8c",
    /**
     * 本体感觉正常柱状图颜色
     */
    selfAbnormalBarColor: "#f79d28",
    selfNormRateAreaColor: new echarts.graphic.LinearGradient(
      0,
      0,
      1,
      0,
      [
        {
          offset: 0,
          color: "rgba(255,255,255, 0.3)",
        },
        {
          offset: 0.8,
          color: "#eef7f2",
        },
      ],
      false
    ),
  },
  getChartOptionTemplate() {
    const self = this;
    return {
      grid: {
        x: 38,
        bottom: 35,
        right: 5,
        top: 30,
      },
      tooltip: {
        formatter: chartFormat(),
      },
      xAxis: {
        type: "category",
        data: ["前屈", "后伸", "左屈", "右屈", "左旋", "右旋"],
        axisLabel: {
          color: self.chartConstants.axisLabelColor,
          fontSize: self.chartConstants.axisLabelFontSize,
          margin: 15,
          formatter: function(value) {
            const arr = value.split(/\n/);
            if (arr.length === 2 && arr[1].length === 19) {
              return arr[0] + "\n" + arr[1].substring(0, 10);
            }
            return value;
          },
        },
        axisLine: {
          lineStyle: {
            color: self.chartConstants.axisLineColor,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: self.chartConstants.axisLineColor,
          },
        },
      },
      yAxis: [
        {
          splitNumber: 5,
          nameGap: 10,
          nameTextStyle: {
            padding: [0, 0, 0, 45],
            color: self.chartConstants.axisLabelColor,
            fontSize: 17,
          },
          axisLabel: {
            margin: 15,
            color: self.chartConstants.axisLabelColor,
            fontSize: 17,
            formatter: null,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: self.chartConstants.axisLineColor,
            },
          },
          splitLine: {
            lineStyle: {
              color: self.chartConstants.axisLineColor,
            },
          },
        },
        {
          axisLine: {
            show: true,
            lineStyle: {
              color: self.chartConstants.axisLineColor,
            },
          },
        },
      ],
      series: [],
    };
  },

  /**
   * 本体感觉评估柱状图样式
   * @param {*}} name
   * @param {*} data
   * @returns
   */
  getSelfEvaluationChartBarSeries(name, normalColor, abNormalColor, data) {
    return {
      type: "bar",
      name: name,
      data: data,
      barMaxWidth: 40,
      label: {
        show: true,
        position: "top",
        color: "#a6b4ca",
        formatter: "{c}°",
        fontSize: 16,
      },
      itemStyle: {
        color: function(params) {
          if (params.value > 4) {
            return abNormalColor;
          }
          return normalColor;
        },
      },
    };
  },
  /**
   * 获取最近本体感觉评估图表配置
   * @returns
   */
  getLastSelfEvaluationChartOption() {
    const option = this.getChartOptionTemplate();
    option.xAxis.data = ["前屈", "后伸", "左屈", "右屈", "左旋", "右旋"];
    option.yAxis.name = "(角度)";
    option.tooltip.show = false;

    const t1 = this.getSelfEvaluationChartBarSeries(
      "第一次",
      this.chartConstants.selfBarNormalColor,
      this.chartConstants.selfAbnormalBarColor,
      [1.8, 1.8, 3.6, 7.2, 7.1, 7.2]
    );
    const t2 = this.getSelfEvaluationChartBarSeries(
      "第二次",
      this.chartConstants.selfBarNormalColor,
      this.chartConstants.selfAbnormalBarColor,
      [3.8, 3.2, 8.4, 6.4, 8.4, 3.6]
    );
    const t3 = this.getSelfEvaluationChartBarSeries(
      "第一次",
      this.chartConstants.selfBarNormalColor,
      this.chartConstants.selfAbnormalBarColor,
      [3.2, 3.2, 7.2, 7.1, 7.2, 3.8]
    );
    option.series = [t1, t2, t3];

    return option;
  },

  /**
   * 获取趋势统计图表参数模板
   * @returns
   */
  getTrendStatisticsChartOptionTemplate() {
    const option = this.getChartOptionTemplate();

    option.grid.left = 80;
    option.grid.right = 200;
    option.grid.bottom = 50;
    return option;
  },

  /**
   * 获取趋势统计折线图配置
   * @returns
   */
  getTrendStatisticsLineChartOption() {
    const option = this.getTrendStatisticsChartOptionTemplate();
    option.tooltip = {
      trigger: "axis",
      formatter: chartFormat(),
    };
    option.grid.bottom = 50;

    option.color = [
      "#009fef",
      "#5fbf52",
      "#ffb20d",
      "#fe6c6c",
      "#33c6d3",
      "#c678b5",
    ];
    option.legend = {
      data: ["左屈", "右屈", "左旋", "右旋", "前屈", "后伸"],
      icon: "roundRect",
      orient: "vertical",
      top: 30,
      right: 80,
      itemGap: 34,
      textStyle: {
        color: "#a5a5a5",
      },
    };

    //图例配置
    return option;
  },

  getLineSeries(name, data) {
    return {
      name: name,
      type: "line",
      data: data,
    };
  },

  getLineAreaSeriesTemplate() {
    return {
      name: "疼痛分值",
      type: "line",
      smooth: true,
      symbol: "emptyCircle",
      symbolSize: 6,
      showSymbol: true,
      lineStyle: {
        normal: {
          width: 3,
        },
      },
      areaStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0,
            0,
            1,
            0,
            [
              {
                offset: 0,
                color: "rgba(255,255,255, 0.3)",
              },
              {
                offset: 0.8,
                color: "rgba(48,124,243, 0)",
              },
            ],
            false
          ),
          shadowColor: "rgba(0, 0, 0, 0.1)",
          shadowBlur: 10,
        },
      },
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 0,
              color: "rgba(129,194,247,1)",
            },
            {
              offset: 0.2,
              color: "rgba(81,152,241,1)",
            },
            {
              offset: 0.7,
              color: "rgba(169,139,236,1)",
            },
            {
              offset: 1,
              color: "rgba(82,143,239,1)",
            },
          ]),
        },
        emphasis: {
          color: "rgb(0,196,132)",
          borderColor: "rgba(0,196,132,0.2)",
          extraCssText: "box-shadow: 8px 8px 8px rgba(0, 0, 0, 1);",
          borderWidth: 10,
        },
      },
      data: [],
    };
  },

  /**
   * 获取本地感觉散点图配置
   * @returns
   */
  getSelfPointChartConfig: function() {
    const option = {
      tooltip: {
        show: false,
        trigger: "axis",
      },
      color: ["#969bee"],
      grid: {
        top: 40,
        left: 60,
        bottom: 10,
        right: 10,
      },
      legend: {
        show: true,
      },
      toolbox: {},
      xAxis: {
        interval: 6,
        min: 0,
        max: 48,
        splitLine: {
          show: true,
          lineStyle: {
            color: this.chartConstants.axisLineColor,
            width: 1,
            type: "solid",
          },
        },
        nameTextStyle: {
          color: this.chartConstants.axisLabelColor,
        },
        axisLine: {
          lineStyle: {
            color: this.chartConstants.axisLineColor,
          },
        },
        axisLabel: {
          color: this.chartConstants.axisLabelColor,
          show: false,
        },
        axisTick: {
          inside: true,
        },
      },
      yAxis: {
        type: "value",
        min: 0,
        max: 20,
        interval: 4,
        minInterval: 1,
        axisLabel: {
          formatter: "{value}°",
          color: this.chartConstants.axisLabelColor,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: this.chartConstants.axisLineColor,
          },
        },
        splitLine: {
          lineStyle: {
            color: this.chartConstants.axisLineColor,
          },
        },
      },
      series: [],
    };
    return option;
  },
};

function formatTimeName(sName) {
  const arr = sName.split(/[\n]/);
  let name = arr[0];
  if (arr.length === 2 && arr[1].length === 19) {
    name = name + " " + Moment(arr[1]).format("MM.DD HH:mm");
  }
  return name;
}

export function getSelfLegendData() {
  return ["左屈", "右屈", "左旋", "右旋"];
}

export function chartFormat(unit = "", scale = null) {
  return function(data) {
    let params = [];
    if (data.constructor === Object) {
      params.push(data);
    } else if (Array.isArray(data)) {
      params = data;
    } else {
      return null;
    }
    // console.log("parms", params);
    const html = [];
    for (let index = 0; index < params.length; index++) {
      if (index === 0) {
        html.push(formatTimeName(params[0].name));
      }
      let value;
      if (params[index].value == undefined || params[index].value == null) {
        value = "N/A";
      } else {
        value =
          scale == null
            ? params[index].value
            : parseFloat(params[index].value).toFixed(scale);
        value = value + unit;
      }
      html.push(
        `<p>${params[index].marker} ${params[index].seriesName} <span style="color:#009fef;padding-left:40px;font-weight:bold;">${value}</span></p>`
      );
    }
    return html.join("");
  };
}

/**
 * 格式化数据
 * @param {*} scale
 */
export function fixedValue(chartData, scale) {
  const resultData = [];
  for (let i = 0; i < chartData.length; i++) {
    const sub = [];
    for (let j = 0; j < chartData[i].length; j++) {
      if (chartData[i][j] != null && chartData[i][j] != undefined) {
        sub.push(parseFloat((chartData[i][j]).toFixed(scale)));
      } else {
        sub.push(chartData[i][j]);
      }
    }
    resultData.push(sub);
  }
  return resultData;
}
