import Token from "@/utils/Token";
import { login } from "@/api/LoginService";
// import { doDataSync } from '@/api/DataSyncService'
import {syncPatientData} from "@/api/LocalProxyService"

export default {
  data() {
    return {
      rememberMe: null,
      loginForm: {
        username: "",
        password: "",
      },
      loginFormRule: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.initAccount();
    // doDataSync()
    // if (this.loginForm.username && this.loginForm.password) {
    //   this.handleLogin();
    // }
  },
  methods: {
    handleLogin() {
      if (this.rememberMe) {
        this.saveAccount();
      } else {
        this.clearAccount();
      }
      this.validForm("loginForm", () => {
        const params = new URLSearchParams();
        params.append("username", this.loginForm.username);
        params.append("password", this.loginForm.password);

        login(params).then(({ data }) => {
          
          Token.saveToken(data);

          const loading = this.$loading({
            lock: true,
            text: '系统初始中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          syncPatientData({hospitalId:data.loginHospitalId}).then(()=>{
            loading.close();
             window.location.href = "/";
          }).catch(()=>{
            loading.close();
             window.location.href = "/";
          })

          // window.location.href = "/";
        });
      });
    },
    saveAccount() {
      localStorage.setItem(
        "loginForm",
        window.btoa(JSON.stringify(this.loginForm))
      );
    },
    clearAccount() {
      localStorage.removeItem("loginForm");
    },
    initAccount() {
      const loginFormData = localStorage.getItem("loginForm");
      if (!loginFormData) {
        return;
      }
      try {
        this.rememberMe = "1";
        const formData = JSON.parse(window.atob(loginFormData));
        this.loginForm.username = formData.username;
        this.loginForm.password = formData.password;
      } catch (e) {
        console.log(e);
      }
    },
    validForm(formName, callback) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          callback && callback();
        } else {
          return false;
        }
      });
    },
  },
};
