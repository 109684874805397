<!-- 图片占位图 -->
<template>
  <div class="component img-placeholder-component" :style="{height:height}">
    <div class="placeholder-wrapper">
      <div :class="iconClass"></div>
      <div class="placeholder-text">{{ placeholder }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "暂无记录",
    },
    /**
     * 图标类型
     * evaluation：评估
     * 
     */
    iconType: {
      type: String,
      default: "evaluation",
    },
    height:{
        type:String,
        default:"500px"
    }
  },
  data() {
    return {};
  },

  components: {},

  computed: {
    iconClass() {
      return {
        "evaluation-icon": this.iconType === "evaluation",
      };
    },
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss">
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

.img-placeholder-component {
  @include flex_layout(column, center, center);
  width: 100%;
  height: 100%;
  .placeholder-wrapper {
    @include flex_layout(column, center, center);
  }
  .evaluation-icon {
    @include size(144px, 121px);
    @include bg-image("../../assets/common/no-evaluation.png", 144px center);
  }
  .placeholder-text {
    margin-top: 30px;
    @include font-size-color(20px, #181e29);
  }
}
</style>
