<!-- 本体感觉训练结果 -->
<template>
  <div class="component self-train-result-component">
    <div class="neck-train-result-wrapper" v-if="trainData.sportType === projectTypeEnum.SELFFEEL_OPEN.type">
      <div
        class="result-panel-wrapper"
        style="height:336px;align-items:flex-start;"
      >
        <div class="result-panel completion-rate" style="height:336px">
          <div class="icon-title completion-rate">平均完成率</div>
          <div class="completion-rate-process-wrapper">
            <el-progress
              type="dashboard"
              color="#25aef3"
              :width="218"
              :stroke-width="14"
              :percentage="trainData.detail.rateData.rate"
            ></el-progress>
          </div>
        </div>

        <!--角度表格-->
        <div class="flex-1">
          <self-angle-table :angle-list="angleList"></self-angle-table>
        </div>
      </div>
      <!--数据面板结束-->
      <div class="pose-error-table-wrapper">
        <pose-error-table
          :errors="trainData.detail.errorLogs"
        ></pose-error-table>
      </div>
    </div>

    <template v-else>
    <!--睁闭眼训练-->
    <div class="open-close-train-wrapper" style="margin-top:20px;">
      <div class="result-panel self-train-title-wrapper">
        <div class="icon-title completion-rate">睁眼+闭眼训练</div>
      </div>
      <self-point-chart-group ref="openCloseChart"></self-point-chart-group>
    </div>

    <!--睁闭眼训练-->
    <div class="open-close-train-wrapper" style="margin-top:20px;">
      <div class="result-panel self-train-title-wrapper">
        <div class="icon-title completion-rate">闭眼训练</div>
      </div>
      <self-point-chart-group ref="closeChart"></self-point-chart-group>
    </div>

    </template>
  </div>
</template>

<script>
import PoseErrorTable from "@/components/pose-error-table/PoseErrorTable.vue";
import SelfAngleTable from "./SelfAngleTable.vue";
import SelfPointChartGroup from '@/components/selft-point-chart/SelfPointChartGroup.vue';
import { SportProjectTypeEnum } from "@/utils/Enums";
export default {
  props: {
    trainData: {
      type: Object,
      default: () => {
        return {
          detail: {
            rateData: {},
          },
        };
      },
    },
  },
  data() {
    return {
      forwardData: [],
      backwardData: [],
    };
  },

  components: {
    PoseErrorTable,
    SelfAngleTable,
    SelfPointChartGroup,
  },

  computed: {
    angleList() {
      if (this.trainData.detail.rateData.additionStep1) {
        return JSON.parse(this.trainData.detail.rateData.additionStep1);
      }
      return [];
    },
    projectTypeEnum(){
      return SportProjectTypeEnum;
    }
  },

  mounted() {
    this.initData();
  },
  watch: {
    trainData: function() {
      this.initData();
    },
  },
  methods: {
    initData() {
      //解析散点图数据
      if (
        this.trainData &&
        this.trainData.detail &&
        this.trainData.detail.pointData
      ) {
        //1：睁闭眼；2：闭眼
        this.$refs.openCloseChart.loadData(this.trainData.detail.pointData,1)
        this.$refs.closeChart.loadData(this.trainData.detail.pointData,2)
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

.open-close-train-wrapper {
  border: 1px solid $pageBorderColor;
}
.self-train-title-wrapper {
  padding: 10px 20px;
  background-color: #f3f5f5;
}
</style>
