<!--标准颈部-->.
<template>
  <div class="standard-neck-component">
    <chart chart-title="训练时间" ref="trainTimeChartEl"></chart>
    <chart chart-title="完成率" ref="completionRateChartEl"></chart>
  </div>
</template>

<script>
import Chart from "../Chart.vue";
import ChartUtils from "@/utils/ChartUtils";
import { getChartData, getXAxisLabel } from "@/api/TrendService";
import {chartFormat,fixedValue} from "@/utils/ChartUtils";

export default {
  props: {
    trendData: {
      type: Object,
    },
  },
  components: {
    Chart,
  },
  data() {
    return {
      trainTimeChart: null,
      completionRateChart: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.trainTimeChart = this.$refs.trainTimeChartEl;
      this.completionRateChart = this.$refs.completionRateChartEl;
      this.loadData();
    },
    loadChart(option) {
      this.chart.loadChart(option);
    },
    loadData() {
      this.trainTimeChart.loadChart(this.getTrainTimeChartOption());
      this.completionRateChart.loadChart(this.getCompletionRateChartOption());
    },

    /**
     * 获取训练时长图表数据
     */
    getTrainTimeChartOption() {
      const option = ChartUtils.getTrendStatisticsLineChartOption();
      option.legend = null;
      option.yAxis[0].name = "(分钟)";
      option.xAxis.data = getXAxisLabel(this.trendData);
      option.yAxis[0].axisLabel.formatter = null;
      option.yAxis[0].minInterval = 2;
      option.tooltip.formatter = chartFormat("分钟",1);

      const lineSeries = ChartUtils.getLineAreaSeriesTemplate();
      lineSeries.name = "训练时间";
      const chartData = getChartData(this.trendData, "trainDuration");
      lineSeries.data = chartData.values[0].map((item) =>
        (item / 60).toFixed(1)
      );

      option.series = lineSeries;
      return option;
    },
    /**
     * 获取完成率图表数据
     */
    getCompletionRateChartOption() {
      const option = ChartUtils.getTrendStatisticsChartOptionTemplate();
      option.yAxis[0].name = "(百分比)";
      option.xAxis.data = getXAxisLabel(this.trendData)
      option.yAxis[0].max = 100;
      option.yAxis[0].interval = 20;
      option.tooltip.formatter = chartFormat("%");

      const data = getChartData(this.trendData,'rate')
      const chartData = fixedValue(data.values,0)


      const item = ChartUtils.getSelfEvaluationChartBarSeries(
        "完成率",
        "#56a793",
        "#56a793",
        chartData[0]
      );
      item.label.color = "#12a5ef";
      item.label.formatter= "{c}%",
      option.series = [item];
      return option;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";
.standard-neck-component {
}
</style>
