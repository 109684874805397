import Vue from "vue";
import VueRouter from "vue-router";

import Login from "@/views/login/Index.vue";
import Home from "@/views/home/Home.vue";
import PageTemplate from "@/views/page-template/PageTemplate.vue";
import Reception from "@/views/reception/Reception.vue";
import Workbench from "@/views/workbench/Workbench.vue";
import TrendStatistics from "@/views/trend-statistics/TrendStatistics.vue";
import EvaluationScheme from "@/views/evaluation-scheme/EvaluationScheme.vue";
import Evaluationing from "@/views/evaluationing/Evaluationing.vue";
import TrainScheme from "@/views/train-scheme/TrainScheme.vue";
import Training from "@/views/training/Training.vue";
import ReceptionLog from "@/views/reception-log/ReceptionLog.vue";
import DiagnosisResults from "@/views/diagnosis-results/DiagnosisResults.vue";
import PatientList from "@/views/patient-list/PatientList.vue";
import System from "@/views/system/System.vue";
import About from "@/views/about/About.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/page",
    name: "Page",
    component: PageTemplate,
    children: [
      {
        path: "reception",
        component: Reception,
        meta: {
          title: "接诊",
        },
      },
      {
        path: "workbench",
        component: Workbench,
        meta: {
          title: "",
        },
      },
      {
        path: "trend",
        component: TrendStatistics,
        meta: {
          title: "趋势统计",
        },
      },
      {
        path: "evaluation-scheme",
        component: EvaluationScheme,
        meta: {
          title: "选择方案",
        },
      },
      {
        path: "evaluationing",
        component: Evaluationing,
      },
      {
        path: "train-scheme",
        component: TrainScheme,
        meta: {
          title: "选择方案",
        },
      },
      {
        path: "training",
        component: Training,
        meta: {
          title: "训练中",
        },
      },
      {
        path: "reception-log",
        component: ReceptionLog,
        meta: {
          title: "接诊记录",
        },
      },
      {
        path: "diagnosis-results",
        component: DiagnosisResults,
      },
      {
        path: "patient-list",
        component: PatientList,
      },
      {
        path: "system",
        component: System,
      },
      {
        path: "about",
        component: About,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

// router.beforeEach((to, from, next) => {
//   next()
// })

export default router;
