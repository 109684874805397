<template>
	<div class="tabs-component">
		<div class="tab-list">
			<div v-for="(tab, index) in tabList" v-bind="tab.dataAttrs" :key="index" :class="{'active': isActive(index), 'disabled': tab.disabled}" @click="select(index)">{{ tab.title }}</div>
		</div>
		<div class="tab-content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tabList: [],
				activeTabIndex: 0
			}
		},

		mounted() {
			this.select(0);
			this.activeTabIndex = this.getInitialActiveTab();

			this.$root.$on('select-tab', index => this.select(index));
		},

		methods: {
			isActive(index) {
				return this.activeTabIndex === index;
			},
			select(index) {
				if (this.tabList.length == 0) {
					return;
				}
				const tab = this.tabList[index];
				if (!tab.isDisabled) {
					this.activeTabIndex = index;
				}
				this.$emit('changed', tab);
			},
			getInitialActiveTab() {
				const index = this.tabList.findIndex(tab => tab.active);
				return index === -1 ? 0 : index;
			}
		}
	}
</script>

<style lang="scss" scoped>
@import '../../styles/variable.scss';
@import '../../styles/mixin.scss';
.tabs-component{
    .tab-list{
        border-bottom: 1px solid $pageBorderColor;
		display: flex;
		justify-content: center;
        // height: 54px;
		min-height: 54px;
		flex-wrap: wrap;
        padding-top: 5px;
        box-sizing: border-box;
        div {
            box-sizing: border-box;          
            line-height: 40px;
            @include font-size-color(20px,$backColor1);
            // padding: 0px 5px;
            cursor: pointer;
            margin-right: 74px;
            &.active {
                border-bottom: 4px solid $themeColor;
                color: $themeColor;
            }
            &:last-child{
                margin-right: 0px;
        }
	}
}
   .tab-content{
        padding:20px;
    }
}
</style>
