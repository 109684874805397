/**
 * 校验手机号码
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @returns
 */
export function checkMobile(rule, value, callback) {
  if (!/^1[23456789]\d{9}$/.test(value)) {
    return callback(new Error("手机号格式不正确"));
  } else {
    return callback();
  }
}

/**
 * 校验邮箱
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @returns
 */
export function checkMail(rule, value, callback) {
  if (!rule.required && (value == "" || value == null)) {
    callback();
  } else if (!/^[\w.-]*\w@[\w.-]+\.[\w.-]+$/.test(value)) {
    return callback(new Error("请输入正确的邮箱"));
  } else {
    return callback();
  }
}

/**
 * 校验电话号码，手机号和座机号
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @returns
 */
export function checkPhone(rule, value, callback) {
  if (value == "") {
    callback();
    return;
  }
  //手机号码
  var isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
  // 座机格式
  var isMob = /^0?1[3458679][0-9]\d{8}$/;
  if (isMob.test(value) || isPhone.test(value)) {
    callback();
  } else {
    callback(new Error("请正确输入电话号码"));
  }
}

/**
 * 校验年龄
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @returns
 */
export function checkAge(rule, value, callback) {
  if (!value) {
    return callback(new Error("年龄不能为空"));
  }

  if (!/^[1-9]\d{0,2}$/.test(value)) {
    callback(new Error("请输入有效的年龄"));
  }
  callback();
}

/**
 * 校验mac地址
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @returns
 */
export function checkMac(rule, value, callback) {
  if (rule.required && (value == "" || value == null || value == undefined)) {
    callback(new Error(rule.requiredMsg));
    return;
  }
  var isMac = /^[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}$/;
  console.log(isMac.test(value), value);
  if (isMac.test(value)) {
    callback();
  } else {
    callback(new Error("请正确输入MAC地址，格式为：XX-XX-XX-XX-XX-XX"));
  }
}

export function checkChar(rule, value, callback) {
  if (rule.required && (value == "" || value == null || value == undefined || value.trim() == "")) {
    callback(new Error(rule.requiredMsg));
    return;
  }

  if (value == "") {
    callback();
    return;
  }

  var isValidChar = /^[\u4e00-\u9fa50-9a-zA-Z()\s,，_。?《》：‘’·【】？；><{}—\\"'/[\]、@|#！\-+=<>（）￥$…*~^.:!%&;“”]+$/;
  if (isValidChar.test(value)) {
    callback();
  } else {
    callback(new Error("请输入正确字符!"));
  }
}
