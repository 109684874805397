/**
 * 多文触屏输入法接口
 *  使用方法
 *
 */
export default {
  /**
   * 屏幕最大宽度
   */
  SCREEN_MAX_WIDTH: 1920,

  /**
   * 键盘宽度
   */
  KEYBOARD_WIDTH: 1024,

  /**
   * 键盘高度
   */
  KEYBOARD_HEIHGT: 360,

  /**
   * 文本键盘MODE
   */
  TEXT_KEYBOARD: "py26",

  /**
   * 英文模式
   */
  EN_TEXT_KEYBOARD: "en26",

  /**
   * 数字键盘Mode
   */
  NUMBER_KEYBOARD: "numonly",

  /**
   * Y起始位置
   */
  START_Y: 32,

  install(Vue) {
    // 注册一个全局自定义指令 `v-focus`
    const self = this;
    Vue.directive("focus", {
      inserted: function(el, param) {
        console.log("el", el);
        // 聚焦元素
        let input = null;
        let els = el.getElementsByTagName("input");
        if (els.length === 0) {
          els = el.getElementsByTagName("textarea");
        }

        if (els.length > 0) {
          input = els[0];
        } else {
          return;
        }

        input.addEventListener("focus", (event) => {
          const elPosition = {
            x: self.getLeft(el),
            y: self.getTop(el),
            width: el.offsetWidth,
            height: el.offsetHeight,
          };
          console.log(
            elPosition,
            param.value,
            event.offsetLeft,
            event.offsetTop
          );
          self.showKeyboard(elPosition, param.value);
        });

        input.addEventListener("blur", () => {
          self.hideKeyboard();
        });
      },
    });
  },
  //获取元素的纵坐标（相对于窗口）
  getTop(e) {
    var offset = e.offsetTop;
    if (e.offsetParent != null) offset += this.getTop(e.offsetParent);
    return offset;
  },
  //获取元素的横坐标（相对于窗口）
  getLeft(e) {
    var offset = e.offsetLeft;
    if (e.offsetParent != null) offset += this.getLeft(e.offsetParent);
    return offset;
  },
  /**
   *  显示键盘
   * @param {*} elPosition
   * @param {*} param
   */
  showKeyboard(elPosition, param) {
    let mode = this.TEXT_KEYBOARD;

    if (param.type === "number") {
      mode = this.NUMBER_KEYBOARD;
    } else if (param.type === "en-text") {
      mode = this.EN_TEXT_KEYBOARD;
    }

    let x = elPosition.x;
    let y = elPosition.y + this.START_Y;

    if (param.position === "rightTop") {
      x += elPosition.width;
    } else if (param.position === "leftBottom") {
      y += elPosition.height;
    } else if (param.position === "center") {
      x = (screen.availWidth - this.KEYBOARD_WIDTH) * 0.5;
      y = (screen.availHeight - this.KEYBOARD_HEIHGT) * 0.5;
    } else if (param.position === "auto") {
      this.callKeyboardApi(
        `/show/mode(${mode})/skin(apple.css)/size(1024,360)/autopos/adjust/`
      );
      return;
    }

    if (param.xOffset) {
      x += param.xOffset;
    }

    if (param.yOffset) {
      y += param.yOffset;
    }

    x = Math.ceil(x);
    y = Math.ceil(y);

    if (x + this.KEYBOARD_WIDTH > this.SCREEN_MAX_WIDTH) {
      x -= x + this.KEYBOARD_WIDTH - this.SCREEN_MAX_WIDTH;
    }

    this.callKeyboardApi(
      `/show/mode(${mode})/skin(apple.css)/size(1024,360)/pos(${x},${y})/`
    );
  },
  /**
   * 隐藏键盘
   */
  hideKeyboard() {
    this.callKeyboardApi("/hide");
  },
  /**
   * 调用键盘接口
   * @param {*} apiCmd
   */
  callKeyboardApi(apiCmd) {
    // const el = document.getElementById("keyboard");
    // if (el) {
    //   el.remove();
    // }
    // var script = document.createElement("iframe");
    // script.id = "keyboard";
    // script.style = "display:none;";
    // script.src = `http://localhost:1606${apiCmd}`;
    // document.body.appendChild(script);

    // const url = `http://localhost:1606${apiCmd}`;

    // if (window.XRHealerClient) {
    //   window.XRHealerClient.callKeyBoard(apiCmd)
    //     .then(() => {
    //     })
    //     .catch(() => {});
    // }


    const img = new Image();
    img.src = `http://localhost:1606${apiCmd}`;
  },
};
