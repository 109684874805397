<!-- 终止评估对话框 -->
<template>
  <div class="component restart-evaluation-component">
    <el-dialog
      title="选择重新评估方向"
      style="top: 15%;"
      :visible.sync="showDialog"
      :before-close="close"
      custom-class="vrmeds-dialog"
      width="1016px"
      :modal-append-to-body="false"
    >
      <div class="direction-wrapper">
        <div
          class="block-choose-item direction-tag"
          v-for="item in romList"
          :key="item.type"
          :class="{ selected: item.selected }"
          @click="item.selected = !item.selected"
        >
          {{ item.desc }}
        </div>
      </div>

      <div slot="footer" class="flex-center dialog-footer">
        <div class="flex-row-layout center btn-group">
          <div class="xrhealer-btn dialog yellow mg-r-40" @click="handleConfirmClick">完成</div>
          <div class="xrhealer-btn dialog gray" @click="handleCancelClick">
            取消
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {SportDirectionEnum} from "@/utils/Enums"
export default {
  data() {
    const romList = Object.values(SportDirectionEnum).map(item=>{
      return {
        type:item.type,
        desc:item.desc,
        selected:false
      }
    })
    console.log("romList",romList)
    return {
      showDialog: false,
      romList: romList,
      currentSelectDirectionId: null,
    };
  },

  components: {},

  computed: {},

  mounted() {
  },

  methods: {
    show() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
      this.romList.map(item=>item.selected = false)
    },
    handleCancelClick() {
      this.close();
    },
    handleConfirmClick() {
  
      const directions = this.romList.filter(item=>item.selected).map(item=>{
        return {
          direction:item.type
        }
      })

    if (directions.length === 0) {
        this.$message.error("请选择重新评估方向");
        return;
      }

      this.$emit("change", directions);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

.restart-evaluation-component {
  .direction-wrapper {
    @include flex_layout(row, flex-start, center);
    flex-wrap: wrap;
  }
  .direction-tag {
    padding-left: 0px;
    @include flex_layout(row, center, center);
    width: 146px;
    height: 68px;
    flex-shrink: 0;
    margin-right: 20px;
    &:last-child{
      margin-right: 0px;
    }
  }

  /deep/ .el-dialog__body {
    padding: 20px;
  }
}
</style>
