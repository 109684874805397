import request from "@/utils/Request";

/**
 * 获取字典类型
 * @returns
 */
export function getDictTypes(parentId) {
  return request({
    url: `/api/hospital-data/dict/types?id=${parentId}`,
    method: "get",
  });
}

/**
 * 根据code获取子级
 * @param {*} code 
 * @returns 
 */
export function getChildDictByCode(code){
  return request({
    url: `/api/main-data/dict/children?type=code&code=${code}`,
    method: "get",
  });
}

/**
 * 获取字典列表
 * @param {*} data 查询条件
 * 
 * @returns 
 */
export function getDictList(data) {
  return request({
    url: `/api/hospital-data/dict/medical`,
    method: "post",
    data,
  });
}

/**
 * 保存字典
 * @param {*} data 
 * @returns 
 */
export function saveDict(data){
  return request({
    url: `/api/hospital-data/dict`,
    method: "post",
    data,
  });
}


/**
 * 删除字典
 * @param {*} dictId 
 * @returns 
 */
export function deleteDict(dictId){
  return request({
    url: `/api/hospital-data/dict/${dictId}`,
    method: "post",
  });
}
