import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/app.scss";
import "@/styles/theme.scss";
import { CommonMixin } from "@/utils/Mixins.js";
import KeyBoard from "@/utils/KeyBoard.js";

// import ClientMonitor from 'skywalking-client-js';


Vue.use(ElementUI);
Vue.use(KeyBoard);
Vue.mixin(CommonMixin);

Vue.config.productionTip = false;

// ClientMonitor.register({
//   collector: 'http://localhost:8080',

//   service: 'APP',
//   pagePath: window.location.href,
//   serviceVersion: 'v1.0.0',
//   enableSPA:true
// });

// Vue.config.errorHandler = (error) => {
//   ClientMonitor.reportFrameErrors({
//     collector: 'http://localhost:8080',

//     service: 'APP',
//     pagePath: window.location.href,
//     serviceVersion: 'v1.0.0',
//   }, error);
// }

if (window.XRHealerClient) {
  // window.XRHealerClient.getClientVersion()
  //   .then((version) => {
  //     console.log("client version:" + version);
  //   })
  //   .catch(() => {});

  // window.XRHealerClient.callKeyBoard("http://localhost:1606/iconshowing").then(()=>{

  // }).catch(()=>{})
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
