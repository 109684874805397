<!-- 运动方向占比 -->
<template>
  <div class="component direction-ratio-component">
    <span class="fs-20-black1">{{ directData.desc }}:</span>
    <div class="progress-bar-wrapper mg-l-20">
      <el-slider @change="handleSliderChange" :disabled="directData.lock" :step="5" v-model="directData.value"></el-slider>
    </div>
    <span class="process-value">{{ directData.value }}%</span>
    <div class="lock-btn " @click="handleLockClick" :class="{lock:directData.lock,unlock:!directData.lock}"></div>
  </div>
</template>

<script>
export default {
  props: {
    directData: {
      type: Object,
    },
  
  },
  data() {
    return {
      selectValue: 0,
    };
  },

  components: {},

  computed: {},
  watch: {
   
  },

  mounted() {
    this.selectValue = this.value;
  },

  methods: {
    handleLockClick(){
      this.directData.lock = !this.directData.lock
    },
    handleSliderChange(){
      this.$emit("change",this.directData)
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

.direction-ratio-component {
  @include flex_layout(row, null, center);
  height: 40px;

  .process-value {
    margin-left: 14px;
    @include font-size-color(16px, $backColor1);
  }
  .lock-btn {
    margin-left: 10px;
    width: 40px;
    height: 40px;
    @include bg-image(null, 25px 31px, center);
    cursor: pointer;

    &.lock {
      background-image: url("../../assets/common/lock.png");
    }
    &.unlock {
      background-image: url("../../assets/common/unlock.png");
    }
  }
}
</style>
