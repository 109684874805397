<!-- 数据详情对话框 -->
<template>
  <div class="component xrhealer-theme data-detail-dialog-component ">
    <el-dialog
      :modal-append-to-body="false"
      :title="title"
      :visible.sync="showDialog"
      width="900px"
      top="60px"
      
    >
  
    <div style="height:65vh;">
         <iframe :src="pdfUrl" id="frameWindow" style="width:100%;height:100%;" frameborder="0"></iframe>
    </div>

     <div style="padding:20px;">
      <el-form>
        <div class="flex-row">
          <el-form-item label="选择打印机" prop="largeTypeId" style="margin-bottom:0px;">
            <el-select v-model="printer" placeholder="请选择打印机"  @change="handlePrinterSelect">
              <el-option
                v-for="item in printerList"
                :key="item"
                :label="item"
                :value="item"
               
                >
              </el-option>
            </el-select>
          </el-form-item>
          
        </div>

       
      </el-form>
    </div> 
      

      <div slot="footer">
        <div class="flex-row">
          <div class="form-dialog-footer-btn" @click="showDialog = false">
            取 消
          </div>
          <div class="form-dialog-footer-btn confirm" @click="handlePrintClick">打印报告</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import pdf from 'vue-pdf'

export default {
  props: {},
  components:{
    // pdf
  },
  data() {
    return {
      showDialog: false,
      title: "打印报告",
      printer:null,
      printerList:[],
      pdfUrl:null,
      numPages:0,
      printPdfUrl:null,
    };
  },



  computed: {
 
  },

  mounted() {},

  methods: {
    show(pdfUrl) {
      this.printPdfUrl = pdfUrl
      this.pdfUrl = pdfUrl+"#toolbar=0"
       if(!window.XRHealerClient){
         this.$message.error("请在客户端运行！");
         return
       }
      const self = this
       window.XRHealerClient.getPrinterList().then((printers)=>{
        console.log("printers",printers)
        self.printerList = printers
        const printer = window.localStorage.getItem("printer")

        const index = printers.findIndex(item=>item===printer)
        if(index===-1){
          this.printer = null
        }else{
          this.printer = printer
        }

        this.showDialog = true;
       }).catch(()=>{})
    },
    handlePrinterSelect(val){
      window.localStorage.setItem("printer",val)
    },
    handlePrintClick(){
      if(this.printer === null){
        this.$message.error("请选择打印机");
        return;
      }

      const loading = this.$loading({
          lock: true,
          text: '打印中，请稍候',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        const self = this
        setTimeout(() => {
          self.showDialog = false
          loading.close();
        }, 5000);

      window.XRHealerClient.printPDF(this.printPdfUrl,this.printer).then(()=>{
        console.log("打印成功")
      }).catch((res)=>{
        console.log(res)
        //this.$message.error('打印失败')
      })
    }
 
  },
};
</script>
<style lang="scss">
@import '@/styles/mixin.scss';
@import '@/styles/variable.scss';

.data-detail-dialog-component {

  .el-dialog {
    .el-dialog__body {
      padding: 0px !important;
    }
  }

  .printer-form{
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

}
</style>
