<!--  -->
<template>
  <div class="vr-progress-component">
    <div class="vr-status-wrapper">
      <div class="in-progress-icon " :class="{finish:isFinish}"></div>
      <div class="vr-status mg-l-20">
        <span class="vr-status-color-normal" >{{isFinish?finishTitle:'正在进行'}}：</span><span class="desc">{{projectTitle}}</span>
        <span class="mg-l-50">VR电量：</span>
        <span class="vr-status-color-normal" v-if="deviceData.vrElectric>=20">{{deviceData.vrElectric}}%</span>
        <span class="vr-status-color-normal" style="color:red;" v-else>{{deviceData.vrElectric}}%</span>
        <span class="mg-l-50">网络：</span>
        <span class="vr-status-color-normal" v-if="deviceData.vrConnect">连接中</span>
        <span class="vr-status-color-abnormal" style="color:red;" v-else>网络断开</span>
      </div>
    </div>
    <div class="mg-l-50 fs-20" v-if="speed">
        <span class="color_ac">速度：</span><span class="color_black1">{{speed}}</span>
      </div>

      <div class="time-wrapper mg-l-50" v-if="maxTime">
        <span>{{sportTime | formatSeconds}}</span>
       <el-progress style="flex:1;margin:0px 10px;" :percentage="(sportTime/maxTime)*100" :stroke-width="10" color="#12a5ef" :show-text="false"></el-progress>
        <span>{{maxTime | formatSeconds}}</span>
      </div>
      <div class="flex-1" v-else></div>

     <div class="mg-l-50 fs-20" v-if="nextSport">
        <span class="color_ac">下一个训练：</span><span class="color_black1">{{nextSport}}</span>
      </div>
  </div>
</template>

<script>
export default {
  props:{
    isFinish:{
      type:Boolean,
      default:false,
    },
    projectTitle:{
      type:String,
      default:''
    },
    finishTitle:{
      type:String,
      default:''
    },
    deviceData:{
      type:Object
    },
    speed:{
      type:String
    },
    sportTime:{
      type:Number
    },
    maxTime:{
      type:Number
    },
    nextSport:{
      type:String
    }
  },
  data() {
    return {};
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

.vr-progress-component {
  @include flex_layout(row, null, center);
  .vr-status-wrapper {
    @include flex_layout(row, null, center);
    @include font-size-color(20px,$backColor1);
  }
  .in-progress-icon {
   @include bg-image('../../assets/common/sporting.gif',40px);
   width: 40px;
   height: 40px;
   &.pause{
       background-image: url('../../assets/common/sport-pause.png');
   }
   &.finish{
     background-image: url('../../assets/common/finish-evaluation.png');
   }
  }
  .vr-status{
      @include flex_layout(row);
  }
  .time-wrapper{
    flex:1;
    @include flex_layout(row,center,center);
    @include font-size-color(20px,$backColor1);
  }

}
</style>
