<!-- 本体感觉角度数据 -->
<template>
  <div class="self-angle-speed-table-component">
    <div class="title-bar-wrapper" v-if="showHeader">
      <div class="icon-title pose-error">角速度（每秒）、时长</div>
    </div>
    <div class="pose-error-table">
      <div class="header-wrapper">
        <div class="header">
          <span class="direction">方向</span>
          <span class="error-direction">平均角速度</span>
          <span class="desc">最大角速度</span>
          <span class="duration">总时长</span>
        </div>
      </div>
      <div class="body">
        <div class="row" :key="item.id" v-for="item in angleList">
          <span class="direction">{{ item.direction | formatSportDirection }}</span>
          <span class="error-direction">{{item.avgAngularSpeed | formatAngleSpeed}}</span>
          <span class="desc">{{ item.maxAngularSpeed | formatAngleSpeed}}</span>
          <span class="duration">{{ item.actionTime | formatSecond}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
      showHeader:{
      type:Boolean,
      default:true
    },
  angleList : {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
    
    };
  },

  components: {},

  computed: {},

  mounted() {
    
  },
  watch:{
  
  },
  methods: {
    initData(){

    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";
@mixin content-padding {
  padding: 10px 20px 0px 20px;
}
.self-angle-speed-table-component {
  border: 1px solid $pageBorderColor;
  .title-bar-wrapper {
    @include content-padding;
    padding-top: 0px;
    height: 60px;
    background-color: #f3f5f5;
    @include flex_layout(row, space-between, center);
  }
  .error-times {
    @include font-size-color(30px, $themeColor);
    .unit {
      font-size: 20px;
    }
  }
  .pose-error-table {
    .header-wrapper {
      box-sizing: border-box;
      background-color: #fff;
      //    padding-left: 20px;
    }
    .header {
      @include flex_layout(row, null, center);
      @include font-size-color(16px, $backColor1);
      height: 34px;
      margin: 0px 20px;
    }
    .row {
      @include flex_layout(row, null, center);
      margin: 0px 20px;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid $pageBorderColor;

      &:last-child{
        border-bottom: none;
      }
    }
    .direction {
      padding-left: 40px;
      box-sizing: border-box;
      width: 20%;
    }
    .error-direction {
      box-sizing: border-box;
      width: 29%;
    }
    .desc {
      box-sizing: border-box;
      flex: 1;
    }
    .duration {
      box-sizing: border-box;
      width: 19%;
    }
  }  
}
</style>
