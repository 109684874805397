<template>
  <div class="page home-page">
    <div class="home-top-banner">
      <div class="top-bar-wrapper">
        <status-bar></status-bar>
      </div>
      <div class="logo-wrapper">
        <img src="../../assets/home/logo.png" class="logo" />
        <h2 class="sys-name">虚拟现实颈部评估及康复训练系统软件V1.1</h2>
      </div>
    </div>

    <div class="menu-wrapper">
      <div class="menus">
        <div
          class="menu-item"
          @click="handleMenuItemClick(menu)"
          :key="menu.id"
          v-for="menu in menuData"
        >
          <div class="menu-icon " :class="[menu.moduleIcon]"></div>
          <div class="menu-name">{{ menu.moduleName }}</div>
        </div>
      </div>
    </div>

    <div class="home-bottom-banner">
      <h2 class="company-name">成都翡铭科技有限公司</h2>
      <h3 class="copyright">
        Copyright@2017-{{new Date().getFullYear()}} 成都翡铭科技有限公司 All Right Reserved.
      </h3>
    </div>
  </div>
</template>

<script>
import StatusBar from "@/components/status-bar/StatusBar.vue";
import { getUserMenus } from "@/api/SysService";
import receptionService from "@/api/ReceptionService";

export default {
  components: { StatusBar },
  comments: {
    StatusBar,
  },

  data() {
    return {
      menuData: [],
    };
  },
  created() {
    getUserMenus().then(({ data }) => {
      this.menuData = data;
    });
  },
  methods: {
    handleMenuItemClick(menu) {
      if (menu.modulePath === "/page/workbench") {
        console.log(menu.modulePath);
        const reception = receptionService.getLastReception();
        if (reception) {
          const url = `${menu.modulePath}?patientCaseId=${reception.patientCaseId}`;
          this.navigateTo(url);
        }else{
           this.$alert('今日还未接诊患者，请先进行接诊。','提示', {
              confirmButtonText: '确定',
          });
        }
        return;
      }
      this.navigateTo(menu.modulePath);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./Home.scss";
</style>
