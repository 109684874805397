import request from "@/utils/Request";
import Moment from "moment";
import axios from "axios";

/**
 * 根据患者手机号、姓名获取患者信息
 * @returns
 */
export function getPatientByKeyWord(params) {
  return request({
    url: "/api/hospital-data/patient/query",
    method: "get",
    params,
  });
}

/**
 *  获取接诊医生列表
 * @returns
 */
export function getDoctorList() {
  return request({
    url: "/api/main-data/user/inonehospital",
    method: "get",
  });
}

/**
 * 获取诊断列表
 * @returns
 */
export function getDiagnosisList() {
  return request({
    url: "/api/hospital-data/patientcase/diagnosistype",
    method: "get",
  });
}

/**
 * 获取最近接诊
 * dateType
 * 日期类型 0 本周 1 本月
 * @returns
 */
export function getLastReceptionList(dateType) {
  return request({
    url: `/api/hospital-data/patient/recent?dateType=${dateType}`,
    method: "get",
  });
}

/**
 * 进行接诊
 * @param {*} dateType
 * @returns
 */
export function doReception(data) {
  return request({
    url: `/api/hospital-data/patient/treatment`,
    method: "post",
    data,
  });
}

/**
 * 获取接诊列表
 * @param {*} patientCaseId
 * @returns
 */
export function getReceptionList(patientCaseId) {
  return request({
    url: `/api/hospital-data/treatment/from/case/${patientCaseId}`,
    method: "get",
  });
}

/**
 * 获取接诊详情
 * @param {*} patientCaseId
 * @returns
 */
export function getReceptionDetail(treatmentId) {
  return request({
    url: `/api/hospital-data/treatment/all/${treatmentId}`,
    method: "get",
  });
}

/**
 * 患者数据同步
 */
export async function doDataSync(mobile) {
  await axios.get("/api/main-data/hospital/sync/ping", {
    timeout: 2000,
  });

  return request({
    url: `/api/hospital-data/sync/patient/${mobile}`,
    method: "get",
  });
}

/**
 * 获取报告打印数据
 */
export function getReportPrintData(treatmentId,patientName){
  return request({
    url: `/api/hospital-data/evaluation/report/print/${treatmentId}?userName=${patientName}`,
    method: "get",
  });
}
/**
 * 工作台业务对象
 */
export default {
  /**
   * 保存最近接诊的接诊信息
   */
  saveLastReception(reception) {
    const now = Moment().format("YYYYMMDD");
    console.log(now);
    reception.receptionDate = now;
    window.localStorage.setItem("lastReception", JSON.stringify(reception));
  },
  /**
   * 获取最近接诊信息
   */
  getLastReception() {
    const now = Moment().format("YYYYMMDD");
    const receptionStr = window.localStorage.getItem("lastReception");
    if (!receptionStr) {
      return null;
    }
    const reception = JSON.parse(receptionStr);
    if (reception.receptionDate !== now) {
      return null;
    }
    return reception;
  },
};
