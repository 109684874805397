import GenderButton from "@/components/gender-button/GenderButton.vue";
import {
  // getPatientByKeyWord,
  getDoctorList,
  getDiagnosisList,
  // getLastReceptionList,
  // doReception,
  doDataSync,
} from "@/api/ReceptionService";
import receptionService from "@/api/ReceptionService";
import { callLocalApi ,findPatientListByCondition,getLocalPatientById,updatePatientCase} from "@/api/LocalProxyService";
import { 
  getPatientData, 
  // updatePatientData 
} from "@/api/WorkbenchService";
import { checkMobile, checkAge, checkChar } from "@/utils/Validator";
import Token from "@/utils/Token";
export default {
  data() {
    return {
      pageParam: {
        patientCaseId: null,
        treatmentId: null,
      },
      lockSuggest: false,
      isEditMode: false,
      lastReceptionDateType: 0,
      lastReceptionList: [],

      form: {
        id: null,
        name: "",
        tel: "",
        age: null,
        gender: null,
        diagnosis: null,
        liableUserId: null,
        remarks: null,
      },
      rules: {
        name: [
          {
            required: true,
            requiredMsg: "患者姓名不能为空！",
            validator: checkChar,
            trigger: "blur",
          },
        ],
        diagnosis: [
          {
            required: true,
            message: "诊断必须选择！",
            trigger: "blur",
          },
        ],
        liableUserId: [
          {
            required: true,
            message: "负责医生必须选择",
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            message: "患者手机号不能为空!",
            trigger: "blur",
          },
          {
            validator: checkMobile,
          },
        ],
        gender: [
          {
            required: true,
            message: "性别必须选择!",
          },
        ],
        age: [
          {
            required: true,
            message: "患者年龄不能为空!",
            trigger: "blur",
            min: 0,
          },
          {
            validator: checkAge,
          },
        ],
        remarks: [
          {
            required: false,
            validator: checkChar,
            trigger: "blur",
          },
        ],
      },
      doctors: [],
      diagnosisList: [],
      patientData: null,
      elLoading: null,
      telQueryList: [],
    };
  },

  components: { GenderButton },

  computed: {},

  mounted() {
    this.setPageTemplateTitle("接诊");
    if (this.pageParam.patientCaseId) {
      this.lockSuggest = true;
      this.isEditMode = true;
      this.setPageTemplateTitle("编辑患者");

      getPatientData(this.pageParam.patientCaseId)
        .then(({ data }) => {
          this.patientData = data;
          // this.form.name = data.name;
          // this.form.tel = data.tel;
          this.form.age = data.age;
          this.form.gender = data.gender;
          this.form.diagnosis = data.diagnosis;
          this.form.liableUserId = data.liableUserId;
          this.form.remarks = data.remarks;
          this.form.patientCaseId = data.patientCaseId;
          this.$refs.genderButton.selectGender(data.gender);

          getLocalPatientById(data.patientId,(patient)=>{
             this.form.name = patient.name;
             this.form.tel = patient.tel;
          })
        })
        .catch(() => {});
    }
    this.getInitData();
  },

  methods: {
    handleTelBlur() {
      const formItems = this.telQueryList.filter(
        (item) => item.tel === this.form.tel
      );
      if (formItems.length > 0) {
        this.handleSelect(formItems[0]);
      }
    },
    handleTelChange(tel, cb) {
      if (this.lockSuggest) {
        return cb([]);
      }
      // getPatientByKeyWord({ tel: tel }).then(({ data }) => {
      //   this.telQueryList = JSON.parse(JSON.stringify(data));
      //   cb(data);
      // });

      findPatientListByCondition({tel}).then(({data})=>{
          console.log("getPatientListByKeyWord",data)
           this.telQueryList = JSON.parse(JSON.stringify(data));
           cb(data);
      })
      
    },
    handleNameChange(name, cb) {
      if (this.lockSuggest) {
        return cb([]);
      }
      // getPatientByKeyWord({ keyWord: name }).then(({ data }) => {
      //   cb(data);
      // });

      findPatientListByCondition({name}).then(({data})=>{
        console.log("getPatientListByKeyWord",data)
         cb(data);
      })
    },
    
    getInitData() {
      getDoctorList()
        .then(({ data }) => {
          this.doctors = data;
        })
        .catch(() => {});

      getDiagnosisList()
        .then(({ data }) => {
          this.diagnosisList = data;
        })
        .catch(() => {});

      this.getLastReceptionData(this.lastReceptionDateType);
    },
    handleSelect(item) {
      this.lockSuggest = true;
      this.clearFormValidate();
      this.form = item;
      this.$refs.genderButton.selectGender(item.gender);
    },
    clearForm() {
      this.lockSuggest = false;
      this.form = {};
      this.$refs.genderButton.selectGender(null);
      this.$nextTick(() => {
        this.clearFormValidate();
      });
    },
    handleGenderChange(gender) {
      this.form.gender = gender;
    },
    clearFormValidate() {
      if (this.$refs["form"]) {
        this.$refs["form"].clearValidate();
      }
    },
    /**
     * 保存接诊
     */
    handleReception() {
      this.$refs["form"].validate((valid) => {
        console.log(valid);
        if (valid) {
          if (this.isEditMode) {
           
            // updatePatientData(this.patientData.patientId, this.form).then(
            //   () => {
            //     window.history.go(-1);
            //     //this.navigateTo(url)
            //   }
            // );
            const formData = JSON.parse(JSON.stringify(this.form));
            formData.patientId = this.patientData.patientId
            updatePatientCase(formData).then(({data})=>{
              console.log("updatePatientCase",data)
              window.history.go(-1);
            })

          } else {
            // doReception(this.form).then(({ data }) => {
            //   receptionService.saveLastReception(data);
            //   const url = `/page/workbench?patientCaseId=${data.patientCaseId}`;
            //   this.navigateTo(url);
            // });
            const userInfo = Token.getLoginUser();
            const param = JSON.parse(JSON.stringify(this.form));
            param.doctorId = userInfo.id;
            
            callLocalApi("register", param).then(({data}) => {
              receptionService.saveLastReception(data);
              const url = `/page/workbench?patientCaseId=${data.patientCaseId}`;
              this.navigateTo(url);
            });
          }
        }
      });
    },
    getLastReceptionData(type) {
      this.lastReceptionDateType = type;
      // getLastReceptionList(this.lastReceptionDateType)
      //   .then(({ data }) => {
      //     this.lastReceptionList = data;
      //   })
      //   .catch(() => {});
        findPatientListByCondition({dateType:this.lastReceptionDateType}).then(({data})=>{
          this.lastReceptionList = data;
        })
    },
    handlePatientNameClick(item) {
      console.log(item);
      this.lockSuggest = true;
      this.clearFormValidate();
      this.form = item;
      this.$refs.genderButton.selectGender(item.gender);
    },
    handlePatientDataSyncClick() {
      this.$refs["form"].validateField("tel", (errorMessage) => {
        if (errorMessage) {
          this.$message.error(errorMessage);
          return;
        }
        this.elLoading = this.$loading({
          lock: true,
          text: "同步中",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.3)",
        });
        doDataSync(this.form.tel)
          .then(() => {
            this.elLoading && this.elLoading.close();
            this.$message({
              message: "操作成功!",
              type: "success",
            });
          })
          .catch((e) => {
            this.elLoading && this.elLoading.close();
            console.log(e);
            if (e.message.indexOf("timeout") != -1) {
              this.$message.error(
                "无法连接同步服务器，请确保当前设备能够正常访问互联网服务。"
              );
            }
          });
      });
    },
  },
};
