<!-- 终止评估对话框 -->
<template>
  <div class="component stop-evaluation-component">
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      :before-close="close"
      custom-class="vrmeds-dialog"
      style="top: 15%;"
      width="740px"
      :modal-append-to-body="false"
    >
      <div class="stop-reason-wrapper">
        <div
          class="block-choose-item reason-tag"
          v-for="item in reasonList"
          :key="item.id"
          :class="{ selected: currentSelectReasonId === item.id }"
          @click="currentSelectReasonId = item.id"
        >
          {{ item.name }}
        </div>
      </div>

      <div slot="footer" class="flex-center dialog-footer">
        <div class="flex-row-layout center btn-group">
          <div class="xrhealer-btn dialog yellow mg-r-40" @click="handleConfirmClick">完成</div>
          <div class="xrhealer-btn dialog gray" @click="handleCancelClick">
            取消
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getReasonList } from "@/api/CommonService";
export default {
  props:{
    title:{
      type:String,
      default:''
    },
    type:{
       type:String,
        default:''
    }
  },
  data() {
    return {
      showDialog: false,
      reasonList: [],
      currentSelectReasonId: null,
    };
  },

  components: {},

  computed: {},

  mounted() {
   
  },

  methods: {
    getInitData() {
      getReasonList(this.type).then(({ data }) => {
        this.reasonList = data;
      });
    },
    show() {
       this.getInitData();
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
      this.currentSelectReasonId = null;
    },
    handleCancelClick() {
      this.close();
    },
    handleConfirmClick() {
      if (!this.currentSelectReasonId) {
        this.$message.error("请选择原因");
        return;
      }
      this.$emit("change", this.currentSelectReasonId);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

.stop-evaluation-component {
  .stop-reason-wrapper {
    @include flex_layout(row, flex-start, center);
    flex-wrap: wrap;
  }
  .reason-tag {
    padding-left: 0px;
    @include flex_layout(row, center, center);
    width: 212px;
    height: 86px;
    flex-shrink: 0;
    margin-right: 20px;
    background-color: #f5f8fb;
    margin-bottom: 20px;
  }

  /deep/ .el-dialog__body {
    padding: 20px;
    padding-right: 0px;
  }
}
</style>
