import request from "@/utils/Request";
import Token from "@/utils/Token";

/**
 * 获取所有标签
 * @returns
 */
export function getAllTags() {
  const userInfo = Token.getLoginUser();

  return new Promise(function(resolve, reject) {
    request({
      url: `/api/main-data/sys/dict/program/data/inhospital/${userInfo.hospitalId}`,
      method: "get",
    })
      .then(({ data }) => {
        const tags = {};
        for (let i = 0; i < data.length; i++) {
          tags[data[i].code] = data[i].hospitalTags;
        }
        resolve(tags);
      })
      .catch((res) => {
        reject(res);
      });
  });
}


/**
 * 获取诊断内容
 * @returns
 */
 export function getCaseEvaluation(patientCaseId) {
  return request({
    url: `/api/hospital-data/patientcase/prescription/evaluation/${patientCaseId}`,
    method: "get",
  });
}

/**
 * 保存诊断内容
 * @returns
 */
 export function saveCaseEvaluation(data) {
  return request({
    url: `/api/hospital-data/patientcase/prescription/evaluation`,
    method: "post",
    data
  });
}



