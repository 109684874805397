<!-- 疼痛分值进度条 -->
<template>
  <div class="component pain-progress-component">
    <div class="progress-bar">
      <div class="progress-value" :style="{left:(41 * value - 16)+'px'}">
        <span>{{ value }}</span>
      </div>
    </div>
    <div class="progress-range">
      <span>0</span>
      <span class="max-value">10</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
        
    };
  },

  components: {},
  watch: {
      
  },
  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";
.pain-progress-component {
    box-sizing: border-box;
    padding-top: 20px;
  height: 80px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;

  .progress-bar {
    width: 412px;
    position: relative;
    background-image: -moz-linear-gradient(
      0deg,
      rgb(13, 191, 89) 0%,
      rgb(245, 223, 0) 51%,
      rgb(230, 35, 40) 100%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(13, 191, 89) 0%,
      rgb(245, 223, 0) 51%,
      rgb(230, 35, 40) 100%
    );
    width: 412px;
    height: 18px;
    border-radius: 9px;
  }
  .progress-value {
    position: absolute;
    border: 4px solid $themeColor;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    z-index: 100;
    background: #fff;

    top: -6px;
    left: 30%;

    span {
      position: absolute;
      top: -32px;
      left: 6px;
      @include font-size-color(20px, $themeColor);
    }
  }
  .progress-range {
    @include flex-row(space-between);
    @include font-size-color(16px, $backColor1);
  }
    .max-value{
        margin-right: -5px;
    }
}
</style>
