<!-- 字典编辑对话框 -->
<template>
  <div class="component dict-edit-dialog">
    <el-dialog
      :modal-append-to-body="false"
      :title="title"
      :visible.sync="showDialog"
      width="930px"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <div class="flex-row">
          <el-form-item label="字典大类" prop="largeTypeId">
            <el-select
              v-model="form.largeTypeId"
              placeholder="请选择字典大类 "
              @change="handleLargeTypeChange"
            >
              <el-option
                v-for="item in largeTypeOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div style="width:20px;"></div>
          <el-form-item label="字典小类" prop="subTypeId">
            <el-select v-model="form.subTypeId" placeholder="请选择字典小类 ">
              <el-option
                v-for="item in subTypeOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="flex-row desc">
          <el-form-item label="描述" prop="describes">
            <el-input
              v-model="form.describes"
              maxlength="100"
              show-word-limit
              type="textarea"
              :rows="4"
              autocomplete="off"
              v-focus="{type:'text',position:'leftBottom',xOffset:0,yOffset:0}"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>

      <div slot="footer">
        <div class="flex-row">
          <div class="form-dialog-footer-btn" @click="showDialog = false">
            取 消
          </div>
          <div class="form-dialog-footer-btn confirm" @click="handleConfirmClick">确 定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { checkChar } from "@/utils/Validator";
import { getDictTypes, saveDict } from "@/api/DictService";

export default {
  data() {
    return {
      showDialog: false,
      title: "",
      largeTypeOptions: [],
      subTypeOptions: [],
      canSubmit: true,
      form: {
        id: "",
        describes: "",
        largeTypeId: "",
        subTypeId: "",
      },
      rules: {
        describes: [
          {
            required: true,
            requiredMsg: "描述不能为空!",
            trigger: "blur",
            validator: checkChar,
          },
          {
            max: 100,
            message: "最大长度100字符",
            trigger: "blur",
          },
        ],
        largeTypeId: [
          {
            required: true,
            message: "字典大类必须选择!",
            trigger: "blur",
          },
        ],
        subTypeId: [
          {
            required: true,
            message: "字典小类必须选择!",
            trigger: "blur",
          },
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.getInitData();
  },

  methods: {
    /**
     * 获取初始数据
     */
    getInitData() {
      //获取大类
      getDictTypes("")
        .then(({ data }) => {
          this.largeTypeOptions = data;
        })
        .catch(() => {});
    },

    handleLargeTypeChange() {
      this.form.subTypeId = "";
      this.subTypeOptions = [];

      if (!this.form.largeTypeId) {
        return;
      }

      getDictTypes(this.form.largeTypeId)
        .then(({ data }) => {
          this.subTypeOptions = data;
        })
        .catch(() => {});
    },
    handleConfirmClick() {
      if (!this.canSubmit) {
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return false;
        }

        this.canSubmit = false;
        saveDict(this.form)
          .then(() => {
             this.$message({
							type: 'success',
							message: '操作成功!'
						});
            this.$emit("editSuccess");
            this.close();
          })
          .catch(() => {
            this.canSubmit = true;
          });
        return true;
      });
    },
    close() {
      this.showDialog = false;
    },
    open(formData) {
      this.canSubmit = true
      if (!formData) {
        this.title = "新增";
        this.form.id = "";
        this.form.largeTypeId = "";
        this.form.subTypeId = "";
        this.form.describes = "";
      } else {
        this.title = "编辑";
        this.form.id = formData.id;
        this.form.largeTypeId = formData.largeTypeId;
        this.form.describes = formData.describes;
        this.handleLargeTypeChange()
        this.form.subTypeId = formData.subTypeId;
      }
      this.showDialog = true;
      this.$nextTick(()=>{
        this.$refs['form'].clearValidate()
      })
    },
  },
};
</script>
<style lang="scss">
.dict-edit-dialog {
  .el-dialog__body {
    padding: 20px 20px 0px 20px;
  }
  .desc {
    .el-form-item__label {
      top: -55px;
    }
  }
}
</style>
