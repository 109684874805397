import Token from '@/utils/Token'
import { updatePassword, checkPassword } from '@/api/SysService'
import {exit} from '@/api/LoginService';
import { mapState } from 'vuex';
export default {

  props:{
    mode:{
      type:String,
      default:'statusBar'
    },
    pageTitle:{
      type:String,
      default:''
    },
    showBack:{
      type:Boolean,
      default:true
    },
    backToHome:{
      type:Boolean
    }
  },

  data() {
    var validatePass = (rule, value, callback) => {
      if (!/[a-zA-Z]+/.test(value) || !/\d+/.test(value)) {
        callback(new Error('密码必须包含字母与数字'))
      }
      if (this.pwdForm.reNewPwd !== '') {
        this.$refs.pwdForm.validateField('reNewPwd')
      }
      callback()
    }

    var validatePass2 = (rule, value, callback) => {
      if (value !== this.pwdForm.newPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      pwdDialogVisible: false,
      aboutUsVisiable: false,
      isInitPwd: false,
      userInfo: Token.getLoginUser(),
      pwdForm: {
        oldPwd: '',
        newPwd: '',
        reNewPwd: '',
      },
      pwdRules: {
        oldPwd: [
          {
            required: true,
            message: '请输入原密码',
            trigger: 'blur',
          },
        ],
        newPwd: [
          {
            required: true,
            message: '请输入新密码',
            trigger: 'blur',
          },
          {
            min: 6,
            max: 20,
            message: '密码长度6至20位',
            trigger: 'blur',
          },
          {
            validator: validatePass,
            trigger: 'blur',
          },
        ],
        reNewPwd: [
          {
            required: true,
            message: '请再次输入新密码',
            trigger: 'blur',
          },
          {
            validator: validatePass2,
            trigger: 'blur',
          },
        ],
      },
    }
  },

  components: {},

  computed: {
    ...mapState({
      backHomeFlag:state=>state.backHomeFlag,
      showBackBtn:state=>state.showBackBtn
    })
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      checkPassword()
        .then(({ data }) => {
          this.isInitPwd = data
          if (this.isInitPwd) {
            this.pwdDialogVisible = true
          }
        })
        .catch(() => {})
    },
    handleCommand(cmd) {
      if (cmd === 'pwd') {
        this.pwdDialogVisible = true
      } else if (cmd === 'about') {
        this.aboutUsVisiable = true
      }else if(cmd === 'about-soft'){
        this.navigateTo("/page/about")
      }
    },
    cancelPwdDialog() {
      if (this.isInitPwd) {
        this.exitLogin()
      } else {
        this.pwdDialogVisible = false
      }
    },
    onClosePwdDialog(done) {
      if (!this.isInitPwd) {
        done()
      } else {
        this.$notify({
          title: '提示',
          message: '请先修改初始密码!',
          type: 'error',
        })
      }
    },
    onPwdFormSubmit() {
      const that = this
      this.$refs.pwdForm.validate((valid) => {
        if (valid) {
          that.doModifyPwd()
        } else {
          return false
        }
      })
    },
    doModifyPwd() {
      updatePassword(this.pwdForm).then(() => {
        this.pwdDialogVisible = false
        this.isInitPwd = false
        this.$refs.pwdForm.resetFields()
        this.$message({
          type: 'success',
          message: '修改成功!',
        })
      })
    },
    handleExitClick(){
      exit().then(()=>{}).catch(()=>{})
      Token.deleteToken()
      this.navigateTo('/login')
    },
    handleShutdownClick(){
      this.$confirm('确定关机吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log("window.XRHealerClient",window.XRHealerClient)
        window.XRHealerClient.shutdown().then(()=>{})
      }).catch(() => {

      });
    },
    back(){
      if(this.backHomeFlag){
        this.navigateTo('/')
      }else{
        this.$router.back();
      }
    },
    handleHelpClick(){   
      window.XRHealerClient.openHelpFile('虚拟现实颈部评估及康复训练系统软件V1.1操作手册.pdf').then(()=>{}).catch((e)=>{
        console.error(e)
      })
    },
    
  }
}
