<!--标准颈部-->.
<template>
  <div class="standard-neck-component">
    <chart chart-title="平均定位差距" ref="angleDiffChartEl"></chart>
    <chart chart-title="平均回位差距" ref="angleDiffReturnChartEl"></chart>
    <chart chart-title="正常率" ref="normalRateChartEl"></chart>
  </div>
</template>

<script>
import Chart from "../Chart.vue";
import ChartUtils from "@/utils/ChartUtils";
import {getChartData,getXAxisLabel} from '@/api/TrendService';
import {chartFormat} from "@/utils/ChartUtils";

export default {
  props: {
     trendData:{
        type:Object
      }
  },
  components: {
    Chart,
  },
  data() {
    return {
      angleDiffChart: null,
      angleDiffReturnChart: null,
      normalRateChart: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.angleDiffChart = this.$refs.angleDiffChartEl;
      this.angleDiffReturnChart = this.$refs.angleDiffReturnChartEl;
      this.normalRateChart = this.$refs.normalRateChartEl;
      this.loadData();
    },
    loadChart(option) {
      this.chart.loadChart(option);
    },
    loadData() {
      this.angleDiffChart.loadChart(this.getAngleDiffChartOption());
      this.angleDiffReturnChart.loadChart(this.getAngleDiffReturnChartOption());
      this.normalRateChart.loadChart(this.getNormalRateChartOption());
    },

    /**
     * 定位差距
     */
    getAngleDiffChartOption() {
      const option = ChartUtils.getTrendStatisticsChartOptionTemplate();
      option.yAxis[0].name = "(角度)";
      option.xAxis.data = getXAxisLabel(this.trendData)
      option.yAxis[0].minInterval = 1;
      option.tooltip.formatter = chartFormat("°");

      const chartData = getChartData(this.trendData,'angleDiff')

     
      const item = ChartUtils.getSelfEvaluationChartBarSeries(
        "平均定位差距",
        "#449ecd",
        "#449ecd",
         chartData.values[0].map(item=>item.toFixed(1))
      );
      item.label.color = "#12a5ef";
      option.series = [item];
      return option;
    },
    /**
     * 回位差距
     */
    getAngleDiffReturnChartOption() {
      const option = ChartUtils.getTrendStatisticsChartOptionTemplate();
      option.yAxis[0].name = "(角度)";
      option.xAxis.data = getXAxisLabel(this.trendData)
      option.yAxis[0].minInterval = 1;
      option.tooltip.formatter = chartFormat("°",1);


      const chartData = getChartData(this.trendData,'rate')
      
      const item = ChartUtils.getSelfEvaluationChartBarSeries(
        "平均回位差距",
        "#ffc657",
        "#ffc657",
        chartData.values[0].map(item=>item.toFixed(1))
      );
      item.label.color = "#12a5ef";
      option.series = [item];
      return option;
    },
    /**
     * 正常率
     */
    getNormalRateChartOption() {
      const option = ChartUtils.getTrendStatisticsLineChartOption();
      option.legend = null;
      
      option.yAxis[0].name = "(百分比)";
      option.xAxis.data = getXAxisLabel(this.trendData)

      option.yAxis[0].axisLabel.formatter = null;
      option.yAxis[0].minInterval = 20;
      option.tooltip.formatter = chartFormat("%");

      const lineSeries = ChartUtils.getLineAreaSeriesTemplate();
      lineSeries.itemStyle.normal.color = "#44ca83"
      lineSeries.areaStyle.normal = {
          color:ChartUtils.chartConstants.selfNormRateAreaColor,
          shadowColor: "rgba(0, 0, 0, 0.1)",
          shadowBlur: 10,
        }
      lineSeries.name="正常率"
      const chartData = getChartData(this.trendData,'speed')
      lineSeries.data = chartData.values[0].map(item=>(item * 100).toFixed(0));

      option.series = lineSeries;
      return option;
    },
  

   
  },
};
</script>

<style lang="scss">
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";
.standard-neck-component {
}
</style>
