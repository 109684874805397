import request from "@/utils/Request";

/**
 * 获取原因列表
 * @returns
 */
export function getReasonList(code) {
  return request({
    url: `/api/main-data/system/dict`,
    method: "post",
    data: {
      code,
    },
  });
}

/**
 * 更新本体感觉训练数据
 * @param data
 * @param forwardData    回填的定位差距
 * @param backwardData   回填的回位差距
 */
export function fillSFData(data, forwardData, backwardData) {
  let forwardDataStr = data.angleDiffList.split(",");
  let backwardDataStr = data.returnAngleDiffList.split(",");
  let xLabel = "";
  // 填充X坐标说明
  if (data.x > 0 && data.y == 0) {
    xLabel = "右";
  }
  if (data.x > 0 && data.y > 0) {
    xLabel = "右上";
  }
  if (data.x == 0 && data.y > 0) {
    xLabel = "上";
  }
  if (data.x < 0 && data.y > 0) {
    xLabel = "左上";
  }
  if (data.x < 0 && data.y == 0) {
    xLabel = "左";
  }
  if (data.x < 0 && data.y < 0) {
    xLabel = "左下";
  }
  if (data.x == 0 && data.y < 0) {
    xLabel = "下";
  }
  if (data.x > 0 && data.y < 0) {
    xLabel = "右下";
  }

  let forwardDataArr = [];
  for (let i = 0; i < forwardDataStr.length; i++) {
    forwardDataArr.push(parseFloat(parseFloat(forwardDataStr[i]).toFixed(1)));
  }
  let findForwardData = false;
  for (let i = 0; i < forwardData.length; i++) {
    if (forwardData[i].xLabel == xLabel) {
      findForwardData = true;
      forwardData[i].data = forwardData[i].data.concat(forwardDataArr);
      break;
    }
  }
  if (!findForwardData) {
    let newForwardData = { xLabel: xLabel, data: forwardDataArr };
    forwardData.push(newForwardData);
  }

  let backwardDataArr = [];
  for (let i = 0; i < backwardDataStr.length; i++) {
    backwardDataArr.push(parseFloat(parseFloat(backwardDataStr[i]).toFixed(1)));
  }
  let findBackwardData = false;
  for (let i = 0; i < backwardData.length; i++) {
    if (backwardData[i].xLabel == xLabel) {
      findBackwardData = true;
      backwardData[i].data = backwardData[i].data.concat(backwardDataArr);
      break;
    }
  }
  if (!findBackwardData) {
    let newBackwardData = { xLabel: xLabel, data: backwardDataArr };
    backwardData.push(newBackwardData);
  }
}
