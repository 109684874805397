.<template>
  <div class="evaluation-active-component">
    <chart chart-title="活动度" ref="romChartEl">
        <div class="chart-tip" slot="contron-panel">点击右侧方块可显示对应数据</div>
    </chart>
    <chart chart-title="平均角速度" v-if="isAciveEvaluation" ref="averageAngularVelocityChartEl"></chart>
    <chart chart-title="激惹疼痛打分" ref="painScoreChartEl"></chart>
    <chart chart-title="一般疼痛打分" v-if="isAciveEvaluation" ref="generalScoreChartEl"></chart>
  </div>
</template>

<script>
import Chart from "../Chart.vue";
import ChartUtils from "../../../utils/ChartUtils";
import {getXAxisLabel} from '@/api/TrendService';
import {chartFormat} from "@/utils/ChartUtils";
export default {
  props: {
      isAciveEvaluation:{
          type:Boolean,
          required:true
      },
      trendData:{
        type:Object
      }
      
  },
  components: {
    Chart,
  },
  data() {
    return {
      romChart: null,
      averageAngularVelocityChart: null,
      painScoreChart: null,
      generalScoreChart: null,
    };
  },
  mounted() {
    console.log("activeEva",this.trendData)
    this.init();
  },
  methods: {
    init() {
      this.romChart = this.$refs.romChartEl;
      this.averageAngularVelocityChart = this.$refs.averageAngularVelocityChartEl;
      this.painScoreChart = this.$refs.painScoreChartEl;
      this.generalScoreChart = this.$refs.generalScoreChartEl;
      this.loadData();
    },
    loadChart(option) {
      this.chart.loadChart(option);
    },
    loadData() {
      this.romChart.loadChart(this.getRomChartOption());
      this.painScoreChart.loadChart(this.getPainOption());
      if(this.isAciveEvaluation){
        this.averageAngularVelocityChart.loadChart(this.getAvgAngularVelocityOption());
        this.generalScoreChart.loadChart(this.getGeneralPainOption());
      }
    },

    /**
     * 活动度图表数据
     */
    getRomChartOption() {
      const option = ChartUtils.getTrendStatisticsLineChartOption();
     option.xAxis.data = getXAxisLabel(this.trendData)
     option.tooltip.formatter = chartFormat("°",1);
      
      const romData = this.getChartData('rom')
      option.yAxis[0].axisLabel.formatter = "{value}°";
      option.series = [
        ChartUtils.getLineSeries("左屈", romData.values[0]),
        ChartUtils.getLineSeries("右屈", romData.values[1]),
        ChartUtils.getLineSeries("左旋", romData.values[2]),
        ChartUtils.getLineSeries("右旋", romData.values[3]),
        ChartUtils.getLineSeries("前屈", romData.values[4]),
        ChartUtils.getLineSeries("后伸", romData.values[5]),
      ];
      return option;
    },
    getChartData(type){
      const romData = this.trendData.trends.filter(item=>item.type===type)[0]
      return romData;
    },
   
    getAvgAngularVelocityOption() {
      const option = ChartUtils.getTrendStatisticsLineChartOption();
     
      option.xAxis.data = getXAxisLabel(this.trendData)
      option.yAxis[0].axisLabel.formatter = "{value}°/s";

      option.tooltip.formatter = chartFormat("°/s",1);

      const chartData = this.getChartData('avgSpeed')

      option.series = [
        ChartUtils.getLineSeries("左屈", chartData.values[0]),
        ChartUtils.getLineSeries("右屈", chartData.values[1]),
        ChartUtils.getLineSeries("左旋", chartData.values[2]),
        ChartUtils.getLineSeries("右旋", chartData.values[3]),
        ChartUtils.getLineSeries("前屈", chartData.values[4]),
        ChartUtils.getLineSeries("后伸", chartData.values[5]),
      ];
      return option;
    },
    /**
     * 获取一般疼痛数据
     */
    getPainOption() {
      const option = ChartUtils.getTrendStatisticsLineChartOption();
      option.yAxis[0].axisLabel.formatter = null;
      option.yAxis[0].max = 10;
      option.yAxis[0].interval = 2;

      option.xAxis.data = getXAxisLabel(this.trendData)

      const chartData = this.getChartData('activePain')

      option.series = [
        ChartUtils.getLineSeries("左屈", chartData.values[0]),
        ChartUtils.getLineSeries("右屈", chartData.values[1]),
        ChartUtils.getLineSeries("左旋", chartData.values[2]),
        ChartUtils.getLineSeries("右旋", chartData.values[3]),
        ChartUtils.getLineSeries("前屈", chartData.values[4]),
        ChartUtils.getLineSeries("后伸", chartData.values[5]),
      ];
      return option;
    },
    getGeneralPainOption() {
      const option = ChartUtils.getTrendStatisticsLineChartOption();
      option.legend = null
      option.xAxis.data = getXAxisLabel(this.trendData)
      option.yAxis[0].axisLabel.formatter = null;
       option.yAxis[0].max = 10;
      option.yAxis[0].interval = 2;

      const lineSeries = ChartUtils.getLineAreaSeriesTemplate();
      const chartData = this.getChartData('normalPain')

      lineSeries.data = chartData.values[0];

      option.series = lineSeries;
      return option;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixin.scss';
@import '@/styles/variable.scss';
.evaluation-active-component {
  .chart-tip{
      @include position(absolute,top -10px right 40px);
      @include font-size-color(18px,$backColor1);
  }
}
</style>
