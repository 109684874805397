<!--接诊记录-->
<template>
  <div class="sub-page reception-log-page">
    <div class="page-content">
      <div class="time-line"></div>
      <div
        class="reception-log-item-wrapper"
        :key="item.id"
        v-for="(item,index) in receptionList"
      >
        <div class="time-dot"></div>
        <reception-log-item :item="item" :patientName="pageParam.patientName" :expand="index===0"></reception-log-item>
      </div>
    </div>
  </div>
</template>

<script>
import ReceptionLogItem from "./ReceptionLogItem.vue";
import { BackHomeControlMixins } from "@/utils/Mixins";
import {getReceptionList} from '@/api/ReceptionService'
export default {
  mixins: [BackHomeControlMixins],
  data() {
    return {
      pageParam:{
        patientCaseId:null,
        patientName:null,
      },
      receptionList:[],
      receptionDetail:null,
    };
  },

  components: { ReceptionLogItem },

  computed: {},

  mounted() {
    this.setPageTemplateTitle("接诊记录");
    this.getInitData()
  },

  methods: {
    getInitData(){
      console.log(this.pageParam)
      getReceptionList(this.pageParam.patientCaseId).then(({data})=>{
        data.forEach(element => {
          element.show = false;
        });
        this.receptionList = data;
      })
    },

  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variable.scss";

.reception-log-page {
  min-height: 100%;
  position: relative;

  .page-content {
    padding-left: 60px;
  }

  .time-line {
    @include position(absolute, top 0px left 45px bottom 0px);
    width: 3px;
    background-color: #cddde9;
  }
  .reception-log-item-wrapper {
    padding: 20px;
    position: relative;

    .time-dot {
      @include position(absolute, left -29px top 38px);
      @include size(22px, 22px);
      border: 4px solid rgb(159, 223, 255);
      background-color: $themeColor;
      border-width: 4px;
      border-radius: 50%;
    }
  }
}
</style>
